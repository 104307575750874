import { BuilderConfig } from '../../types/Builder';
import BusinessTrendingTopics, { BusinessTrendingTopicsProps } from './business-trending-topics';

export const BusinessTrendingTopicsBuilderConfig = {
    name: 'Trending topics',
    inputs: [
        {
            name: 'title',
            type: 'string',
            required: true,
        },
        {
            name: 'description',
            type: 'string',
            required: true,
        },
        {
            name: 'categories',
            type: 'list',
            required: true,
            subFields: [
                {
                    name: 'categoryId',
                    type: 'string',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessTrendingTopicsProps> = {
    component: BusinessTrendingTopics,
    config: BusinessTrendingTopicsBuilderConfig,
};

export default builderConfig;
