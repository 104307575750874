import { BuilderConfig } from '../../types/Builder';
import BusinessButton, { Props } from './business-button';
import { COLORS, ICONS, POSITION, SIZES } from './business-button.constants';

export const BusinessButtonWrapBuilderConfig = {
    name: 'Button',
    inputs: [
        {
            name: 'buttonText',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'color',
            type: 'string',
            enum: Object.values(COLORS),
            defaultValue: COLORS.MINT,
        },
        {
            name: 'size',
            type: 'string',
            enum: Object.values(SIZES),
            defaultValue: SIZES.NORMAL,
        },
        {
            name: 'icon',
            type: 'string',
            enum: Object.values(ICONS),
            defaultValue: ICONS.ArrowBack,
        },
        {
            name: 'link',
            type: 'url',
            defaultValue: '',
        },
        {
            name: 'square',
            type: 'boolean',
            defaultValue: false,
        },
        {
            name: 'position',
            type: 'string',
            enum: Object.values(POSITION),
            defaultValue: POSITION.CENTER,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessButton,
    config: BusinessButtonWrapBuilderConfig,
};

export default builderConfig;
