import { BuilderConfig } from '../../types/Builder';
import BusinessResourceAsset, { Props } from './business-resource-background-text-wrap';

export const BusinessResourceBackgroundTextWrapBuilderConfig = {
    name: 'Resource Background Text Wrap',
    inputs: [
        {
            name: 'text',
            type: 'richText',
        },
        {
            name: 'copyableText',
            type: 'longText',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceAsset,
    config: BusinessResourceBackgroundTextWrapBuilderConfig,
};

export default builderConfig;
