import { BuilderConfig } from "../../types/Builder";
import UnorderedHighlightList, { Props } from "./unordered-highlight-list";
import { BACKGROUND_COLORS } from "./unordered-highlight-list.constants";

export const UnorderedHighlightListBuilderConfig = {
    name: "Unordered Highlight List",
    inputs: [
        {
            name: "heading",
            type: "string",
            defaultValue: "Heading",
        },
        {
            name: "list",
            type: "list",
            defaultValue: [],
            subFields: [
                {
                    name: "content",
                    type: "richText",
                    defaultValue: "",
                },
            ],
        },
        {
            name: "backgroundColor",
            type: "string",
            enum: Object.values(BACKGROUND_COLORS),
            defaultValue: BACKGROUND_COLORS.MINT,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: UnorderedHighlightList,
    config: UnorderedHighlightListBuilderConfig,
};

export default builderConfig;
