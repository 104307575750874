import { BuilderConfig } from '../../types/Builder';
import BusinessVideoPlayer, { VideoPlayerProps } from './business-video-player';

export const BusinessVideoPlayerWrapBuilderConfig = {
    name: 'Video Player',
    inputs: [
        {
            name: 'videoUrl',
            type: 'string',
            required: true,
        },
        {
            name: 'controls',
            type: 'boolean',
        },
        {
            name: 'loop',
            type: 'boolean',
        },
        {
            name: 'muted',
            type: 'boolean',
        },
        {
            name: 'backgroundTransform',
            type: 'boolean',
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<VideoPlayerProps> = {
    component: BusinessVideoPlayer,
    config: BusinessVideoPlayerWrapBuilderConfig,
};

export default builderConfig;
