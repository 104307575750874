import React, { FC, useState } from 'react';
import styles from './copy-button.module.scss';
import Icon, { IconType } from '../icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';
import { usePopper } from 'react-popper';

export interface CopyButtonProps {
    content: string;
}

const CopyButton: FC<CopyButtonProps> = ({ content }) => {
    const [isCopied, setIsCopied] = useState(false);

    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const [arrowElement, setArrowElement] = useState<any>(null);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                    padding: 5,
                },
            },
        ],
        placement: 'top',
        strategy: 'fixed',
    });

    return (
        <div>
            <span ref={setPopperElement} className={classNames(styles.tooltip, { [styles.visible]: isCopied })} style={popperStyles.popper} {...(attributes?.popper || {})}>
                Copied!
                <div ref={setArrowElement} className={styles.tooltipArrow} style={popperStyles.arrow} />
            </span>
            <CopyToClipboard
                text={content}
                onCopy={() => {
                    setIsCopied(true);
                    setTimeout(() => {
                        setIsCopied(false);
                    }, 2000);
                }}
            >
                <span ref={setReferenceElement} className={styles.copyIcon}>
                    <Icon type={IconType.Copy} />
                </span>
            </CopyToClipboard>
        </div>
    );
};

export default CopyButton;
