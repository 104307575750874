import React, { FC, useEffect, useState } from 'react';
import styles from './ratio-image.module.scss';

export interface RatioImageProps {
    ratio?: number | 'auto';
    image?: string;
    maxHeight?: number;
    maxWidth?: number;
    minHeight?: number;
}

const RatioImage: FC<RatioImageProps> = ({ image, ratio = 'auto', maxHeight, maxWidth, minHeight }) => {
    const [imageBoxStyle, setImageBoxStyle] = useState<React.CSSProperties>(
        typeof ratio === 'number'
            ? {
                  paddingTop: `${ratio * 100}%`,
              }
            : {}
    );
    const [imageStyle, setImageStyle] = useState<React.CSSProperties>({ maxHeight, maxWidth, minHeight });
    useEffect(() => {
        if (image) {
            const img = new Image();
            img.onload = function () {
                if (ratio === 'auto') {
                    setImageBoxStyle({
                        ...imageBoxStyle,
                        paddingTop: `${(img.height / img.width) * 100}%`,
                    });
                }
                if (maxHeight || maxWidth) {
                    setImageStyle({
                        ...imageStyle,
                        maxWidth: maxWidth ? maxWidth : maxHeight && maxHeight * (img.width / img.height),
                        maxHeight: maxHeight ? maxHeight : maxWidth && maxWidth * (img.height / img.width),
                    });
                }
            };
            img.src = image;
        }
    }, []);
    return (
        <div className={styles.root} style={imageStyle}>
            <div className={styles.imageContainer} style={imageBoxStyle}>
                <img className={styles.image} src={image} />
            </div>
        </div>
    );
};

export default RatioImage;
