import { FC } from 'react';
import styles from './business-resource-text-list.module.scss';

export interface Props {
    title: string;
    items: {
        content: string;
    }[];
}

const BusinessResourceTextList: FC<Props> = (props) => {
    const { title, items } = props;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.items}>
                {items?.map((item, index) => (
                    <div className={styles.item} key={index}>
                        <span className={styles.number}>{index + 1}</span>
                        <span
                            className={styles.content}
                            dangerouslySetInnerHTML={{
                                __html: item.content,
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BusinessResourceTextList;
