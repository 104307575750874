import React, { FC } from 'react';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_BACKGROUND_COLORS, mapNameToColour } from '../../helpers/ColourMap';
import { replaceOrAddLocale, useLocale } from '../../helpers/LangHelper';
import Icon, { IconType } from '../icons';
import ImageZoom from '../image-zoom/image-zoom';
import { IMAGE_POSITION, IMAGE_POSITIONS } from './business-framed-info-block.constants';

import styles from './business-framed-info-block.module.scss';

export type FramedInfoBlockProps = {
    borderColor?: DEFAULT_BACKGROUND_COLOR;
    image: string;
    title: string;
    description: string;
    url?: string;
    ctaText?: string;
    ctaIcon?: IconType;
    mediaPosition: IMAGE_POSITION;
};

const FramedInfoBlock: FC<FramedInfoBlockProps> = (props) => {
    const {locale} = useLocale();

    const { borderColor = DEFAULT_BACKGROUND_COLORS.MINT, image, title, description, mediaPosition = 'left', ctaText, ctaIcon } = props;
    let url = replaceOrAddLocale(props.url ?? '', locale);

    const mediaBlock = () => (
        <div className={`${styles.businessResourceBlockMediaContainer} ${styles[mediaPosition]}`}>
            <div className={styles.businessResourceBlockMedia}>
                <div className={`${styles.mediaImageWrapper} ${styles[mediaPosition]}`}>
                    <div className={styles.mediaImageBox}>
                        <div className={styles.mediaImageMain}>
                            <ImageZoom src={image}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles.mediaBackground} ${styles[mediaPosition]}`} style={{ backgroundColor: mapNameToColour(borderColor) }}></div>
            </div>
        </div>
    );

    const infoBlock = () => (
        <div className={`${styles.businessResourceBlockInfo} ${styles[mediaPosition]}`}>
            <div className={styles.infoTitle} dangerouslySetInnerHTML={{ __html: title }}></div>
            <div className={styles.infoDescription} dangerouslySetInnerHTML={{__html: description}}></div>

            <a href={url} className={styles.infoCTA}>
                <span>{ctaText ?? 'Download'}</span>
                <Icon type={ctaIcon ?? IconType.Download} />
            </a>
        </div>
    );

    return (
        <div className={styles.businessResourceBlockContainer}>
            {mediaPosition === IMAGE_POSITIONS.LEFT ? mediaBlock() : null}
            {infoBlock()}
            {mediaPosition === IMAGE_POSITIONS.RIGHT ? mediaBlock() : null}
        </div>
    );
};

export default FramedInfoBlock;
