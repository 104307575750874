import { AssetType, AssetVariantType, COLORS, RequestZipType, ResourceType } from '../types/Resource';
import path from 'path';

const RESOURCE_CREATE_ZIP_API = `${process.env.NEXT_PUBLIC_ADHOC_HOST}/api/zip/create`;
const RESOURCE_PULL_ZIP_API = `https://afterpay-adhoc-api-media-production-bucket.s3.ap-southeast-2.amazonaws.com`;

const assetTypeToRequest = (assets: AssetType[], rootFolderName: string): RequestZipType => {
    const result: RequestZipType = {
        name: rootFolderName,
        variants: [],
    };
    const assetNameCheck = overlapCheckHof();

    assets
        .filter(({ skipDownload }) => !skipDownload)
        .forEach((asset, i) => {
            const assetName = assetNameCheck('asset', asset.name);
            const filenameCheck = overlapCheckHof();
            asset.variants.forEach((variant, j) => {
                const filename = filenameCheck('variant', getFileNameWithExtension(variant));
                result.variants.push({
                    downloadUrl: variant.downloadUrl,
                    filename,
                    extension: '',
                    path: assetName,
                });
            });
        });

    return result;
};

export const resourceTypeToRequest = (resources: ResourceType[], rootFolderName: string): RequestZipType => {
    const result: RequestZipType = {
        name: rootFolderName,
        variants: [],
    };
    const typeCheck = overlapCheckHof();
    resources?.forEach(({ assets, type, title }, k) => {
        const typeName = typeCheck('resource', title || type);
        const assetNameCheck = overlapCheckHof();
        assets
            ?.filter(({ skipDownload }) => !skipDownload)
            .forEach((asset, i) => {
                const assetName = assetNameCheck('asset', asset.name);
                const variantNameCheck = overlapCheckHof();

                asset.variants.forEach((variant, j) => {
                    const variantName = variantNameCheck('variant', getFileNameWithExtension(variant));
                    result.variants.push({
                        downloadUrl: variant.downloadUrl,
                        filename: variantName,
                        extension: '',
                        path: path.join(typeName, assetName),
                    });
                });
            });
    });

    return result;
};

export const downloadAsset = async (assets: AssetType[], rootFolderName: string) => {
    if (!assets || !assets.length) {
        console.warn('No variant to download');
        return;
    }

    const body = assetTypeToRequest(assets, rootFolderName);
    await baseDownloadAsset(body);
};

export const baseDownloadAsset = async (body: RequestZipType) => {
    if (!body) {
        console.warn('No request body');
        return;
    }

    const data = await fetch(RESOURCE_CREATE_ZIP_API, { method: 'POST', body: JSON.stringify(body) });
    if (data.headers.get('Content-Disposition')) {
        await downloadFromResponse(data);
        return;
    }

    const key = (await data.json()).key;
    if (!key) {
        console.error('Invalid key', data);
        return;
    }

    for (let count = 0; count < 150; count++) {
        const pullResult = await pullAsset(key);
        if (pullResult) {
            break;
        }
        await new Promise((resolve) =>
            setTimeout(() => {
                resolve(true);
            }, 6000)
        );
    }
};

export const overlapCheckHof = () => {
    let index = 1;
    const typeNames: string[] = [];
    return (initialDefaultName: string, name?: string): string => {
        let typeName = name || initialDefaultName;
        if (typeNames.includes(typeName)) {
            typeName = `${typeName}_${index}`;
            index += 1;
        }
        typeNames.push(typeName);
        return typeName;
    };
};

export const getColorCode = (colorName?: COLORS, hash: boolean = false): string | undefined => {
    if (!colorName) return;
    const mapping = {
        [COLORS.Mint]: 'B2FCE4',
        [COLORS.Black]: '000',
        [COLORS.White]: 'FFF',
        [COLORS.Grey]: '969696',
    };
    const code = mapping[colorName];
    if (!code) return;
    return `${hash ? '#' : ''}${code}`;
};

export const getFileNameWithExtension = (variant: AssetVariantType) => {
    return `${variant.fileName || ''}${variant.extension ? '.' + variant.extension : ''}`;
};

const pullAsset = async (key: string) => {
    try {
        const response = await fetch(`${RESOURCE_PULL_ZIP_API}/${key}`);
        if (response.ok) {
            const filename = response.headers.get('x-amz-meta-name') || undefined;
            await downloadFromResponse(response, filename);
            return true;
        }
        return false;
    } catch (ex) {
        console.log(ex);
        return false;
    }
};

const downloadFromResponse = async (data: Response, filename?: string) => {
    const downloadElement = document.createElement('a');
    const url = window.URL.createObjectURL(await data.blob());
    downloadElement.href = url;
    downloadElement.download = filename || '';
    if (!filename) {
        const disposition = data.headers.get('Content-Disposition');
        let filename = 'assets';
        if (disposition) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
            downloadElement.download = filename;
        }
    }

    downloadElement.click();
    window.URL.revokeObjectURL(url);
};
