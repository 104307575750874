import classNames from 'classnames';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useWindowSize } from '../../hook/useWindowSize';
import styles from './business-resource-image-comparison-slider.module.scss';
import { compareImages } from './image-comparison-slider.utils';

export interface BusinessResourceImageComparisonSliderProps {
    title?: string;
    leftImage: string;
    rightImage: string;
}

const BusinessResourceImageComparisonSlider: FC<BusinessResourceImageComparisonSliderProps> = (props) => {
    const { title, leftImage, rightImage } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const containerWidth = containerRef.current?.getBoundingClientRect().width;
    const imgCompSliderId = useMemo(() => `${Math.random()}`, []);
    const { width } = useWindowSize();
    const lastWindowWidth = useRef<number | undefined>();

    useEffect(() => {
        if (!containerRef.current) return;
        const elemements = containerRef.current?.getElementsByClassName(styles.imgCompOverlay);
        containerRef.current?.getElementsByClassName;
        const isWindowResize = lastWindowWidth.current !== undefined && width !== undefined && lastWindowWidth.current !== width;
        for (let i = 0; i < elemements.length; i++) {
            const imgElement = elemements[i] as HTMLImageElement;

            if (isWindowResize) {
                imgElement.style.width = containerWidth! + 'px';
            }

            compareImages(imgElement, imgCompSliderId, styles.imgCompSlider);
        }
        lastWindowWidth.current = width;

        return () => {
            document.getElementById(imgCompSliderId)?.remove();
        };
    }, [containerWidth, width]);

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.sliderContainer}>
                <div className={styles.img} style={{ width: containerWidth }}>
                    <div className={styles.mask}></div>
                    <img src={rightImage} style={{ width: containerWidth }} />
                    <div className={styles.do}>
                        <span>Do</span>
                    </div>
                </div>
                <div className={classNames(styles.img, styles.imgCompOverlay)} style={{ width: containerWidth }}>
                    <div className={styles.mask}></div>
                    <img src={leftImage} style={{ width: containerWidth }} />
                    <div className={styles.dont}>
                        <span>Don&apos;t</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessResourceImageComparisonSlider;
