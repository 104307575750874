import { FC } from 'react';
import styles from './business-resource-divider.module.scss';

export interface BusinessResourceDividerProps {}

const BusinessResourceDivider: FC<BusinessResourceDividerProps> = () => {
    return <div className={styles.container}></div>;
};

export default BusinessResourceDivider;
