import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './business-resource-link-button.module.scss';
import classNames from 'classnames';

export type Props = PropsWithChildren<JSX.IntrinsicElements['a']> & {
    icon?: ReactNode;
    strong?: boolean;
    disabled?: boolean;
};

const BusinessResourceLinkButton: FC<Props> = ({ disabled, className, children, icon, strong, onClick, ...props }) => {
    return (
        <a
            className={classNames(
                styles.root,
                {
                    [styles.disabled]: disabled,
                },
                className
            )}
            onClick={(e) => {
                if (disabled) {
                    e.preventDefault();
                } else onClick?.(e);
            }}
            {...props}
        >
            <span
                className={classNames(styles.text, {
                    [styles.strong]: strong,
                })}
            >
                {children}
            </span>
            <span className={styles.icon}>{icon}</span>
        </a>
    );
};

export default BusinessResourceLinkButton;
