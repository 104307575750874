import { BuilderConfig } from "../../types/Builder";
import CategoryFeature, { CategoryFeatureProps } from "./category-feature";

export const CategoryFeatureBuilderConfig = {
    name: 'Category Feature',
    inputs: [
        {
            name: 'category',
            type: 'text',
        }
    ]
};

const builderConfig: BuilderConfig<CategoryFeatureProps> = {
    component: CategoryFeature,
    config: CategoryFeatureBuilderConfig,
};

export default builderConfig;