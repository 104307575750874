import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import styles from './business-resource-asset-library.module.scss';
import { AssetType, COLORS, ResourceType } from '../../types/Resource';
import Button from '../outlined-button/outlined-button';
import Modal from '../modal/modal';
import FilterPanelContainer from './components/filter-panel-container/filter-panel-container';
import BusinessResourceDownloadButton from '../business-resource-download-button/business-resource-download-button';
import ArrowRightMedium from '../icons/Icons/ArrowRightMedium';
import Download from '../icons/Icons/Download';
import BusinessResourceFilter from '../icons/Icons/BusinessResourceFilter';
import { BusinessResourceAssetLibraryContext, BusinessResourceAssetLibraryProvider } from './context';
import InlinedMultiSelectContainer from './components/inlined-multi-select-container/inlined-multi-select-container';
import ChipsContainer from './components/chips-container/chips-container';
import BusinessResourceDownloadResourcesButtonContainer from '../business-resource-download-button/business-resource-download-resources-button-container';
import BusinessResourceAssetGrid from '../business-resource-asset-grid/business-resource-asset-grid';
import AssetCardContainer from './components/asset-card-container/asset-card-container';
import classNames from 'classnames';
import { useWindowSize } from '../../hook/useWindowSize';

export interface Props {
    resources?: ResourceType[];
    showDownloadButton?: boolean;
    columns?: number;
    limitRows?: number;
}

const BusinessResourceAssetLibrary: FC<Omit<Props, 'resources'>> = ({ showDownloadButton, columns, limitRows }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [dropdownValue, setDropdownValue] = useState<number | string>(1);
    const { getFilteredResources, assets, getDownloadAssetResources } = useContext(BusinessResourceAssetLibraryContext);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const [lock, setLock] = useState<Boolean>(false);
    const { width } = useWindowSize();

    const onScroll = useCallback(
        (e: Event) => {
            if (width && width <= 768) {
                if (buttonContainerRef.current) {
                    const { height, y } = buttonContainerRef.current.getBoundingClientRect();
                    const botPosition = height + y;
                    if (botPosition <= 0) {
                        setLock(true);
                    } else {
                        setLock(false);
                    }
                }
            } else {
                setLock(false);
            }
        },
        [width]
    );

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [width]);

    return (
        <div className={styles.root}>
            <Modal
                actions={
                    <BusinessResourceDownloadButton onClick={() => setOpen(false)}>
                        Show me what I need <ArrowRightMedium />
                    </BusinessResourceDownloadButton>
                }
                onCloseClick={() => setOpen(false)}
                onEscape={() => setOpen(false)}
                onOutsideClick={() => setOpen(false)}
                open={open}
                title="I'm not sure what I need"
            >
                <FilterPanelContainer />
            </Modal>
            <div className={styles.action}>
                <div className={styles.leftAction}>
                    {/* <Select
                        className={styles.dropdownButton}
                        onClick={(e, option) => setDropdownValue(option.value)}
                        options={[
                            {
                                value: 1,
                                label: 'Most popular',
                            },
                            {
                                value: 2,
                                label: 'Newest',
                            },
                        ]}
                        value={dropdownValue}
                    />*/}
                    <Button className={styles.buttonResponsive} onClick={() => setOpen(true)}>
                        I&apos;m not sure what I need
                        <div style={{ flexShrink: 0 }}>
                            <BusinessResourceFilter />
                        </div>
                    </Button>
                </div>
                <div className={styles.buttonContainer} id="container" ref={buttonContainerRef}>
                    <div
                        className={classNames({
                            [styles.lock]: lock && assets.length,
                        })}
                    >
                        <BusinessResourceDownloadResourcesButtonContainer
                            resources={getDownloadAssetResources()}
                            rootContainerName="asset-library"
                            disabled={!assets.length}
                            containerClass={styles.buttonResponsive}
                            className={classNames(styles.buttonResponsive, {
                                [styles.lockedButton]: lock,
                            })}
                            mobileFullWidth
                        >
                            Download Selected <Download />
                        </BusinessResourceDownloadResourcesButtonContainer>
                    </div>
                </div>
            </div>
            <div className={styles.tab}>
                <InlinedMultiSelectContainer />
            </div>
            <div className={styles.chip}>
                <ChipsContainer />
            </div>
            <BusinessResourceAssetGrid
                renderAssetCard={(asset) => <AssetCardContainer asset={asset} />}
                resources={getFilteredResources()}
                columns={columns}
                showDownloadButton={showDownloadButton}
                limitRows={limitRows}
                showDivider
            />
        </div>
    );
};

const BusinessResourceAssetLibraryContextWrapper: FC<Props> = ({ resources: initResources = [], ...props }) => {
    const [colors, setColors] = useState<COLORS[]>([]);
    const [keyMessages, setKeyMessages] = useState<string[]>([]);
    const [themedAssets, setThemedAssets] = useState<string[]>([]);
    const [assetTypes, setAssetTypes] = useState<string[]>([]);
    const [assets, setAssets] = useState<AssetType[]>([]);
    const resources = useMemo(() => {
        return initResources?.map((resource) => ({
            ...resource,
            id: Math.random().toString(8),
            assets: resource.assets?.map((asset) => ({
                ...asset,
                id: Math.random().toString(8),
            })),
        }));
    }, []);

    const getDownloadAssetResources = (): ResourceType[] => {
        return getFilteredResources().map((resource) => {
            return {
                ...resource,
                assets: resource.assets?.filter((asset) => assets.some(({ id }) => id === asset.id)),
            };
        });
    };

    const getDownloadAssets = (resourceId?: string): AssetType[] => {
        const resource = resources.find(({ id }) => id === resourceId);
        if (!resource) return [];
        return resource.assets?.filter((asset) => assets.some(({ id }) => id === asset.id)) || [];
    };

    const getFilteredResources = (): ResourceType[] =>
        (resources || [])
            .filter(({ type }) => !type || !assetTypes.length || assetTypes.includes(type))
            .map((resource) => ({
                ...resource,
                assets: resource.assets
                    ?.filter((asset) => !keyMessages.length || keyMessages.some((k) => asset.keyMessages && asset.keyMessages.includes(k)))
                    .filter((asset) => !themedAssets.length || themedAssets.some((k) => asset.themedAssets && asset.themedAssets.includes(k)))
                    .map((asset) => ({
                        ...asset,
                        variants: asset.variants.filter((variant) => {
                            return !colors.length || colors.some((color) => variant.colors?.[0]?.color === color);
                        }),
                    }))
                    .filter(({ variants }) => variants.length),
            }))
            .filter(({ assets }) => assets?.length);

    return (
        <BusinessResourceAssetLibraryProvider
            value={{
                resources,
                colors,
                setColors,
                keyMessages,
                setKeyMessages,
                themedAssets,
                setThemedAssets,
                assetTypes,
                setAssetTypes,
                assets,
                setAssets,
                getDownloadAssetResources,
                getDownloadAssets,
                getFilteredResources,
            }}
        >
            <BusinessResourceAssetLibrary {...props} />
        </BusinessResourceAssetLibraryProvider>
    );
};

export default BusinessResourceAssetLibraryContextWrapper;
