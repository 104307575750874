import { BuilderConfig } from '../../types/Builder';
import VideoCarouselWrapper, { Props } from './video-carousel-wrapper';

export const VideoCarouselBuilderConfig = {
    name: 'Video Carousel',
    inputs: [
        {
            name: 'videoCarouselItems',
            type: 'list',
            subFields: [
                { name: 'thumbnailUrl', type: 'file' },
                { name: 'quote', type: 'text', defaultValue: '' },
                { name: 'authorQuote', type: 'text', defaultValue: '' },
                { name: 'sectionId', type: 'string', defaultValue: '', helperText: 'This is id for scroll to section below.' },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: VideoCarouselWrapper,
    config: VideoCarouselBuilderConfig,
};

export default builderConfig;
