import React, { CSSProperties, FC, useState } from 'react';
import styles from './asset-card.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { AssetType, COLORS } from '../../types/Resource';
import { getColorCode } from '../../helpers/ResourceHelper';
import RatioImage, { RatioImageProps } from '../ratio-image/ratio-image';

SwiperCore.use([Pagination]);

export interface Props {
    asset: AssetType;
    RatioImageProps?: RatioImageProps;
}

const AssetCard: FC<Props> = ({ asset: { variants }, children, RatioImageProps = {} }) => {
    const filteredVariants = (variants || []).filter(({ hide, colors }) => !hide);
    const [backgroundColor, setBackgroundColor] = useState<CSSProperties['backgroundColor'] | undefined>(undefined);
    return (
        <div className={styles.root}>
            <Swiper
                onSlideChange={(swiper) => {
                    const realIndex = swiper.realIndex;
                    const currentSlide = filteredVariants[realIndex];
                    setBackgroundColor(currentSlide.backgroundColor);
                }}
                loop
                className={styles.swiperContainer}
                slidesPerView={1}
                navigation={false}
                pagination={{
                    clickable: true,
                    renderBullet: (index) => {
                        if (!filteredVariants) {
                            return '';
                        }
                        const { colors } = filteredVariants[index];
                        return `<div style='background-color: ${getColorCode(colors?.[0]?.color, true)}; ${
                            colors?.[0]?.color === COLORS.White ? 'border-color: #000' : ''
                        }' class='swiper-pagination-bullet'></div>`;
                    },
                }}
            >
                {filteredVariants.map(({ previewUrl }, index) => (
                    <SwiperSlide key={index}>
                        <div
                            style={{
                                backgroundColor,
                            }}
                            className={styles.content}
                        >
                            <RatioImage image={previewUrl} {...RatioImageProps} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={styles.checkbox}>{children}</div>
        </div>
    );
};

export default AssetCard;
