import React, { FC } from 'react';
import Icon, { IconType } from '../icons';
import ResourceTraningBlockItem, { ResourceTraningItemBlockProps } from './business-resource-training-block-item/business-resource-training-block-item';

import styles from './business-resource-training-block.module.scss';

export type ResourceTraningBlockProps = {
    title: string;
    description: string;
    list: ResourceTraningItemBlockProps[];
};

const ResourceTraningBlock: FC<ResourceTraningBlockProps> = (props) => {
    const { title, description, list } = props;

    return (
        <div className={styles.businessResourceTrainingBlockContainer}>
            <div className={styles.businessResourceTrainingBlockTitle}>{title}</div>
            <div className={styles.businessResourceTrainingBlockDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
            <div className={styles.businessResourceTrainingBlockItems}>
                {list?.map((item, index) => (
                    <ResourceTraningBlockItem key={`${index}`} {...item} />
                ))}
            </div>
        </div>
    );
};

export default ResourceTraningBlock;
