import React from 'react';
import { localifyLink, useLocale } from '../../helpers/LangHelper';
import { useWindowSize } from '../../hook/useWindowSize';
import { Article } from '../../types/Article';
import BusinessButton from '../business-button/business-button';
import CategoriesComponent from '../categories/categories';
import { IconType } from '../icons';
import ImageZoom from '../image-zoom/image-zoom';
import styles from './community-card.module.scss';
interface Props {
    article: Article;
}

const CommunityCard = (props: Props) => {
    const { locale } = useLocale();

    const { article } = props;
    const windowSize = useWindowSize();
    const windowWidth = windowSize?.width || 0;
    const isMobile = windowWidth && windowWidth <= 768 ? true : false;
    const ratioImage = isMobile ? [345, 345] : [720, 638];

    if (!article) {
        return null;
    }

    const articleUrl = localifyLink(`/${locale}${article.articleUrl}`, locale);

    return (
        <div className={styles.communityCard}>
            <div className={styles.communityCardWrapper}>
                <ImageZoom src={article.leadImage || ''} ratio={ratioImage} />
            </div>
            <div className={styles.infoArticle}>
                <CategoriesComponent categories={article.categories} type="SMALL" color={'white'} />
                <div className={styles.articleTitle}>{article.title}</div>
                <div className={styles.articleDescription}>{article.description}</div>
                <div className={styles.readMoreBtn}>
                    <BusinessButton icon={IconType.ArrowRightMedium} buttonText="Read more" link={articleUrl} color={'white'}/>
                </div>
            </div>
            <div className={styles.overlay}></div>
            <a href={articleUrl} className={styles.articleUrl}></a>
        </div>
    );
};

export default CommunityCard;
