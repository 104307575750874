import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from './business-resource-centre-hero.module.scss';

export interface BusinessResourceCentreHeroProps {
    title: string;
}

const BusinessResourceCentreHero: FC<BusinessResourceCentreHeroProps> = (props) => {
    const { title } = props;

    const Element = (
        <div className={styles.container}>
            <div className={styles.background}></div>
            <div className={styles.content}>
                <div className={styles.title}>{title}</div>
            </div>
        </div>
    );

    useEffect(() => {
        const container = document.getElementById('resource-centre-hero-container');
        if (!container) return;
        container.style.display = 'block';

        ReactDOM.render(Element, container);
        return () => {
            container.style.display = 'none';
        };
    }, []);

    return null;
};

export default BusinessResourceCentreHero;
