import { BuilderConfig } from '../../types/Builder';
import BusinessResourceCentreHero, { BusinessResourceCentreHeroProps } from './business-resource-centre-hero';

export const BusinessResourceCentreHeroBuilderConfig = {
    name: 'Resource Centre Hero',
    inputs: [
        {
            name: 'title',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'filterTilte',
            type: 'string',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceCentreHeroProps> = {
    component: BusinessResourceCentreHero,
    config: BusinessResourceCentreHeroBuilderConfig,
};

export default builderConfig;
