import { withChildren } from '@builder.io/react';
import { BuilderConfig } from '../../types/Builder';
import VideoCardSection, { Props } from './video-card-section';
const VideoCardSectionWithChildren = withChildren(VideoCardSection);
export const VideoCardSectionBuilderConfig = {
    name: 'Video Card Section',
};

const builderConfig: BuilderConfig<Props> = {
    component: VideoCardSectionWithChildren,
    config: VideoCardSectionBuilderConfig,
};

export default builderConfig;
