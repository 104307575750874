import { BuilderConfig } from '../../types/Builder';
import BusinessResourceDownloadGrid, { BusinessResourceDownloadGridProps } from './business-resource-download-grid';

export const BusinessResourceDownloadGridBuilderConfig = {
    name: 'Resource Download Grid',
    inputs: [
        {
            name: 'items',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                },
                {
                    name: 'variants',
                    type: 'list',
                    subFields: [
                        { name: 'variantName', type: 'string' },
                        {
                            name: 'color',
                            type: 'color',
                        },
                        {
                            name: 'backgroundColor',
                            type: 'color',
                            helperText: 'Background in the UI. Default is white',
                            defaultValue: '#ffffff',
                        },
                        {
                            name: 'extension',
                            helperText: 'Example: svg, png, ai, eps,...',
                            type: 'string',
                        },
                        {
                            name: 'previewUrl',
                            type: 'url',
                        },
                        {
                            name: 'downloadUrl',
                            type: 'url',
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceDownloadGridProps> = {
    component: BusinessResourceDownloadGrid,
    config: BusinessResourceDownloadGridBuilderConfig,
};

export default builderConfig;
