import { BuilderConfig } from '../../types/Builder';
import BusinessResourceVideoList, { Props } from './business-resource-video-list';

export const BusinessVideoListBuilderConfig = {
    name: 'Video List',
    inputs: [
        { name: 'title', type: 'string', defaultValue: 'Make it your Way' },
        { name: 'description', type: 'string' },
        { name: 'ctaText', type: 'string', defaultValue: 'Learn More' },
        {
            name: 'videoList',
            type: 'list',
            subFields: [
                { name: 'title', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'leadImage', type: 'file' },
                { name: 'imageBrandUrl', type: 'file' },
                { name: 'videoUrl', type: 'string' },
            ],
        },
        { name: 'watchAllUrl', type: 'string' },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceVideoList,
    config: BusinessVideoListBuilderConfig,
};

export default builderConfig;
