import { BuilderConfig } from '../../types/Builder';
import BusinessResourceColourPalette, { BusinessResourceColourPaletteProps } from './business-resource-colour-palette';

export const BusinessResourceColourPaletteBuilderConfig = {
    name: 'Resource Colour Palette',
    inputs: [
        {
            name: 'layout',
            type: 'string',
            defaultValue: 'primary',
            enum: ['primary', 'secondary'],
        },
        {
            name: 'colours',
            type: 'list',
            subFields: [
                {
                    name: 'colour',
                    type: 'color',
                    required: true,
                },
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'description',
                    type: 'string',
                },
                {
                    name: 'textColor',
                    type: 'color',
                    required: true,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceColourPaletteProps> = {
    component: BusinessResourceColourPalette,
    config: BusinessResourceColourPaletteBuilderConfig,
};

export default builderConfig;
