import React, { PropsWithChildren, forwardRef, ForwardRefRenderFunction } from 'react';
import styles from './outlined-button.module.scss';
import classNames from 'classnames';

export type Props = PropsWithChildren<JSX.IntrinsicElements['div']>;

const OutlinedButton: ForwardRefRenderFunction<HTMLDivElement, Props> = ({ children, className, ...props }, ref) => {
    return (
        <div ref={ref} className={classNames(styles.root, className)} {...props}>
            {children}
        </div>
    );
};

export default forwardRef(OutlinedButton);
