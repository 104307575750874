import { BuilderConfig } from '../../types/Builder';
import { ResourceCardProps } from './business-resource-card';
import ResourceCard from './business-resource-card';

export const BusinessResourceCardBuilderConfig = {
    name: 'Resource Card',
    inputs: [
        {
            name: 'image',
            type: 'file',
            required: true,
        },
        {
            name: 'title',
            type: 'string',
            defaultValue: '',
            required: true,
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'buttonLabel',
            type: 'string',
            defaultValue: 'Read more',
        },
        {
            name: 'url',
            type: 'url',
        },
        {
            name: 'replaceLocalesOnLink',
            type: 'boolean',
            defaultValue: false,
        }
    ],
};

const builderConfig: BuilderConfig<ResourceCardProps> = {
    component: ResourceCard,
    config: BusinessResourceCardBuilderConfig,
};

export default builderConfig;
