import * as React from 'react';

const IconArrowRight = () => {
    return (
        <svg className="icon-arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9L8 6L5 3" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
export default IconArrowRight;
