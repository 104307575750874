import Vimeo from '@u-wave/react-vimeo';
import React, { useEffect, useRef, useState } from 'react';
import { useScroll } from '../../hook/useScroll';
import { MEDIA_RATIO } from '../../types/Media';
import styles from './video-hero.module.scss';
export interface Props {
    vimeoId: string;
    videoContent: string;
    children?: JSX.Element;
}

const VideoHero = (props: Props) => {
    const { vimeoId, videoContent, children } = props;
    const { scrollY } = useScroll();
    const [isVimeoPause, setIsVimeoPause] = useState<boolean>(false);
    const [volume, setVolume] = useState(0);
    const vimeoRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const offsetHeight = vimeoRef?.current?.offsetHeight || 0;
        const offsetTop = vimeoRef?.current?.offsetTop || 0;
        if (scrollY > offsetTop + offsetHeight) {
            setIsVimeoPause(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollY]);

    const [width, height] = MEDIA_RATIO['16:9'];
    const vimeoVideoPaddingTop = (height / width) * 100;

    const handleOnPlay = () => {
        setIsVimeoPause(false);
        setVolume(0.5);
    };

    return (
        <div className={styles.videoHero}>
            {children}
            <div className={styles.videoHeroContainer} ref={vimeoRef}>
                <div className={styles.vimeoVideo} style={{ paddingTop: `${vimeoVideoPaddingTop}%` }}>
                    <Vimeo video={vimeoId} muted paused={isVimeoPause} volume={volume} onPlay={handleOnPlay} onVolumeChange={(e) => setVolume(e.volume)} />
                    {/* <iframe src={videoUrl} width="538" height="618" frameBorder="0" allow="autoplay; muted; fullscreen; picture-in-picture" allowFullScreen /> */}
                </div>
            </div>
            <div className={styles.videoHeroContent} dangerouslySetInnerHTML={{ __html: videoContent }} />
        </div>
    );
};

export default VideoHero;
