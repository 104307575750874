import { BuilderConfig } from '../../types/Builder';
import BusinessResourceHiws, { Props } from './business-resource-hiws';

export const BusinessResourceHIWsBuilderConfig = {
    name: 'Resource How It Works',
    inputs: [
        {
            name: 'rows',
            type: 'list',
            subFields: [
                {
                    name: 'assets',
                    type: 'list',
                    copyOnAdd: false,
                    subFields: [
                        {
                            name: 'name',
                            type: 'string',
                        },
                        {
                            name: 'variants',
                            type: 'list',
                            copyOnAdd: false,
                            subFields: [
                                { name: 'variantName', type: 'string' },
                                {
                                    name: 'previewUrl',
                                    type: 'url',
                                },
                                {
                                    name: 'downloadUrl',
                                    type: 'url',
                                },
                                {
                                    name: 'extension',
                                    type: 'string',
                                },
                                {
                                    name: 'pagePreviewImage',
                                    type: 'file',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceHiws,
    config: BusinessResourceHIWsBuilderConfig,
};

export default builderConfig;
