import { useEffect, useState } from 'react';
import { getLatestArticles } from '../../helpers/BuilderHelper';
import { useLocale } from '../../helpers/LangHelper';
import { Article } from '../../types/Article';
import HeadliningArticlesBlock from '../headlining-articles-block/headlining-articles-block';

export interface BusinessHeadliningLatestArticlesProps {
    offset: number;
    showSignupForAfterpay: boolean;

    articles: Article[];
}

export const BusinessHeadliningLatestArticles = (props: BusinessHeadliningLatestArticlesProps) => {
    const { locale } = useLocale();
    const [featuredArticles, setFeaturedArticles] = useState<Article[]>(props.articles);

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        let cancelled = false;
        // Return on first load since articles would be populated via getStaticProps
        if (isFirstLoad && featuredArticles.length) {
            setIsFirstLoad(true);
            return;
        }

        setIsFirstLoad(false);

        getLatestArticles({ locale, offset: props.offset ?? 1, limit: 3 }).then((articles) => {
            if (!cancelled) {
                setFeaturedArticles(articles);
            }
        });

        return () => {
            cancelled = true;
        };
    }, [props.offset]);

    return <HeadliningArticlesBlock cardData={featuredArticles} showSignupCard={props.showSignupForAfterpay ?? false} />;
};
