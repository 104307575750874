import { BuilderConfig } from '../../types/Builder';
import BusinessResourceTypographyReview, { BusinessResourceTypographyReviewProps } from './business-resource-typography-review';

export const BusinessResourceTypographyReviewBuilderConfig = {
    name: 'Resource Typography Review',
    inputs: [
        {
            name: 'showDownloadButton',
            type: 'boolean',
        },
        {
            name: 'fonts',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'fontWeight',
                    type: 'string',
                    defaultValue: 'light',
                    enum: ['light', 'regular', 'demibold', 'bold'],
                },
                {
                    name: 'fontStyle',
                    type: 'string',
                    defaultValue: 'normal',
                    enum: ['normal', 'italic'],
                },
                {
                    name: 'downloadInfo',
                    type: 'list',
                    subFields: [
                        { name: 'variantName', type: 'string' },
                        {
                            name: 'downloadUrl',
                            type: 'url',
                            required: true,
                        },
                        {
                            name: 'extension',
                            type: 'string',
                            required: true,
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceTypographyReviewProps> = {
    component: BusinessResourceTypographyReview,
    config: BusinessResourceTypographyReviewBuilderConfig,
};

export default builderConfig;
