import { FC, useState } from 'react';
import { downloadAsset } from '../../helpers/ResourceHelper';
import { useWindowSize } from '../../hook/useWindowSize';
import { AssetType, ResourceType } from '../../types/Resource';
import BusinessResourceDivider from '../business-resource-divider/business-resource-divider';
import BussinessResourceDownloadButton from '../business-resource-download-button/business-resource-download-button';
import BusinessResourceDownloadCard from '../business-resource-download-card/business-resource-download-card';
import BusinessResourceDownloadGrid from '../business-resource-download-grid/business-resource-download-grid';
import BusinessResourceTextWrap from '../business-resource-text-wrap/business-resource-text-wrap';
import Icon, { IconType } from '../icons';
import styles from './business-resource-logos.module.scss';

export interface Props {
    downloadAllText?: string;
    resources?: ResourceType[];
}

const BusinessResourceLogos: FC<Props> = (props) => {
    const { downloadAllText = 'Download logos', resources } = props;
    const [loading, setLoading] = useState(false);
    const { width = 0 } = useWindowSize();

    const handleDownloadAll = async () => {
        setLoading(true);
        try {
            const assets: AssetType[] = [];
            resources?.forEach((resource) => assets.push(...(resource.assets || [])));
            if (assets.length > 0) {
                await downloadAsset(assets, 'Logos');
            }
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <BussinessResourceDownloadButton containerClass={styles.downloadButton} onClick={handleDownloadAll} disabled={loading}>
                {downloadAllText} <Icon type={IconType.Download} />
            </BussinessResourceDownloadButton>
            {width > 768 ? <BusinessResourceDivider /> : null}
            {resources?.map((resource, index) => {
                const { id, title, description, description2, assets } = resource;
                const largeAsset = assets?.[0];

                return (
                    <div key={id || Math.random()}>
                        {width > 768 && index !== 0 ? <BusinessResourceDivider /> : null}
                        <BusinessResourceTextWrap caption={title} content={description} />
                        {largeAsset ? <BusinessResourceDownloadCard item={largeAsset} type="large" /> : null}
                        <BusinessResourceTextWrap content={description2} />
                        {assets?.length ? <BusinessResourceDownloadGrid name={resource.type} items={assets.slice(1, assets.length)} /> : null}
                    </div>
                );
            })}
        </div>
    );
};

export default BusinessResourceLogos;
