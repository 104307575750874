import React, { FC, useState } from 'react';
import Download from '../icons/Icons/Download';
import { AssetType, ResourceType } from '../../types/Resource';
import styles from './business-resource-banners.module.scss';
import BusinessResourceDivider from '../business-resource-divider/business-resource-divider';
import BusinessResourceDownloadResourcesButtonContainer from '../business-resource-download-button/business-resource-download-resources-button-container';
import BusinessResourceSingleAssetVariantContainer from '../business-resource-asset-single-variant/business-resource-single-asset-variant-container';
import BusinessResourceGroupLabelContainer from '../business-resource-group-label/business-resource-group-label-container';

export interface Props {
    resources?: ResourceType[];
}

const BusinessResourceBanners: FC<Props> = ({ resources }) => {
    const [selectedAsset, setSelectedAsset] = useState<AssetType | null>(resources?.[0]?.assets?.[0] || null);
    return (
        <div className={styles.root}>
            <BusinessResourceDownloadResourcesButtonContainer resources={resources || []} rootContainerName="banners">
                Download All Banners <Download />
            </BusinessResourceDownloadResourcesButtonContainer>
            <div>
                <img className={styles.pagePreviewImage} src={selectedAsset?.variants?.[0]?.pagePreviewImage} alt="" />
            </div>
            <div>
                {resources?.map((resource, index) => (
                    <div key={index}>
                        <BusinessResourceGroupLabelContainer
                            assets={resource.assets || []}
                            rootFolderName={resource.title || 'banner'}
                            DownloadButtonProps={{
                                children: 'Download All',
                            }}
                        >
                            {resource?.title}
                        </BusinessResourceGroupLabelContainer>
                        <div className={styles.resourceContainer}>
                            {resource?.assets?.map((asset, index) => (
                                <BusinessResourceSingleAssetVariantContainer key={index} asset={asset} clickable onClick={(asset) => setSelectedAsset(asset)} />
                            ))}
                        </div>
                        {index !== resources?.length - 1 && <BusinessResourceDivider />}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BusinessResourceBanners;
