import classNames from 'classnames';
import { FC, useState } from 'react';
import { isWhite } from '../../helpers/ColourMap';
import { downloadAsset } from '../../helpers/ResourceHelper';
import { AssetType } from '../../types/Resource';
import BussinessResourceDownloadButton from '../business-resource-download-button/business-resource-download-button';
import { VariantClickType } from '../business-resource-download-button/business-resource-download-tooltip/business-resource-download-tooltip';
import Icon, { IconType } from '../icons';
import styles from './business-resource-download-card.module.scss';

export interface BusinessResourceDownloadCardProps {
    item: AssetType;
    type?: 'large' | 'default';
}

const BusinessResourceDownloadCard: FC<BusinessResourceDownloadCardProps> = (props) => {
    const { item, type } = props;
    const [loading, setLoading] = useState(false);

    if (!item) return null;
    const showingVariant = item.variants?.[0];
    if (!showingVariant) return null;

    const onClick = async (variant: VariantClickType) => {
        setLoading(true);
        try {
            let asset = item;
            let name;
            if (variant === 'ALL') {
                name = `${item.name ? `${item.name}` : ''}` || 'afterpay-assets';
            } else {
                name = `${variant.variantName}`.toLowerCase() || 'afterpay-assets';
                asset = {
                    ...item,
                    variants: [variant],
                };
            }

            await downloadAsset([asset], name);
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className={classNames(styles.container, type && styles[type], {
                [styles.bordered]: isWhite(showingVariant.backgroundColor),
            })}
        >
            <div className={styles.downloadContainer}>
                <div className={styles.download}>
                    <BussinessResourceDownloadButton disabled={loading} type="icon" item={item} onVariantClick={onClick}>
                        <Icon type={IconType.Download} />
                    </BussinessResourceDownloadButton>
                </div>
            </div>
            <div className={styles.box} style={{ background: showingVariant.backgroundColor }}>
                <img src={showingVariant.previewUrl} alt="" />
            </div>
        </div>
    );
};

export default BusinessResourceDownloadCard;
