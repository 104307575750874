import { BuilderConfig } from '../../types/Builder';
import BusinessArticleImage, { Props } from './business-article-image';

export const BusinessArticleImageBuilderConfig = {
    name: 'Article Image',
    inputs: [
        {
            name: 'image',
            type: 'file',
            required: true,
        },
        {
            name: 'autoHeight',
            type: 'boolean',
        },
        {
            name: 'autoWidth',
            type: 'boolean',
        },
        {
            name: 'squared',
            type: 'boolean',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessArticleImage,
    config: BusinessArticleImageBuilderConfig,
};

export default builderConfig;
