import { IconType } from '../icons';

export const ICONS = IconType;

export type ICON = typeof ICONS[keyof typeof ICONS];
export const COLORS = {
    MINT: 'mint',
    PLUM: 'plum',
    BLACK: 'black',
    WHITE: 'white',
} as const;

export enum POSITION {
    LEFT = 'Left',
    CENTER = 'Center',
    RIGHT = 'Right'
}

export type COLOR = typeof COLORS[keyof typeof COLORS];
export const SIZES = { NORMAL: 'normal', SMALL: 'small' } as const;

export type SIZE = typeof SIZES[keyof typeof SIZES];
