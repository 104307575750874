import React from 'react';

import styles from './business-resource-text-highlight.module.scss';
import classNames from 'classnames';

export interface Props {
    headline: string;
    text: string;
}

const BusinessResourceTextHighlight: React.FC<Props> = ({ headline, text }) => {
    return (
        <div className={classNames(styles.root)}>
            <div className={styles.headline}>{headline}</div>
            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default BusinessResourceTextHighlight;
