import { FC } from 'react';

import styles from './logo-usage-card.module.scss';

export interface LogoUsageItem {
    title: string;
    image: string;
}

interface LogoUsageCardProps {
    item: LogoUsageItem;
}

const LogoUsageCard: FC<LogoUsageCardProps> = ({ item }) => {
    const { title, image } = item;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.image}>
                <img src={image} alt="" />
            </div>
        </div>
    );
};

export default LogoUsageCard;
