import classNames from 'classnames';
import { FC, useState } from 'react';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { downloadAsset } from '../../helpers/ResourceHelper';
import { AssetType, AssetVariantType } from '../../types/Resource';
import BussinessResourceDownloadButton from '../business-resource-download-button/business-resource-download-button';
import Icon, { IconType } from '../icons';
import styles from './business-resource-banner-carousel.module.scss';

const options: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 45,
    allowTouchMove: true,
};

export interface BusinessResourceBannerCarouselProps {
    banners: {
        preview: string;
        downloadUrl: string;
        bannerName: string;
        extension: string;
    }[];
    ctaText?: string;
}

const BusinessResourceBannerCarousel: FC<BusinessResourceBannerCarouselProps> = (props) => {
    const { banners = [], ctaText } = props;
    const [loading, setLoading] = useState(false);

    const downloadURLs = [];
    banners.map((banner) => {
        if (banner.downloadUrl) {
            downloadURLs.push(banner.downloadUrl);
        }
    });

    const onDownloadAll = async () => {
        setLoading(true);
        try {
            const items: AssetType = {
                name: 'afterpay-banners',
                type: 'banner',
                variants: banners
                    .filter((banner) => !!banner.downloadUrl)
                    .map((banner) => {
                        return {
                            downloadUrl: banner.downloadUrl,
                            extension: banner.extension,
                            variantName: banner.bannerName,
                        } as AssetVariantType;
                    }),
            };

            await downloadAsset([items], 'afterpay-banners');
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classNames(styles.container, { [styles.withDownloadButton]: downloadURLs?.length > 0 })}>
            <div className={styles.content}>
                <Swiper {...options}>
                    {banners.map((banner, index) => (
                        <SwiperSlide key={index}>
                            <img className={styles.bannerPreview} src={banner.preview} alt="" />
                        </SwiperSlide>
                    ))}
                </Swiper>
                {downloadURLs?.length > 0 ? (
                    <div className={styles.download}>
                        <BussinessResourceDownloadButton disabled={loading} onClick={onDownloadAll}>
                            {ctaText || 'Download All Banners'} <Icon type={IconType.Download} />
                        </BussinessResourceDownloadButton>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default BusinessResourceBannerCarousel;
