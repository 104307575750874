import { BuilderConfig } from '../../types/Builder';
import BusinessResourceValueBlock, { BusinessResourceValueBlockProps } from './business-resource-value-block';

export const BusinessResourceValueBlockBuilderConfig = {
    name: 'Resource Value Block',
    inputs: [
        {
            name: 'title',
            type: 'string',
            required: true,
        },
        {
            name: 'items',
            type: 'list',
            defaultValue: [],
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'description',
                    type: 'richText',
                    defaultValue: '',
                    required: true,
                },
                {
                    name: 'image',
                    type: 'file',
                    required: true,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceValueBlockProps> = {
    component: BusinessResourceValueBlock,
    config: BusinessResourceValueBlockBuilderConfig,
};

export default builderConfig;
