import { BuilderConfig } from '../../types/Builder';
import BusinessResourceTextWrap, { Props } from './business-resource-text-wrap';

export const BusinessResourceTextWrapBuilderConfig = {
    name: 'Resource Text Wrap',
    inputs: [
        {
            name: 'caption',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'content',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'row',
            type: 'boolean',
            defaultValue: false,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceTextWrap,
    config: BusinessResourceTextWrapBuilderConfig,
};

export default builderConfig;
