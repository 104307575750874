import classNames from 'classnames';
import React, { FC } from 'react';
import { useWindowSize } from '../../hook/useWindowSize';
import Breadcrumb, { BreadcrumbItem } from '../breadcrumb/breadcrumb';

import styles from './business-resource-hero-sub.module.scss';

export type ResourceHeroSubProps = {
    image: string;
    title: string;
    description: string;
    breadcrumbs?: BreadcrumbItem[];
};

const ResourceHeroSub: FC<ResourceHeroSubProps> = (props) => {
    const { image, title, description, breadcrumbs } = props;
    const { width = 0 } = useWindowSize();

    return (
        <div className={classNames(styles.businessResourceHeroSubWrapper, { [styles.breadcrumb]: breadcrumbs && breadcrumbs.length })}>
            <div className={styles.businessResourceHeroSubBackgroundWrapper}>
                <div className={styles.box}>
                    <div className={styles.main} style={{ backgroundImage: `url(${image})` }}></div>
                </div>
            </div>

            {breadcrumbs && breadcrumbs.length ? (
                <div className={styles.businessResourceHeroSubBreadcrumb}>
                    <Breadcrumb breadcrumbs={breadcrumbs} type={width > 768 ? 'dark' : 'light'} />
                </div>
            ) : null}
            <div className={styles.businessResourceHeroSubOverlayWrapper}>
                <div className={styles.businessResourceHeroSubOverlayTitle}>{title}</div>
                <div className={styles.businessResourceHeroSubOverlayDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
        </div>
    );
};

export default ResourceHeroSub;
