import { FC, useState } from 'react';
import { downloadAsset } from '../../helpers/ResourceHelper';
import { AssetType, AssetVariantType } from '../../types/Resource';
import BusinessResourceDivider from '../business-resource-divider/business-resource-divider';
import BussinessResourceDownloadButton from '../business-resource-download-button/business-resource-download-button';
import { VariantClickType } from '../business-resource-download-button/business-resource-download-tooltip/business-resource-download-tooltip';
import Icon, { IconType } from '../icons';
import styles from './business-resource-typography-review.module.scss';
import TypographyReviewCard from './typography-review-card/typography-review-card';

export interface BusinessResourceTypographyReviewProps {
    fonts: { name: string; fontWeight: 'light' | 'regular' | 'demibold' | 'bold'; fontStyle: 'normal' | 'italic'; downloadInfo: AssetVariantType[] }[];
    showDownloadButton?: boolean;
}

const BusinessResourceTypographyReview: FC<BusinessResourceTypographyReviewProps> = (props) => {
    const { fonts, showDownloadButton = true } = props;
    const [loading, setLoading] = useState(false);

    const variants: AssetVariantType[] = [];
    fonts.forEach((font) => {
        if (Array.isArray(font.downloadInfo)) {
            variants.push(...font.downloadInfo);
        }
    });

    const onDownload = async (variant: VariantClickType) => {
        setLoading(true);
        try {
            const name = 'afterpay-fonts';
            let asset: AssetType = {
                name,
                variants: variant === 'ALL' ? variants : [variant],
            };

            await downloadAsset([asset], name);
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            {showDownloadButton && (
                <div className={styles.downloadButton}>
                    <BussinessResourceDownloadButton disabled={loading} item={{ variants }} onVariantClick={onDownload} allOption={false}>
                        Download Typeface Familly <Icon type={IconType.Download} />
                    </BussinessResourceDownloadButton>
                </div>
            )}
            {fonts?.map((font, index) => {
                return (
                    <div key={index}>
                        <TypographyReviewCard {...font} showDownloadButton={showDownloadButton} />
                        {index !== fonts.length - 1 ? <BusinessResourceDivider /> : null}
                    </div>
                );
            })}
        </div>
    );
};

export default BusinessResourceTypographyReview;
