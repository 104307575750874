import React, { FC, useState } from 'react';
import { AssetType } from '../../types/Resource';
import Download from '../icons/Icons/Download';
import styles from './business-resource-top-strips.module.scss';
import BusinessResourceDownloadAssetsButtonContainer from '../business-resource-download-button/business-resource-download-assets-button-container';
import BusinessResourceSingleAssetVariantContainer from '../business-resource-asset-single-variant/business-resource-single-asset-variant-container';

export interface Props {
    assets?: AssetType[];
}

const BusinessResourceTopStrips: FC<Props> = ({ assets = [] }) => {
    const [selectedAsset, setSelectedAsset] = useState<AssetType | null>(assets?.[0] || null);
    return (
        <div className={styles.root}>
            <BusinessResourceDownloadAssetsButtonContainer assets={assets} rootContainerName="top-strips">
                Download All Strips <Download />
            </BusinessResourceDownloadAssetsButtonContainer>
            <div className={styles.container}>
                <div className={styles.leftBlock}>
                    {assets.map((asset, index) => {
                        return <BusinessResourceSingleAssetVariantContainer clickable key={index} asset={asset} onClick={(asset) => setSelectedAsset(asset)} />;
                    })}
                </div>
                {selectedAsset && (
                    <div className={styles.item}>
                        <img className={styles.image} src={selectedAsset?.variants?.[0]?.pagePreviewImage} alt="" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BusinessResourceTopStrips;
