import { FC } from 'react';
import ImageZoom from '../image-zoom/image-zoom';
import styles from './business-resource-value-block.module.scss';

interface ItemProps {
    title: string;
    description: string;
    image: string;
}

export interface BusinessResourceValueBlockProps {
    title: string;
    items: ItemProps[];
}

const BusinessResourceValueBlock: FC<BusinessResourceValueBlockProps> = (props) => {
    const { title, items } = props;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.items}>
                {items?.map((item) => {
                    const { title, description, image } = item;

                    return (
                        <div key={`item_${title || Math.random()}`} className={styles.item}>
                            <div className={styles.backgroundWrapper}>
                                <div className={styles.backgroundBox}>
                                    <div className={styles.background} style={{ backgroundImage: `url(${image})` }}></div>
                                </div>
                            </div>
                            <div className={styles.itemMask}></div>
                            <div className={styles.itemTitle}>{title}</div>
                            <div className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BusinessResourceValueBlock;
