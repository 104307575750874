/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames';
import React from 'react';
import Icon, { IconType } from '../../icons';
import ImageZoom from '../../image-zoom/image-zoom';
import { VideoCardItemProps } from '../video-card-list-item';
import { VIDEO_CARD_ITEM_TYPES } from './video-card-item.constants';
import styles from './video-card-item.module.scss';

interface Props {
    item: VideoCardItemProps;
    ratio?: number[];
    isSingleBlock: boolean;
}

const VideoCardItem = (props: Props) => {
    const {
        typeOfItem,
        discountContent = '',
        discountText = '',
        imageBrandUrl = '',
        imageUrl = '',
        authorName = '',
        quote = '',
        ctaContent = '',
        ctaUrl = '',
        discountPercentImage = '',
    } = props.item;
    const { isSingleBlock } = props;
    const { ratio = [256, 256] } = props;
    switch (typeOfItem) {
        case VIDEO_CARD_ITEM_TYPES.IMAGE_BRAND:
            return (
                <div className={styles.discountCarouselItemImageBrand}>
                    <img src={imageBrandUrl} alt="" />
                </div>
            );
        case VIDEO_CARD_ITEM_TYPES.IMAGE:
            return (
                <div className={styles.discountCarouselItemImage}>
                    <ImageZoom ratio={ratio} src={imageUrl} />
                </div>
            );
        case VIDEO_CARD_ITEM_TYPES.QUOTE:
            let quoteClassName = classNames(styles.discountCarouselItemQuote);

            if (isSingleBlock) {
                quoteClassName = classNames(styles.discountCarouselItemQuote, styles.singleBlock);
            }
            return (
                <div className={quoteClassName}>
                    <q className={styles.discountCarouselItemQuoteContent}>{quote}</q>
                    <p className={styles.discountCarouselItemQuoteAuthorName}>- {authorName}</p>
                </div>
            );
        case VIDEO_CARD_ITEM_TYPES.DISCOUNT:
            return (
                <div className={styles.discountCarouselItemDiscount}>
                    {discountPercentImage ? (
                        <ImageZoom ratio={ratio} src={discountPercentImage} className='image-discount' />
                    ) : (
                        <>
                            <p className={styles.discountCarouselItemDiscountPercent}>{discountContent}</p>
                            <p className={styles.discountCarouselItemDiscountText}>{discountText}</p>
                        </>
                    )}
                </div>
            );
        case VIDEO_CARD_ITEM_TYPES.CTA_BLOCK:
            return (
                <div className={styles.discountCarouselItemCTABlock}>
                    <p className={styles.discountCarouselItemCTABlockContent}>{ctaContent} </p>
                    <div className={styles.discountCarouselItemCTABlockBtn}>
                        <span>Click here</span>
                        <Icon type={IconType.ArrowRightMedium} />
                    </div>
                    <a className={styles.discountCarouselItemCTABlockUrl} href={ctaUrl} target="_self" />
                </div>
            );
        default:
            return null;
    }
};

export default VideoCardItem;
