import classNames from 'classnames';
import React, { useContext } from 'react';
import { useWindowSize } from '../../hook/useWindowSize';
import { Article } from '../../types/Article';
import Breadcrumb, { BreadcrumbItem } from '../breadcrumb/breadcrumb';
import BusinessArticleHeroInfo from './business-article-single-hero-info/business-article-single-hero-info';
import styles from './business-article-single-hero.module.scss';
import BusinessVimeoEmbed from '../business-vimeo-embed/business-vimeo-embed';
import { DefaultLayoutContext } from '../../contexts/DefaultLayoutContext';
import Image from 'next/image';
import { getResizedBuilderImage } from '../../helpers/BuilderHelper';

export interface Props {
    article: Article;
    breadcrumbs: BreadcrumbItem[];
    isMarginLower?: boolean;
    isShowReadMore?: boolean;
    isFeaturedBlock?: boolean;
}

const BusinessArticleHero: React.FC<Props> = (props: Props) => {
    const { title, leadImage, categories, articleUrl, description, leadVideo } = props.article;
    const { breadcrumbs, isMarginLower = false, isShowReadMore = false, isFeaturedBlock } = props;
    const isUseLeadVideo = leadVideo && leadVideo.videoUrl;

    const classNameArticleHero = classNames(
        styles.businessArticleHeroContainer,
        { [styles.marginLower]: isMarginLower },
        {
            [styles.isFeatured]: isFeaturedBlock,
        }
    );

    const defaultLayoutContext = useContext(DefaultLayoutContext);
    const { width = 0 } = useWindowSize();

    const getBreadcrumbType = () => {
        if (isUseLeadVideo && defaultLayoutContext.type) {
            return defaultLayoutContext.type;
        }
        return width > 768 ? 'dark' : 'light';
    };

    return (
        <div className={classNameArticleHero}>
            <div className={styles.breadcrumb}>
                <Breadcrumb breadcrumbs={breadcrumbs} type={getBreadcrumbType()} />
            </div>
            {isUseLeadVideo ? (
                <>
                    <BusinessVimeoEmbed {...leadVideo} />
                </>
            ) : (
                <>
                    <div className={styles.businessArticleHeroWrapper}>
                        <div className={styles.businessArticleHeroBox}>
                            <Image
                                src={leadImage ?? ''}
                                alt={`Headline article image ${title}`}
                                style={{ objectFit: 'cover', objectPosition: 'center' }}
                                fill={true}
                                loading={'eager'}
                                loader={({ src, width, quality }) => {
                                    return `${src}?width=${width}&quality=${quality ?? 75}`;
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className={styles.businessArticleHeroInfoWrapper}>
                <BusinessArticleHeroInfo
                    {...{ title, articleUrl, categories, isShowReadMore, description: isFeaturedBlock ? description : undefined }}
                    layout={isUseLeadVideo ? 'simple' : undefined}
                    theme={defaultLayoutContext.type}
                />
            </div>
        </div>
    );
};

export default BusinessArticleHero;
