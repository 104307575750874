import { BuilderConfig } from '../../types/Builder';
import VideoHero, { Props } from './video-hero';

export const VideoHeroBuilderConfig = {
    name: 'Video Hero',
    inputs: [
        {
            name: 'vimeoId',
            type: 'string',
            require: true,
        },
        { name: 'videoContent', type: 'richText' },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: VideoHero,
    config: VideoHeroBuilderConfig,
};

export default builderConfig;
