import { BuilderConfig } from '../../types/Builder';
import BusinessResourceDivider, { BusinessResourceDividerProps } from './business-resource-divider';

export const BusinessResourceDividerBuilderConfig = {
    name: 'Resource Divider',
    inputs: [],
};

const builderConfig: BuilderConfig<BusinessResourceDividerProps> = {
    component: BusinessResourceDivider,
    config: BusinessResourceDividerBuilderConfig,
};

export default builderConfig;
