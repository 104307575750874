import { FC } from 'react';
import ColourCard, { ColorType } from './colour-card/colour-card';
import styles from './business-resource-colour-palette.module.scss';
import classNames from 'classnames';

export interface BusinessResourceColourPaletteProps {
    layout?: 'primary' | 'secondary';
    colours: ColorType[];
}

const BusinessResourceColourPalette: FC<BusinessResourceColourPaletteProps> = (props) => {
    const { layout = 'primary', colours } = props;

    return (
        <div className={classNames(styles.container, styles[layout])}>
            {colours?.map((colour, index) => (
                <ColourCard key={index} {...colour} />
            ))}
        </div>
    );
};

export default BusinessResourceColourPalette;
