import { BuilderConfig } from '../../types/Builder';
import BusinessResourceImageComparisonSlider, { BusinessResourceImageComparisonSliderProps } from './business-resource-image-comparison-slider';

export const BusinessResourceImageComparisonSliderBuilderConfig = {
    name: 'Resource Image Comparison Slider',
    inputs: [
        {
            name: 'title',
            type: 'string',
        },
        {
            name: 'leftImage',
            type: 'file',
            required: true,
        },
        {
            name: 'rightImage',
            type: 'file',
            required: true,
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceImageComparisonSliderProps> = {
    component: BusinessResourceImageComparisonSlider,
    config: BusinessResourceImageComparisonSliderBuilderConfig,
};

export default builderConfig;
