import React, { FC, useState } from 'react';
import Download from '../icons/Icons/Download';
import { AssetType } from '../../types/Resource';
import styles from './business-resource-hiws.module.scss';
import BusinessResourceDownloadAssetsButtonContainer from '../business-resource-download-button/business-resource-download-assets-button-container';
import BusinessResourceSingleAssetVariantContainer from '../business-resource-asset-single-variant/business-resource-single-asset-variant-container';

export interface Props {
    rows?: { assets?: AssetType[] }[];
}

const BusinessResourceHiws: FC<Props> = ({ rows }) => {
    const [selectedAsset, setSelectedAsset] = useState<AssetType | null>(rows?.[0]?.assets?.[0] || null);
    return (
        <div className={styles.root}>
            <BusinessResourceDownloadAssetsButtonContainer
                assets={
                    rows?.reduce<AssetType[]>((result, { assets }) => {
                        return result.concat(assets || []);
                    }, []) || []
                }
                rootContainerName="how-it-works"
            >
                Download All How It Works <Download />
            </BusinessResourceDownloadAssetsButtonContainer>
            {rows?.map?.((row, index) => (
                <div key={index} className={styles.resourceContainer}>
                    {row?.assets?.map((asset, index) => (
                        <BusinessResourceSingleAssetVariantContainer key={index} asset={asset} clickable onClick={(asset) => setSelectedAsset(asset)} />
                    ))}
                </div>
            ))}
            <div>
                <img className={styles.pagePreviewImage} src={selectedAsset?.variants?.[0]?.pagePreviewImage} alt="" />
            </div>
        </div>
    );
};

export default BusinessResourceHiws;
