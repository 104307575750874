import React, { FC } from 'react';
import { AssetType } from '../../types/Resource';
import styles from './business-resource-in-situ.module.scss';
import Download from '../icons/Icons/Download';
import BusinessResourceLinkButton from '../business-resource-link-button/business-resource-link-button';
import ArrowRightMedium from '../icons/Icons/ArrowRightMedium';
import BusinessResourceDivider from '../business-resource-divider/business-resource-divider';
import BusinessResourceDownloadAssetsButtonContainer from '../business-resource-download-button/business-resource-download-assets-button-container';

interface DownloadButton {
    text?: string;
    assets?: AssetType[];
}

interface MoreButton {
    text?: string;
    url?: string;
}

export interface Props {
    sections?: {
        title?: string;
        previewPages?: {
            name?: string;
            previewImage?: string;
        }[];
        downloadButton?: DownloadButton;
        moreButton?: MoreButton;
    }[];
}

const BusinessResourceInSitu: FC<Props> = ({ sections = [] }) => {
    const showDownloadButton = (downloadButton?: DownloadButton): boolean => !!downloadButton?.assets && !!downloadButton.assets.length;
    const showViewMoreButton = (moreButton?: MoreButton): boolean => !!moreButton?.url;
    return (
        <div className={styles.root}>
            {sections?.map(({ title, previewPages = [], downloadButton, moreButton }, index) => (
                <>
                    <div className={styles.section} key={index}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.previewContainer}>
                            {previewPages?.map(({ previewImage, name }, index) => (
                                <div key={index} className={styles.previewBlock}>
                                    <div className={styles.previewTitle}>{name}</div>
                                    <img className={styles.previewImage} src={previewImage} />
                                </div>
                            ))}
                        </div>
                        {(showDownloadButton(downloadButton) || showViewMoreButton(moreButton)) && (
                            <div className={styles.action}>
                                {showDownloadButton(downloadButton) && (
                                    <BusinessResourceDownloadAssetsButtonContainer
                                        assets={downloadButton?.assets || []}
                                        rootContainerName={title || 'in-situ'}
                                        containerClass={styles.mobileFullWidth}
                                        className={styles.mobileFullWidth}
                                    >
                                        {downloadButton?.text} <Download />
                                    </BusinessResourceDownloadAssetsButtonContainer>
                                )}
                                {showViewMoreButton(moreButton) && (
                                    <BusinessResourceLinkButton className={styles.mobileFullWidth} strong icon={<ArrowRightMedium />}>
                                        {moreButton?.text}
                                    </BusinessResourceLinkButton>
                                )}
                            </div>
                        )}
                    </div>
                    {index !== sections?.length - 1 && <BusinessResourceDivider />}
                </>
            ))}
        </div>
    );
};

export default BusinessResourceInSitu;
