import styles from './business-resource-group-label.module.scss';
import React, { FC, PropsWithChildren } from 'react';
import BusinessResourceDownloadButton, {
    BussinessResourceDownloadButtonProps as BusinessResourceDownloadButtonProps,
} from '../business-resource-download-button/business-resource-download-button';

export type BusinessResourceGroupLabelProps = PropsWithChildren<{
    DownloadButtonProps?: BusinessResourceDownloadButtonProps;
}>;

const BusinessResourceGroupLabel: FC<BusinessResourceGroupLabelProps> = ({ children, DownloadButtonProps }) => {
    return (
        <div className={styles.header}>
            <div className={styles.title}>{children}</div>
            {DownloadButtonProps && <BusinessResourceDownloadButton {...DownloadButtonProps} />}
        </div>
    );
};

export default BusinessResourceGroupLabel;
