import React, { useEffect, useState } from 'react';
import styles from './business-article-image.module.scss';
import classNames from 'classnames';

export interface Props {
    image: string;
    autoHeight: boolean;
    autoWidth: boolean;
    squared: boolean;
}

const BusinessArticleImage = (props: Props) => {
    const { image, autoHeight, autoWidth, squared } = props;
    const [imageBoxStyle, setImageBoxStyle] = useState<React.CSSProperties>({});
    const [articleImageWrapperStyle, setArticleImageWrapperStyle] = useState<React.CSSProperties>({});
    useEffect(() => {
        const img = new Image();
        img.onload = function () {
            if (autoWidth) {
                setArticleImageWrapperStyle({
                    maxWidth: Math.min(872, img.width),
                    width: '100%',
                });
            } else {
                setArticleImageWrapperStyle({});
            }
            if (autoHeight) {
                setImageBoxStyle({
                    paddingTop: `${(img.height / img.width) * 100}%`,
                });
            } else {
                setImageBoxStyle({
                    paddingTop: `${(581 / 872) * 100}%`,
                });
            }
        };
        img.src = image;
    }, [autoHeight, image, autoWidth]);

    return (
        <div className={styles.businessArticleImageContainer}>
            <div className={styles.businessArticleImageWrapper} style={articleImageWrapperStyle}>
                <div
                    className={classNames(styles.businessArticleImageBox, {
                        [styles.squared]: squared,
                    })}
                    style={imageBoxStyle}
                >
                    <div className={styles.businessArticleImage} style={{ backgroundImage: `url('${image}')` }}></div>
                </div>
            </div>
        </div>
    );
};

export default BusinessArticleImage;
