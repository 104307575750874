import { BuilderConfig } from '../../types/Builder';
import BusinessResourceMessaging, { BusinessResourceMessagingProps } from './business-resource-messaging';

export const BusinessResourceMessagingBuilderConfig = {
    name: 'Resource Messaging',
    inputs: [
        {
            name: 'content',
            type: 'richText',
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceMessagingProps> = {
    component: BusinessResourceMessaging,
    config: BusinessResourceMessagingBuilderConfig,
};

export default builderConfig;
