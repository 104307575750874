import React, { useCallback, useEffect, useState } from 'react';
import { getArticlesByCategory, getCategoriesFromIDs } from '../../helpers/BuilderHelper';
import { localifyLink, useLocale } from '../../helpers/LangHelper';
import { Article } from '../../types/Article';
import { Category } from '../../types/Category';
import HeadliningArticlesBlock from '../headlining-articles-block/headlining-articles-block';
import { TYPE_OF_CARD } from '../headlining-articles-block/headlining-articles-block.constants';
import Icon, { IconType } from '../icons';
import { LearningSeriesCategory } from '../learning-card/learning-card';

import styles from './series-collection-articles-block.module.scss';

export interface SeriesCollectionArticlesProps {
    titleSeries: string;
    descriptionSeries: string;
    viewAllText: string;
    category: string;

    categoryObject: Category;
    articles: Article[];

    typeOfCard?: TYPE_OF_CARD | undefined;
    includeLargeCard?: boolean;
    backgroundColor?: 'Transparent' | 'Orca Pastel';
}

const SeriesCollectionArticles = (props: SeriesCollectionArticlesProps) => {
    const { locale } = useLocale();

    const { titleSeries, descriptionSeries, viewAllText, typeOfCard, categoryObject, articles, includeLargeCard, backgroundColor = '' } = props;

    let viewAllUrl = localifyLink(`/${locale}${categoryObject?.categoryUrl}`, locale);

    return (
        <div className={styles.seriesCollectionBlock}>
            {backgroundColor ? <div className={styles[`seriesCollectionBlock${backgroundColor.replace(' ', '')}`]}></div> : null}
            <div className={styles.seriesHeader}>
                <div className={styles.infoSeries}>
                    <p className={styles.title}>{titleSeries}</p>
                    <p className={styles.description}>{descriptionSeries}</p>
                </div>
                <a href={viewAllUrl} className={styles.btnViewAll}>
                    <span>{viewAllText}</span>
                    <Icon type={IconType.ArrowRightMedium} />
                </a>
            </div>
            <HeadliningArticlesBlock cardData={articles} typeOfCard={typeOfCard} showSignupCard={false} includeLargeCard={includeLargeCard} debounce={true} />
        </div>
    );
};

export default SeriesCollectionArticles;
