import { BuilderConfig } from '../../types/Builder';
import BusinessResourceTextList, { Props } from './business-resource-text-list';

export const BusinessResourceTextListBuilderConfig = {
    name: 'Resource Text List',
    inputs: [
        {
            name: 'title',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'items',
            type: 'list',
            subFields: [
                {
                    name: 'content',
                    type: 'richText',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceTextList,
    config: BusinessResourceTextListBuilderConfig,
};

export default builderConfig;
