import { BuilderConfig } from '../../types/Builder';
import { IconType } from '../icons';
import ResourceTrainingBlock, { ResourceTraningBlockProps } from './business-resource-training-block';

export const ResourceTrainingBlockBuilderConfig = {
    name: 'Resource Training Block',
    inputs: [
        {
            name: 'title',
            type: 'string',
            required: true,
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
            required: true,
        },
        {
            name: 'list',
            type: 'list',
            defaultValue: [],
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'description',
                    type: 'richText',
                    defaultValue: '',
                    required: true,
                },
                {
                    name: 'image',
                    type: 'file',
                    required: true,
                },
                {
                    name: 'ctaText',
                    type: 'text',
                },
                {
                    name: 'ctaIcon',
                    type: 'text',
                    enum: [IconType.ArrowRightMedium, IconType.Download],
                    default: IconType.Download,
                },
                {
                    name: 'url',
                    type: 'url',
                    defaultValue: '',
                },
                {
                    name: 'openLinkInNewTab',
                    type: 'boolean',
                    defaultValue: true,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<ResourceTraningBlockProps> = {
    component: ResourceTrainingBlock,
    config: ResourceTrainingBlockBuilderConfig,
};

export default builderConfig;
