import { useState } from 'react';
import { downloadAsset } from '../helpers/ResourceHelper';
import { AssetType } from '../types/Resource';

const useDownloadAssets = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const downloadAssetWrapper = async (assets: AssetType[], rootFolderName: string) => {
        setLoading(true);
        try {
            await downloadAsset(assets, rootFolderName);
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };
    return {
        loading,
        downloadAssets: downloadAssetWrapper,
    };
};

export default useDownloadAssets;
