import React, { FC, useContext } from 'react';
import CheckboxAssetCard, { Props } from '../../../asset-card/checkbox-asset-card';
import { BusinessResourceAssetLibraryContext } from '../../context';
import { onMultiSelect } from '../../utils';
import { AssetType } from '../../../../types/Resource';

export interface AssetCardContainerProps extends Props {}

const AssetCardContainer: FC<AssetCardContainerProps> = ({ asset }) => {
    const { assets, setAssets } = useContext(BusinessResourceAssetLibraryContext);
    return (
        <CheckboxAssetCard
            checked={assets.some(({ id }) => id === asset.id)}
            onChange={() => {
                const newAssets = onMultiSelect<AssetType>(asset, assets, 'id');
                setAssets?.(newAssets);
            }}
            asset={asset}
            AssetCardProps={{
                RatioImageProps: {
                    maxHeight: 216,
                    minHeight: 120,
                },
            }}
        />
    );
};

export default AssetCardContainer;
