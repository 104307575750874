import React from 'react';
import { BACKGROUND_COLOR, BACKGROUND_COLORS } from './numerical-highlight-list.constants';
import styles from './numerical-highlight-list.module.scss';

export interface NumericalItem {
    title: string;
    description: string;
}

export interface Props {
    heading: string;
    list: NumericalItem[];
    backgroundColor?: BACKGROUND_COLOR;
}

const NumericalList: React.FC<Props> = (props: Props) => {
    const { heading, list, backgroundColor = BACKGROUND_COLORS.MINT } = props;

    const getBackgroundColor = (): string => {
        const backgroundColorMap = {
            [BACKGROUND_COLORS.MINT]: '#b2fce4',
            [BACKGROUND_COLORS.PLUM]: '#EADDF2',
        };
        return backgroundColorMap[backgroundColor];
    };

    return (
        <div style={{ backgroundColor: getBackgroundColor() }} className={styles.numericalListContainer}>
            <p className={styles.numericalHeading}>{heading}</p>
            <ul className={styles.numericalList}>
                {list.map((item, index) => {
                    return (
                        <li key={item.title + '' + index} className={styles.numericalItem}>
                            <div className={styles.numericalWrapper}>
                                <div className={styles.numericalTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
                                <div className={styles.numericalDescription} dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default NumericalList;
