import React, { useContext } from 'react';
import { DefaultLayoutContext } from '../../../contexts/DefaultLayoutContext';
import { Article } from '../../../types/Article';

import BusinessSingleArticleHero from '../../../components/business-article-single-hero/business-article-single-hero';

export interface FeaturedArticleHeroProps {
    article: Article;
}

export const FeaturedArticleHero = (props: FeaturedArticleHeroProps) => {
    const defaultContext = useContext(DefaultLayoutContext);

    return (
        <>
            {!!props.article && (
                <BusinessSingleArticleHero article={props.article} isMarginLower={true} breadcrumbs={defaultContext.breadcrumbs} isShowReadMore={true} isFeaturedBlock={true} />
            )}
        </>
    );
};
