import { baseDownloadAsset, resourceTypeToRequest } from '../helpers/ResourceHelper';
import { useState } from 'react';
import { ResourceType } from '../types/Resource';

const useDownloadResource = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const downloadResources = async (resources: ResourceType[], rootFolderName: string) => {
        setLoading(true);
        try {
            const body = resourceTypeToRequest(resources, rootFolderName);
            await baseDownloadAsset(body);
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };
    return {
        loading,
        downloadResources,
    };
};

export default useDownloadResource;
