import { FC } from 'react';
import FAQItem, { FAQItemType } from './business-resource-faq-item';
import styles from './business-resource-faq.module.scss';

export interface BusinessResourceFAQProps {
    faqs: FAQItemType[];
}

const BusinessResourceFAQ: FC<BusinessResourceFAQProps> = (props) => {
    const { faqs } = props;

    return (
        <div className={styles.container}>
            {faqs?.map((faq, index) => (
                <FAQItem key={index} item={faq} />
            ))}
        </div>
    );
};

export default BusinessResourceFAQ;
