import { BuilderConfig } from '../../types/Builder';
import BusinessResourceAssetLibrary, { Props } from './business-resource-asset-library';
import { resourcesInput } from '../../helpers/BuilderHelper';

export const BusinessResourceAssetBuilderConfig = {
    name: 'Resource Asset Library',
    inputs: [
        {
            name: 'showDownloadButton',
            type: 'boolean',
        },
        {
            name: 'columns',
            type: 'number',
        },
        {
            name: 'limitRows',
            type: 'number',
        },
        resourcesInput,
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceAssetLibrary,
    config: BusinessResourceAssetBuilderConfig,
};

export default builderConfig;
