import React, { FC } from 'react';
import BusinessResourceDownloadResourcesButtonContainer, {
    Props as BusinessResourceDownloadResourcesButtonContainerProps,
} from '../business-resource-download-button/business-resource-download-resources-button-container';
import Download from '../icons/Icons/Download';

export interface Props extends BusinessResourceDownloadResourcesButtonContainerProps {
    text?: string;
}

const BusinessResourceDownloadAllButton: FC<Props> = ({ text, ...props }) => {
    return (
        <BusinessResourceDownloadResourcesButtonContainer {...props}>
            {text} <Download />
        </BusinessResourceDownloadResourcesButtonContainer>
    );
};

export default BusinessResourceDownloadAllButton;
