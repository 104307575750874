export const DEFAULT_BACKGROUND_COLORS = {
    MINT: 'mint',
    PLUM: 'plum',
    ORCA: 'orca',
    ORCA_PASTEL: 'orcaPastel',
    BLACK: 'black',
    WHITE: 'white',
};

export type DEFAULT_BACKGROUND_COLOR = typeof DEFAULT_BACKGROUND_COLORS[keyof typeof DEFAULT_BACKGROUND_COLORS];

export const mapNameToColour = (color: DEFAULT_BACKGROUND_COLOR) => {
    switch (color) {
        case 'mint':
            return '#B2FCE4';
        case 'plum':
            return '#EADDF2';
        case 'orca':
            return '#3375C9';
        case 'orcaPastel':
            return '#EFF4FB';
        case 'black':
            return '#000000';
        case 'white':
            return '#FFFFFF';
    }
};

export const hexToRgb = (hex: string) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

export const rgbToHex = (r: number, g: number, b: number) => {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const isWhite = (color?: string) => {
    if (!color) return false;
    return !!color.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i);
};
