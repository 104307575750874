import React from 'react';
import styles from './video-card-section.module.scss';

export interface Props {
    children?: JSX.Element;
}

const VideoCardSection = (props: Props) => {
    const { children } = props;
    return <div className={styles.videoCardSection}>{children}</div>;
};

export default VideoCardSection;
