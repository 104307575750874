import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import SwiperCore, { Lazy, Navigation, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { useWindowSize } from '../../hook/useWindowSize';
import { Article } from '../../types/Article';
import ArticleCard, { ArticleCardType } from '../article-card/article-card';
import Icon, { IconType } from '../icons';
import LearningCard, { LearningSeriesCategory } from '../learning-card/learning-card';
import SignUpCard from '../sign-up-card/sign-up-card';
import { TYPES_OF_CARD, TYPE_OF_CARD } from './headlining-articles-block.constants';
import styles from './headlining-articles-block.module.scss';

SwiperCore.use([Lazy, Navigation]);

interface Props {
    cardData: Article[] | LearningSeriesCategory[];
    showSignupCard?: boolean;
    typeOfCard?: TYPE_OF_CARD;
    includeLargeCard?: boolean;

    debounce?: boolean;
}

const HeadliningArticlesBlock = (props: Props) => {
    const { cardData, showSignupCard = true, typeOfCard = TYPES_OF_CARD.ARTICLE, includeLargeCard } = props;
    const windowSize = useWindowSize();
    const windowWidth: number = windowSize.width || 0;
    const isMobile: boolean = windowWidth <= 768 ? true : false;
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const navigationConfig = { prevEl: prevRef.current ? prevRef.current : undefined, nextEl: nextRef.current ? nextRef.current : undefined };

    // Because layout breaks if you try to populate the DOM before slider is initalised, we debounce it with state, Only set data after swiper is initialised
    const [swiperCardList, setSwiperCardList] = useState<Article[] | LearningSeriesCategory[]>(props.debounce ? [] : cardData);

    const { ref, inView } = useInView({ triggerOnce: true });

    const [swiper, setSwiper] = useState<any>(null);

    const swiperOption: SwiperOptions = {
        navigation: navigationConfig,
        slidesPerView: 'auto',
        spaceBetween: isMobile ? 8 : 24,
        centeredSlides: isMobile,
        centeredSlidesBounds: true,
        watchOverflow: true,
        lazy: { loadPrevNext: true, loadPrevNextAmount: 1 },
        updateOnWindowResize: true,

        init: false,
    };

    useEffect(() => {
        if (swiper && inView) {
            swiper.init();
            setSwiperCardList(cardData);
        }
    }, [swiper, inView, cardData]);

    const cardTemplate = [ArticleCardType.Large, ArticleCardType.Small, ArticleCardType.Small];

    const getCard = (item: Article | LearningSeriesCategory, cardSize?: ArticleCardType) => {
        switch (typeOfCard) {
            case TYPES_OF_CARD.ARTICLE:
                return <ArticleCard type={cardSize || ArticleCardType.Small} article={item as Article} fixedWidth />;
            case TYPES_OF_CARD.LEARNING:
                return <LearningCard learningSeries={item as LearningSeriesCategory} />;
            default:
                return <ArticleCard type={cardSize || ArticleCardType.Small} article={item as Article} fixedWidth />;
        }
    };

    const renderArticleItem = (item: Article | LearningSeriesCategory, index: number) => {
        let cardSize = ArticleCardType.Small;
        if (includeLargeCard && !isMobile) {
            cardSize = cardTemplate[index % cardTemplate.length];
        }
        if (index === cardData.length - 1) {
            return (
                <SwiperSlide
                    className={classNames(styles.headliningArticlesBlockSlide, { [styles.headliningArticlesBlockSlideLarge]: cardSize === ArticleCardType.Large })}
                    key={`card_slide_key_${index}`}
                >
                    {showSignupCard ? <SignUpCard /> : getCard(item, cardSize)}
                </SwiperSlide>
            );
        }
        return (
            <SwiperSlide
                className={classNames(styles.headliningArticlesBlockSlide, { [styles.headliningArticlesBlockSlideLarge]: cardSize === ArticleCardType.Large })}
                key={`card_slide_key_${index}`}
            >
                {getCard(item, cardSize)}
            </SwiperSlide>
        );
    };

    return (
        <div className={styles.headliningArticlesBlock} ref={ref}>
            <div ref={nextRef} className={styles.swiperButtonNext}>
                <Icon type={IconType.ArrowRightMedium} />
            </div>
            <div ref={prevRef} className={styles.swiperButtonPrev}>
                <Icon type={IconType.ArrowRightMedium} />
            </div>
            <Swiper
                {...swiperOption}
                onInit={(swiper) => {
                    if (!swiper) {
                        return;
                    }
                    const navigation = swiper?.params.navigation as any;
                    navigation.prevEl = prevRef.current;
                    navigation.nextEl = nextRef.current;
                    swiper.navigation.update();
                }}
                onSwiper={(sw) => {
                    setSwiper(sw);
                }}
                className={styles.headliningArticlesSlider}
            >
                {(swiperCardList || []).map((item: Article | LearningSeriesCategory, index: number) => renderArticleItem(item, index))}
            </Swiper>
        </div>
    );
};

export default HeadliningArticlesBlock;
