import classNames from 'classnames';
import React from 'react';
import { replaceOrAddLocale, useLocale } from '../../../helpers/LangHelper';
import { Size, useWindowSize } from '../../../hook/useWindowSize';
import { Category } from '../../../types/Category';
import { BreadcrumbType } from '../../breadcrumb/breadcrumb';
import CategoriesComponent from '../../categories/categories';
import Icon, { IconType } from '../../icons';
import styles from './business-article-single-hero-info.module.scss';

export interface Props {
    title: string;
    description?: string;
    categories: Category[];
    isShowReadMore?: boolean;
    articleUrl?: string | '';
    layout?: 'default' | 'simple';
    theme?: BreadcrumbType | undefined;
}

const BusinessArticleHeroInfo: React.FC<Props> = ({ title, description, categories, articleUrl, isShowReadMore, layout = 'default', theme }: Props) => {
    const windowSize: Size = useWindowSize();
    const { locale } = useLocale();
    const isMobile = (): boolean => {
        const windowWidth: number = windowSize.width || 0;
        if (windowWidth < 768) {
            return true;
        } else {
            return false;
        }
    };
    const classNameArticleZHeroInfo = classNames(styles.businessArticleHeroInfo, { 'article-hero-info': true }, styles[layout]);
    return (
        <div className={classNameArticleZHeroInfo}>
            {categories && (
                <div className={styles.categories}>
                    <CategoriesComponent
                        color={theme !== undefined ? (theme === 'dark' ? 'grey' : 'white') : undefined}
                        type={isMobile() ? 'SMALL' : 'LARGE'}
                        categories={categories}
                    />
                </div>
            )}
            <h1 className={classNames(styles.title, styles[theme || ''])}>
                {title}
                {description && <div className={styles.description}>{description}</div>}
            </h1>
            {isShowReadMore && articleUrl && (
                <a className={styles.readMoreUrl} href={replaceOrAddLocale(articleUrl, locale)} target="_self" rel="noopener">
                    Read More
                    <Icon type={IconType.ArrowRightBig} />
                </a>
            )}
        </div>
    );
};

export default BusinessArticleHeroInfo;
