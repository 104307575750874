import { BuilderConfig } from '../../types/Builder';
import BusinessTextCaption, { Props } from './business-text-caption';

export const BusinessTextWrapBuilderConfig = {
    name: 'Text Caption',
    inputs: [
        {
            name: 'text',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessTextCaption,
    config: BusinessTextWrapBuilderConfig,
};

export default builderConfig;
