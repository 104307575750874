import React, { useContext, useState } from 'react';
import { CategoryCtx } from '../../shared/sharedContext';
import { Category } from '../../types/Category';
import Icon, { IconType } from '../icons';
import styles from './category-filter.module.scss';

interface Props {}

const CategoryFilter = (props: Props) => {
    const [isShownCategories, setIsShownCategories] = useState<boolean>(false);
    const categoryCtx = useContext(CategoryCtx);

    const handleCategories = (category: Category) => {
        if (categoryCtx.categoriesToFilter.some(_category => _category.id === category.id)) {
            return categoryCtx.setCategoriesToFilter(categoryCtx.categoriesToFilter.filter((_category) => _category.id !== category.id));
        }
        return categoryCtx.setCategoriesToFilter([...categoryCtx.categoriesToFilter, category]);
    };
    return (
        <div className={styles.categoryFilter}>
            <div className={styles.filterButton + ' ' + styles[isShownCategories ? 'open' : 'close']} onClick={() => setIsShownCategories(!isShownCategories)}>
                <Icon type={IconType.Filter} />
                <span>Filter</span>
                <div className={styles.filterIconDown}>
                    <Icon type={IconType.Down} />
                </div>
            </div>
            <div className={styles.categories + ' ' + styles[isShownCategories ? 'open' : 'close']}>
                <div className={styles.categoriesList}>
                    {(categoryCtx.allCategories || []).map((category: Category, index: number) => {
                        const isItemChecked = categoryCtx.categoriesToFilter.some(_category => _category.id === category.id);
                        return (
                            <div key={index} className={styles.categoryName + ' ' + styles[isItemChecked ? 'checked' : 'unCheck']} onClick={() => handleCategories(category)}>
                                {category.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CategoryFilter;
