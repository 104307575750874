import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getLatestArticles } from '../../helpers/BuilderHelper';
import { useLocale } from '../../helpers/LangHelper';
import { useAsync } from '../../hook/useAsync';
import { Article } from '../../types/Article';
import ArticleGrid from '../article-grid/article-grid';
import { ArticleGridSource } from './business-article-grid.constants';

export interface BusinessArticleGridProps {
    source: ArticleGridSource;
    offset: number;

    initialArticles: Article[];
}

export const BusinessArticleGrid = (props: BusinessArticleGridProps) => {
    const { locale } = useLocale();
    const [articles, setArticles] = useState<Article[]>(props.initialArticles ?? []);
    const [offset, setOffsetArticle] = useState<number>(props.offset);
    const [isLoadingMoreArticles, setIsLoadingMoreArticles] = useState<boolean>(false);

    const getUniqueArticles = (newArticles: Article[]) => {
        let mapper: { [id: string]: Article } = {};

        newArticles.forEach((article) => {
            mapper[article.builderModel.id] = article;
        });

        return Object.values(mapper);
    };

    useEffect(() => {
        let cancelled = false;

        if (offset !== props.offset) {
            setIsLoadingMoreArticles(true);
            getLatestArticles({ locale, offset, limit: props.offset !== offset ? 10 : 9 })
                .then((newArticles) => {
                    if (!cancelled && newArticles) {
                        setArticles((oldArticles) => getUniqueArticles([...oldArticles, ...newArticles]));
                    }
                })
                .finally(() => {
                    if (!cancelled) {
                        setIsLoadingMoreArticles(false);
                    }
                });
        }

        return () => {
            cancelled = true;
        };
    }, [offset]);

    return <ArticleGrid articles={articles} isLoadingMoreArticles={isLoadingMoreArticles} setOffsetArticle={setOffsetArticle} offsetArticle={offset} />;
};
