import React, { FC } from 'react';
import styles from './business-resource-super-text.module.scss';

export interface Props {
    text: string;
}

const BusinessResourceSuperText: FC<Props> = ({ text }) => {
    return <div className={styles.root}>{text}</div>;
};

export default BusinessResourceSuperText;
