import { BuilderConfig } from '../../types/Builder';
import CategoryHero, { Props } from './category-hero';

export const CategoryHeroBuilderConfig = {
    name: 'Category Hero',
    inputs: [
        {
            name: 'breadcrumbs',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    defaultValue: '',
                },
                {
                    name: 'link',
                    type: 'url',
                    defaultValue: '',
                },
            ],
        },
        {
            name: 'categoryHeroTitle',
            type: 'longText',
            defaultValue: '',
        },
        {
            name: 'categoryHeroDescription',
            type: 'longText',
            defaultValue: '',
        },
        {
            name: 'backgroundColor',
            type: 'string',
            defaultValue: 'Orca Pastel',
            enum: ['Blum', 'Orca Pastel'],
        },
        {
            name: 'backgroundCoverContent',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'showFilter',
            type: 'boolean',
            defaultValue: true,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: CategoryHero,
    config: CategoryHeroBuilderConfig,
};

export default builderConfig;
