import { GET_ALL_CATEGORY } from '../../helpers/CategoryHelper';
import { BuilderConfig } from '../../types/Builder';
import LearningSeriesBlock, { LearningSeriesBlockProps } from './learning-series-block';

export const LearningSeriesBlockPropsBuilderConfig = {
    name: 'Learning series block',
    inputs: [
        {
            name: 'titleSeries',
            type: 'string',
        },
        {
            name: 'descriptionSeries',
            type: 'longText',
            defaultValue: '',
        },
        {
            name: 'viewAllUrl',
            type: 'url',
            defaultValue: '',
        },
        {
            name: 'viewAllText',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'category1',
            type: 'dynamic-dropdown',
            options: {
                url: GET_ALL_CATEGORY,
                mapper: `({data}) => {
                  return ({
                    category: Object.values(data)[0].reduce((rs, curr) => {
                      return [...rs, { value: curr.id, name: curr.name }]
                    }, [])
                  })
                }`,
                expectMultipleDropdowns: false,
            },
        },
        {
            name: 'category2',
            type: 'dynamic-dropdown',
            options: {
                url: GET_ALL_CATEGORY,
                mapper: `({data}) => {
                  return ({
                    category: Object.values(data)[0].reduce((rs, curr) => {
                      return [...rs, { value: curr.id, name: curr.name }]
                    }, [])
                  })
                }`,
                expectMultipleDropdowns: false,
            },
        },
        {
            name: 'category3',
            type: 'dynamic-dropdown',
            options: {
                url: GET_ALL_CATEGORY,
                mapper: `({data}) => {
                  return ({
                    category: Object.values(data)[0].reduce((rs, curr) => {
                      return [...rs, { value: curr.id, name: curr.name }]
                    }, [])
                  })
                }`,
                expectMultipleDropdowns: false,
            },
        },
        {
            name: 'backgroundColor',
            type: 'string',
            enum: ['Orca Pastel'],
        },
    ],
};

const builderConfig: BuilderConfig<LearningSeriesBlockProps> = {
    component: LearningSeriesBlock,
    config: LearningSeriesBlockPropsBuilderConfig,
};

export default builderConfig;
