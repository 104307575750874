/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import React from 'react';
import { useWindowSize } from '../../hook/useWindowSize';
import { VideoItem } from '../business-resource-video-list/business-resource-video-list';
import Icon, { IconType } from '../icons';
import ImageZoom from '../image-zoom/image-zoom';
import styles from './video-card-selected.module.scss';

export interface Props {
    video: VideoItem | undefined;
}

const VideoCardSelected = (props: Props) => {
    const { video } = props;
    const windowSize = useWindowSize();

    const windowWidth = windowSize?.width || 0;
    const isMobile = windowWidth && windowWidth <= 768 ? true : false;
    const ratioImage = isMobile ? [345, 345] : [720, 638];

    return (
        <div className={styles.videoWrap}>
            <div className={styles.videoWrapButtonPlay}>
                <Icon type={IconType.Triangle} />
            </div>
            <ImageZoom ratio={ratioImage} src={video?.leadImage ?? ''} />
            <div className={styles.videoOverlay}></div>
            <div className={styles.videoContainer}>
                <Image
                    className={styles.videoItemImageBrand}
                    src={video?.imageBrandUrl ?? ''}
                    alt=""
                    priority={false}
                    loading={'lazy'}
                    width={160}
                    height={32}
                    fill={false}
                    style={{ objectFit: 'scale-down', objectPosition: 'left' }}
                />
                <p className={styles.videoContainerTitle}>{video?.title}</p>
                {video?.description ? <p className={styles.videoContainerDescription}>{video?.description}</p> : null}
                <div className={styles.videoContainerWatchNow}>
                    <span>Watch Now</span>
                    <Icon type={IconType.ArrowRightMedium} />
                </div>
            </div>
            <a href={video?.videoUrl} className={styles.videoLink}></a>
        </div>
    );
};

export default VideoCardSelected;
