import React, { useEffect, useState } from 'react';
import { getArticlesByCategory, getCategoryFromID, getCategoryFromName } from '../../helpers/BuilderHelper';
import { localifyLink, useLocale } from '../../helpers/LangHelper';
import { Article } from '../../types/Article';
import { Category } from '../../types/Category';
import CategoriesComponent from '../categories/categories';
import CommunityCard from '../community-card/community-card';
import Icon, { IconType } from '../icons';
import styles from './category-feature.module.scss';

export interface CategoryFeatureProps {
    category: Category | string;

    categoryArticles?: Article[];
}

const CategoryFeature = (props: CategoryFeatureProps) => {
    const { locale } = useLocale();

    const [featuredArticles, setFeaturedArticles] = useState<Article[]>(props.categoryArticles ?? []);
    const [articleSelected, setArticleSelected] = useState<Article>(featuredArticles[0]);
    const [category, setCategory] = useState<Category>();

    const linkReadAll = localifyLink(`/${locale}` + category?.categoryUrl, locale);
    const categoryName = category?.name;

    useEffect(() => {
        let cancelled = false;

        if (typeof props.category === 'string') {
            getCategoryFromID(props.category).then((foundCategory) => {
                if (!cancelled && foundCategory) {
                    setCategory(foundCategory);
                }
            });
        } else {
            setCategory(props.category);
        }

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(() => {
        let cancelled = false;

        if (category && !props.categoryArticles) {
            getArticlesByCategory({ locale, limit: 3, categoryIds: [category?.id] }).then((articles) => {
                if (!cancelled) {
                    setFeaturedArticles(articles);
                }
            });
        }

        return () => {
            cancelled = true;
        };
    }, [category]);

    useEffect(() => {
        if (!articleSelected) {
            setArticleSelected(featuredArticles[0]);
        }
    }, [featuredArticles]);

    if (!featuredArticles || featuredArticles.length === 0) {
        return null;
    }

    const renderArticleItem = (article: Article) => {
        return (
            <div
                key={`article_feature_${article.articleUrl}`}
                className={styles.articleItem}
                onMouseEnter={() => setArticleSelected(article)}
                onClick={() => setArticleSelected(article)}
            >
                <CategoriesComponent categories={article.categories} type="SMALL" />
                <div className={styles.articleContainer}>
                    <div className={styles.articleTitle}>{article.title}</div>
                    <Icon type={IconType.ArrowRightBig} />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.categoryFeature}>
            <div className={styles.categoryFeatureContainer}>
                <div className={styles.categoryHeader}>
                    <p className={styles.headerText}>{categoryName}</p>
                    <div className={styles.articleCardButtonReadAll}>
                        <span className={styles.businessButtonText}>Read all</span>
                        <Icon type={IconType.ArrowRightMedium} />
                        <a href={linkReadAll} className={styles.linkReadAllArticle}></a>
                    </div>
                </div>
                {featuredArticles?.map((article: Article) => renderArticleItem(article))}
            </div>
            <div className={styles.articlesFeature}>
                <CommunityCard article={articleSelected} />
            </div>
        </div>
    );
};

export default CategoryFeature;
