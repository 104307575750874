import React from 'react';

const ArrowRightXSmall = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M11.9999 8L1.33325 8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.66675 2.66668L12.0001 8.00001L6.66675 13.3333" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowRightXSmall;
