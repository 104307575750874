import React from 'react';
import { MEDIA_RATIO, Ratio } from '../../types/Media';
import styles from './business-youtube-embed.module.scss';

export interface Props {
    videoUrl: string;
    ratio?: Ratio;
}

const BusinessYoutubeEmbed: React.FC<Props> = (props: Props) => {
    const { videoUrl, ratio = '1:1' } = props;

    const renderVidPlayer = () => {
        const [width, height] = MEDIA_RATIO[ratio];
        const youtubeVideoPaddingTop = (height / width) * 100;
        return (
            <div className={styles.youtubeVideo} style={{ paddingTop: `${youtubeVideoPaddingTop}%` }}>
                <iframe width="872" height="872" src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
            </div>
        );
    };
    return <div className={styles.businessYoutubeEmbedContainer}>{renderVidPlayer()}</div>;
};

export default BusinessYoutubeEmbed;
