import React, { FC, useState } from 'react';
import Download from '../icons/Icons/Download';
import { AssetType } from '../../types/Resource';
import styles from './business-resource-lightbox.module.scss';
import BusinessResourceDownloadAssetsButtonContainer from '../business-resource-download-button/business-resource-download-assets-button-container';
import BusinessResourceSingleAssetVariantContainer from '../business-resource-asset-single-variant/business-resource-single-asset-variant-container';

export interface Props {
    assets?: AssetType[];
}

const BusinessResourceLightbox: FC<Props> = ({ assets }) => {
    const [selectedAsset, setSelectedAsset] = useState<AssetType | null>(assets?.[0] || null);
    return (
        <div className={styles.root}>
            <BusinessResourceDownloadAssetsButtonContainer assets={assets || []} rootContainerName="lightbox">
                Download All Lightbox <Download />
            </BusinessResourceDownloadAssetsButtonContainer>
            <div>
                <img className={styles.pagePreviewImage} src={selectedAsset?.variants?.[0]?.pagePreviewImage} alt="" />
            </div>
            <div className={styles.resourceContainer}>
                {assets?.map((asset, index) => (
                    <BusinessResourceSingleAssetVariantContainer key={index} asset={asset} clickable onClick={(asset) => setSelectedAsset(asset)} />
                ))}
            </div>
        </div>
    );
};

export default BusinessResourceLightbox;
