import classNames from 'classnames';
import { FC, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { hexToRgb, isWhite, rgbToHex } from '../../../helpers/ColourMap';
import Icon, { IconType } from '../../icons';
import styles from './colour-card.module.scss';

export interface ColorType {
    colour: string;
    name: string;
    description?: string;
    textColor: string;
}

interface ColorCardProps extends ColorType {}

const ColourCard: FC<ColorCardProps> = (props) => {
    const { colour, name, description, textColor } = props;
    const [rgbCopied, setRgbCopied] = useState(false);
    const [hexCopied, setHexCopied] = useState(false);

    if (!colour) return null;
    let rgbColor: any = hexToRgb(colour);
    if (!rgbColor) {
        const rgb = colour.replace(/[^\d,]/g, '').split(',');
        rgbColor = {};
        rgbColor.r = Number.parseInt(rgb[0], 10);
        rgbColor.g = Number.parseInt(rgb[1], 10);
        rgbColor.b = Number.parseInt(rgb[2], 10);
    }
    const hex = rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b);

    return (
        <div className={classNames(styles.container, { [styles.bordered]: isWhite(colour) })} style={{ backgroundColor: colour }}>
            <div className={styles.content} style={{ color: textColor }}>
                <div className={styles.name}>{name}</div>
                {description ? <div className={styles.description}>{description}</div> : null}
                <div className={classNames(styles.colorCode, { [styles.copied]: rgbCopied })}>
                    <CopyToClipboard
                        text={colour}
                        onCopy={() => {
                            setRgbCopied(true);
                            setTimeout(() => {
                                setRgbCopied(false);
                            }, 3000);
                        }}
                    >
                        <div>
                            <span className={styles.copyIcon}>
                                <Icon type={IconType.Copy}></Icon>
                            </span>
                            {rgbCopied ? 'Copied!' : `RGB ${rgbColor.r} ${rgbColor.g} ${rgbColor.b}`}
                        </div>
                    </CopyToClipboard>
                </div>
                <div className={classNames(styles.colorCode, { [styles.copied]: hexCopied })}>
                    <CopyToClipboard
                        text={hex}
                        onCopy={() => {
                            setHexCopied(true);
                            setTimeout(() => {
                                setHexCopied(false);
                            }, 3000);
                        }}
                    >
                        <div>
                            <span className={styles.copyIcon}>
                                <Icon type={IconType.Copy}></Icon>
                            </span>
                            {hexCopied ? 'Copied!' : `HEX ${hex}`}
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    );
};

export default ColourCard;
