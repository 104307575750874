import React, { FC } from 'react';
import Icon, { IconType } from '../../icons';

import styles from './business-resource-training-block-item.module.scss';

export type ResourceTraningItemBlockProps = {
    title: string;
    description: string;
    image: string;
    ctaText?: string;
    ctaIcon?: IconType;
    url: string;
    openLinkInNewTab?: boolean;
};

const ResourceTraningBlockItem: FC<ResourceTraningItemBlockProps> = (props) => {
    const { title, description, image, ctaText, ctaIcon, url, openLinkInNewTab } = props;

    return (
        <div className={styles.businessResourceTrainingBlockItemContainer}>
            <div className={styles.businessResourceTrainingBlockItemBackground}>
                <div className={styles.box}>
                    <div className={styles.main} style={{ backgroundImage: `url(${image})` }}></div>
                </div>
            </div>
            <div className={styles.businessResourceTrainingBlockItemContent}>
                <div className={styles.contentTitle}>{title}</div>
                <div className={styles.contentDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
                <a href={url} className={styles.contentCTA} rel="noreferrer" target={openLinkInNewTab ? "_blank" : "_self"}>
                    <span>{ctaText ?? 'Download'}</span>
                    <Icon type={ctaIcon ?? IconType.Download} />
                </a>
            </div>
        </div>
    );
};

export default ResourceTraningBlockItem;
