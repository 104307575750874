import React, { FC } from 'react';
import Head from 'next/head';
import styles from './trustpilot-widget.module.scss';
import classNames from 'classnames';

export type TrustpilotWidgetProps = {
    id: 'afterpay' | 'clearpay';
};

const TRUSTPILOT_CONFIG = {
    afterpay: {
        businessunitId: '56c5d65b0000ff0005891636',
        href: 'https://www.trustpilot.com/review/afterpay.com',
    },
    clearpay: {
        businessunitId: '59e8a1f50000ff0005ae12db',
        href: 'https://www.trustpilot.com/review/www.clearpay.co.uk',
    },
};

const TrustpilotWidget: FC<TrustpilotWidgetProps> = ({ id = 'afterpay' }) => {
    const config = TRUSTPILOT_CONFIG[id];

    return (
        <>
            <Head>
                <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
            </Head>
            <div
                className={classNames('trustpilot-widget', styles['trustpilot-widget-container'])}
                data-locale="en-US"
                data-template-id="5406e65db0d04a09e042d5fc"
                data-businessunit-id={config.businessunitId}
                data-style-height="28px"
                data-style-width="100%"
                data-theme="light"
            >
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={config.href} target="_blank" rel="noopener">
                    Trustpilot
                </a>
            </div>
        </>
    );
};

export default TrustpilotWidget;
