export function compareImages(img: any, sliderId: string, sliderClassName: string) {
    let slider: HTMLDivElement;
    // let img;
    let clicked = 0;
    let w: number;
    let h: number;
    /* Get the width and height of the img element */
    w = img.offsetWidth;
    h = img.offsetHeight;
    console.log('w', w);
    /* Set the width of the img element to 50%: */
    img.style.width = w / 2 + 'px';
    /* Create slider: */
    slider = document.getElementById(sliderId) as HTMLDivElement;
    if (slider) {
        slider.remove();
    }
    slider = document.createElement('div') as HTMLDivElement;
    slider.appendChild(document.createElement('div'));
    slider.setAttribute('class', sliderClassName);
    slider.setAttribute('id', sliderId);
    /* Insert slider */
    img.parentElement.insertBefore(slider, img);
    /* Position the slider in the middle: */

    slider.style.top = h / 2 - slider.offsetHeight / 2 + 'px';
    slider.style.left = w / 2 - slider.offsetWidth / 2 + 'px';

    /* Execute a function when the mouse button is pressed: */
    slider.addEventListener('mousedown', slideReady);
    /* And another function when the mouse button is released: */
    window.addEventListener('mouseup', slideFinish);
    /* Or touched (for touch screens: */
    slider.addEventListener('touchstart', slideReady);
    /* And released (for touch screens: */
    window.addEventListener('touchend', slideFinish);

    function slideReady(e: MouseEvent | TouchEvent) {
        /* Prevent any other actions that may occur when moving over the image: */
        e.preventDefault();
        /* The slider is now clicked and ready to move: */
        clicked = 1;
        /* Execute a function when the slider is moved: */
        window.addEventListener('mousemove', slideMove);
        window.addEventListener('touchmove', slideMove);
    }
    function slideFinish() {
        /* The slider is no longer clicked: */
        clicked = 0;
    }
    function slideMove(e: MouseEvent | TouchEvent) {
        var pos;
        /* If the slider is no longer clicked, exit this function: */
        if (clicked == 0) return false;
        /* Get the cursor's x position: */
        pos = getCursorPos(e);
        /* Prevent the slider from being positioned outside the image: */
        if (pos < 0) pos = 0;
        if (pos > w) pos = w;
        /* Execute a function that will resize the overlay image according to the cursor: */
        slide(pos);
    }
    function getCursorPos(e: any) {
        var a,
            x = 0;
        e = e.changedTouches ? e.changedTouches[0] : e;
        /* Get the x positions of the image: */
        a = img.getBoundingClientRect();
        /* Calculate the cursor's x coordinate, relative to the image: */
        x = e.pageX - a.left;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        return x;
    }
    function slide(x: number) {
        /* Resize the image: */
        img.style.width = x + 'px';
        /* Position the slider: */
        slider.style.left = img.offsetWidth - slider.offsetWidth / 2 + 'px';
    }
}
