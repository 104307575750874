import React, { useCallback, useEffect, useState } from 'react';
import { getAllArticles } from '../../helpers/BuilderHelper';
import { replaceOrAddLocale, useLocale } from '../../helpers/LangHelper';
import { Category } from '../../types/Category';
import Icon, { IconType } from '../icons';
import ImageZoom from '../image-zoom/image-zoom';
import styles from './learning-card.module.scss';
interface Props {
    learningSeries: LearningSeriesCategory;
}

export type LearningSeriesCategory = { category: Category; articleCount?: number };

export type LearningSeries = {
    title: string;
    leadImage: string | '';
    description: string;
    seriesUrl: string;
    quantityArticle: number;
};

const LearningCard = (props: Props) => {
    const { learningSeries } = props;
    const [quantityArticle, setQuantityArticle] = useState<number | null>(null);
    const { locale } = useLocale();

    const category = learningSeries.category;

    useEffect(() => {
        let cancelled = false;

        if (!learningSeries) return;

        if (learningSeries.articleCount) {
            setQuantityArticle(learningSeries.articleCount);
        } else {
            getAllArticles(locale, { categoryIds: [category.id], limit: 30 }).then((article) => {
                if (!cancelled) {
                    setQuantityArticle(article.length);
                }
            });
        }

        return () => {
            cancelled = true;
        };
    }, [learningSeries]);

    return (
        <div className={styles.learningCard}>
            <ImageZoom src={category?.image || ''} ratio={[424, 540]} />
            <div className={styles.overlay}></div>
            <div className={styles.learningCardContainer}>
                <div className={styles.quantity}>
                    {quantityArticle}
                    <span>{quantityArticle && quantityArticle > 1 ? ' ARTICLES' : ' ARTICLE'}</span>
                </div>
                <p className={`${styles.title} ${styles.threeLinesLimit}`}>{category.name}</p>
                <p className={`${styles.description} ${styles.threeLinesLimit}`}>{category.description}</p>
                <div className={styles.btnReadAll}>
                    <span>Read all</span>
                    <Icon type={IconType.ArrowRightMedium} />
                </div>
            </div>
            <a className={styles.learningUrl} href={replaceOrAddLocale(category.categoryUrl || '', locale)} rel="noreferrer"></a>
        </div>
    );
};

export default LearningCard;
