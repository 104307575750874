import { BuilderConfig } from '../../types/Builder';
import BusinessResourceTextHighlight, { Props } from './business-resource-text-highlight';

export const BusinessResourceTextHighlightBuilderConfig = {
    name: 'Resource Text Highlight',
    inputs: [
        {
            name: 'headline',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'text',
            type: 'string',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceTextHighlight,
    config: BusinessResourceTextHighlightBuilderConfig,
};

export default builderConfig;
