import classNames from 'classnames';
import React from 'react';
import Breadcrumb, { BreadcrumbItem } from '../breadcrumb/breadcrumb';
import CategoryFilter from '../category-filter/category-filter';
import styles from './category-hero.module.scss';

export interface Props {
    breadcrumbs?: BreadcrumbItem[];
    categoryHeroTitle: string;
    categoryHeroDescription?: string;
    backgroundColor?: 'Blum' | 'Orca Pastel';
    backgroundCoverContent?: boolean;
    showFilter?: boolean;
}

const CategoryHero = (props: Props) => {
    const { categoryHeroTitle, breadcrumbs, categoryHeroDescription, backgroundColor = 'Orca Pastel', backgroundCoverContent = true, showFilter = true } = props;
    const breadcrumbIncluded = breadcrumbs && breadcrumbs.length;

    return (
        <div className={classNames(styles.categoryHero, { [styles.breadcrumb]: breadcrumbIncluded })}>
            <div
                className={classNames(styles.categoryHeroBackground, styles[`categoryHeroBackground${backgroundColor.replace(' ', '')}`], {
                    [styles.categoryHeroBackgroundCover]: backgroundCoverContent,
                })}
            />
            {breadcrumbIncluded ? (
                <div className={styles.breadcrumbs}>
                    <Breadcrumb type="dark" breadcrumbs={breadcrumbs} />
                </div>
            ) : null}
            <p className={styles.categoryName}>{categoryHeroTitle}</p>
            {categoryHeroDescription ? <p className={styles.categoryDescription}>{categoryHeroDescription}</p> : null}
            <div className={styles.categoryFilter}>{showFilter ? <CategoryFilter /> : null}</div>
        </div>
    );
};

export default CategoryHero;
