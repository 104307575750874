import React, { FC } from 'react';
import { doesContainLocale, localifyLink, replaceLocale, useLocale } from '../../helpers/LangHelper';
import Icon, { IconType } from '../icons';

import styles from './business-resource-card.module.scss';

export type ResourceCardProps = {
    image: string;
    title: string;
    description: string;
    url: string;
    buttonLabel: string;

    replaceLocalesOnLink?: boolean,
};

const ResourceCard: FC<ResourceCardProps> = (props) => {
    const { image, title, description, replaceLocalesOnLink} = props;
    let url = props.url;
    
    const {locale} = useLocale();

    if (replaceLocalesOnLink) {
        if (doesContainLocale(url)) {
            url = replaceLocale(url, locale);
            
        } else {
            url = `/${locale}${url}`;
        }

        url = localifyLink(url, locale);
    }

    return (
        <div className={styles.businessResourceCardWrapper}>
            <div className={styles.businessResourceCardBackgroundWrapper}>
                <div className={styles.businessResourceCardBackgroundBox}>
                    <div className={styles.businessResourceCardBackground} style={{ backgroundImage: `url(${image})` }}></div>
                </div>
            </div>
            <div className={styles.businessResourceCardOverlayWrapper}>
                <div className={styles.businessResourceCardOverlayTitle}>{title}</div>
                <div className={styles.businessResourceCardOverlayDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
                <a href={url} className={styles.businessResourceCardOverlayCTA}>
                    <span>{props.buttonLabel ?? 'Read more'}</span>
                    <Icon type={IconType.ArrowRightSmall} />
                </a>
            </div>
        </div>
    );
};

export default ResourceCard;
