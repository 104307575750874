import { DEFAULT_BACKGROUND_COLORS } from '../../helpers/ColourMap';
import { BuilderConfig } from '../../types/Builder';
import { IconType } from '../icons';
import FramedInfoBlock, { FramedInfoBlockProps } from './business-framed-info-block';
import { IMAGE_POSITIONS } from './business-framed-info-block.constants';

export const BusinessFramedInfoBlockBuilderConfig = {
    name: 'Framed Info Block',
    inputs: [
        {
            name: 'borderColor',
            type: 'string',
            enum: Object.values(DEFAULT_BACKGROUND_COLORS),
            defaultValue: DEFAULT_BACKGROUND_COLORS.MINT,
        },
        {
            name: 'image',
            type: 'file',
            required: true,
        },
        {
            name: 'title',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'url',
            type: 'url',
        },
        {
            name: 'ctaText',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'ctaIcon',
            type: 'string',
            enum: [IconType.Download, IconType.ArrowRightMedium],
        },
        {
            name: 'mediaPosition',
            type: 'string',
            enum: Object.values(IMAGE_POSITIONS),
            defaultValue: IMAGE_POSITIONS.LEFT,
        },
    ],
};

const builderConfig: BuilderConfig<FramedInfoBlockProps> = {
    component: FramedInfoBlock,
    config: BusinessFramedInfoBlockBuilderConfig,
};

export default builderConfig;
