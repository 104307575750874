import classNames from 'classnames';
import React, { useContext } from 'react';
import { DefaultLayoutContext } from '../../contexts/DefaultLayoutContext';

import styles from './business-emphasized-text.module.scss';

export interface Props {
    text: string;
}

const BusinessEmphasizedText: React.FC<Props> = (props: Props) => {
    const { text } = props;
    const defaultLayoutContext = useContext(DefaultLayoutContext);

    return (
        <div className={styles.businessEmphasizedTextContainer}>
            <div className={classNames(styles.businessEmphasizedText, styles[defaultLayoutContext.type || ''])} dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    );
};

export default BusinessEmphasizedText;
