import { VariantClickType } from '../components/business-resource-download-button/business-resource-download-tooltip/business-resource-download-tooltip';
import { downloadAsset } from '../helpers/ResourceHelper';
import { useState } from 'react';
import { AssetType } from '../types/Resource';

const useDownloadVariant = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const downloadVariant = async (asset: AssetType, variant: VariantClickType, defaultFolderName?: string) => {
        setLoading(true);
        try {
            await downloadAsset(
                variant === 'ALL'
                    ? [asset]
                    : [
                          {
                              ...asset,
                              variants: [variant],
                          },
                      ],
                asset.name || defaultFolderName || 'asset'
            );
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };
    return {
        loading,
        downloadVariant,
    };
};

export default useDownloadVariant;
