import { RefObject, useCallback, useContext, useEffect, useState } from 'react';
import { DefaultLayoutContext } from '../contexts/DefaultLayoutContext';

type ElementPosType = 'TOP' | 'ON_SCREEN' | 'BOTTOM' | undefined;

export const useBackgroundTransform = (target: RefObject<HTMLElement>, preventTopTransform?: boolean) => {
    const defaultLayout = useContext(DefaultLayoutContext);
    const [position, setPosition] = useState<ElementPosType>();

    const transformDetector = useCallback(() => {
        if (!target || !target.current) return;

        const targetElem = target.current;
        const element_height = targetElem.clientHeight;
        const top_of_element = targetElem.offsetTop;
        const bottom_of_element = targetElem.offsetTop + element_height;
        const top_of_window = window.scrollY;
        const bottom_of_window = top_of_window + window.innerHeight;
        const window_height = window.innerHeight;

        let position: ElementPosType = 'ON_SCREEN';
        let top_transform_position = Math.max(top_of_element + 100, Math.min(window_height / 3, (top_of_element / 3) * 4));
        if (top_transform_position > bottom_of_element) {
            top_transform_position = top_of_element;
        }

        let bottom_transform_position = Math.max(bottom_of_element - 100, Math.min(window_height / 3, (top_of_element / 3) * 2));
        if (bottom_transform_position < top_of_element) {
            bottom_transform_position = bottom_of_element;
        }

        if ((bottom_of_window > top_transform_position || preventTopTransform) && top_of_window < bottom_transform_position) {
            document.body.classList.add('dark');
            defaultLayout.setType('light');
            position = 'TOP';
        } else {
            document.body.classList.remove('dark');
            defaultLayout.setType('dark');
            position = 'BOTTOM';
        }

        if (top_of_window < bottom_transform_position && bottom_of_window > top_transform_position) {
            position = 'ON_SCREEN';
        }

        setPosition(position);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target]);

    useEffect(() => {
        transformDetector();
        document.addEventListener('scroll', transformDetector);
        return () => document.removeEventListener('scroll', transformDetector);
    }, [transformDetector]);

    return { position };
};
