export const BACKGROUND_COLORS = {
    MINT: 'mint',
    PLUM: 'plum',
} as const;

export type BACKGROUND_COLOR = typeof BACKGROUND_COLORS[keyof typeof BACKGROUND_COLORS];

export const WIDTHS = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    FULL: 'full',
} as const;

export type WIDTH = typeof WIDTHS[keyof typeof WIDTHS];
