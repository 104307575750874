import { FC } from 'react';
import styles from './business-resource-logo-usage.module.scss';
import LogoUsageCard, { LogoUsageItem } from './logo-usage-card/logo-usage-card';

export interface BusinessResourceLogoUsageProps {
    items: LogoUsageItem[];
}

const BusinessResourceLogoUsage: FC<BusinessResourceLogoUsageProps> = ({ items }) => {
    return (
        <div className={styles.container}>
            {items?.map((item) => (
                <LogoUsageCard key={item.image} item={item} />
            ))}
        </div>
    );
};

export default BusinessResourceLogoUsage;
