import classNames from 'classnames';
import React, { useContext } from 'react';
import { DefaultLayoutContext } from '../../contexts/DefaultLayoutContext';
import styles from './business-text-wrap.module.scss';

export interface Props {
    text: string;
}

const BusinessTextWrap: React.FC<Props> = (props: Props) => {
    const { text } = props;
    const defaultLayoutContext = useContext(DefaultLayoutContext);

    return (
        <div className={styles.businessTextWrapContainer}>
            <div className={classNames(styles.businessTextWrap, styles[defaultLayoutContext.type || ''])} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default BusinessTextWrap;
