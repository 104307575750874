import { BuilderConfig } from '../../types/Builder';
import BusinessResourceFAQ, { BusinessResourceFAQProps } from './business-resource-faq';

export const BusinessResourceFAQBuilderConfig = {
    name: 'Resource FAQ',
    inputs: [
        {
            name: 'faqs',
            type: 'list',
            subFields: [
                {
                    name: 'question',
                    type: 'string',
                },
                {
                    name: 'answer',
                    type: 'richText',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceFAQProps> = {
    component: BusinessResourceFAQ,
    config: BusinessResourceFAQBuilderConfig,
};

export default builderConfig;
