import { BuilderConfig } from '../../types/Builder';
import BusinessTextWrap, { Props } from './business-text-wrap';

export const BusinessTextWrapBuilderConfig = {
    name: 'Text Wrap',
    inputs: [
        {
            name: 'text',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessTextWrap,
    config: BusinessTextWrapBuilderConfig,
};

export default builderConfig;
