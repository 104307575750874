import classNames from 'classnames';
import React, { useContext } from 'react';
import { DefaultLayoutContext } from '../../contexts/DefaultLayoutContext';
import { BACKGROUND_COLOR, BACKGROUND_COLORS, WIDTH, WIDTHS } from './business-article-quote.constants';
import styles from './business-article-quote.module.scss';

export interface Props {
    text: string;
    authorName?: string;
    backgroundColor?: BACKGROUND_COLOR;
    width: WIDTH;
}

const BusinessArticleQuoteWrap: React.FC<Props> = (props: Props) => {
    const { text, authorName, backgroundColor = BACKGROUND_COLORS.MINT, width = WIDTHS.MEDIUM } = props;
    const defaultLayoutContext = useContext(DefaultLayoutContext);

    const getBackgroundColor = (): string => {
        const backgroundColorMap = {
            [BACKGROUND_COLORS.MINT]: '#b2fce4',
            [BACKGROUND_COLORS.PLUM]: '#EADDF2',
        };
        return backgroundColorMap[backgroundColor];
    };

    return (
        <div className={styles.businessArticleQuoteContainer}>
            <div className={classNames(styles.businessArticleQuoteBox, styles[defaultLayoutContext.type || ''])}>
                <div className={styles.businessArticleQuote} dangerouslySetInnerHTML={{ __html: text }} />
                {authorName && authorName !== '' ? <span className={styles.businessArticleAuthorName}>{'- ' + authorName}</span> : null}
            </div>
            <div style={{ backgroundColor: getBackgroundColor() }} className={styles.businessArticleQuoteBackground + ' ' + styles[width]} />
        </div>
    );
};

export default BusinessArticleQuoteWrap;
