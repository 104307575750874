import { BuilderConfig } from '../../types/Builder';
import BusinessResourcePriceBreakdown, { BusinessResourcePriceBreakdownProps } from './business-resource-price-breakdown';

export const BusinessResourcePriceBreakdownBuilderConfig = {
    name: 'Resource Price Breakdown',
    inputs: [
        {
            name: 'priceText',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'position',
            type: 'string',
            defaultValue: 'left',
            enum: ['left', 'right'],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourcePriceBreakdownProps> = {
    component: BusinessResourcePriceBreakdown,
    config: BusinessResourcePriceBreakdownBuilderConfig,
};

export default builderConfig;
