import { BuilderConfig } from '../../types/Builder';
import TrustpilotWidget, { TrustpilotWidgetProps } from './trustpilot-widget';

export const TrustpilotWidgetBuilderConfig: any = {
    name: 'Trustpilot Widget',
    inputs: [
        {
            name: 'id',
            type: 'string',
            defaultValue: 'afterpay',
            enum: ['afterpay', 'clearpay'],
        },
    ],
};

const builderConfig: BuilderConfig<TrustpilotWidgetProps> = {
    component: TrustpilotWidget,
    config: TrustpilotWidgetBuilderConfig,
};

export default builderConfig;
