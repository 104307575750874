import React, { Dispatch, SetStateAction } from 'react';
import { Props } from './business-resource-asset-library';
import { AssetType, COLORS, ResourceType } from '../../types/Resource';

export interface BusinessResourceAssetLibraryContextProps extends Props {
    colors: COLORS[];
    keyMessages: string[];
    themedAssets: string[];
    assetTypes: string[];
    assets: AssetType[];
    getDownloadAssetResources: () => ResourceType[];
    getDownloadAssets: (resourceId?: string) => AssetType[];
    getFilteredResources: () => ResourceType[];

    setColors?: Dispatch<SetStateAction<COLORS[]>>;
    setKeyMessages?: Dispatch<SetStateAction<string[]>>;
    setThemedAssets?: Dispatch<SetStateAction<string[]>>;
    setAssetTypes?: Dispatch<SetStateAction<string[]>>;
    setAssets?: Dispatch<SetStateAction<AssetType[]>>;
}

export const BusinessResourceAssetLibraryContext = React.createContext<BusinessResourceAssetLibraryContextProps>({
    resources: [],
    getDownloadAssetResources: () => [],
    getDownloadAssets: () => [],
    getFilteredResources: () => [],

    colors: [],
    keyMessages: [],
    themedAssets: [],
    assetTypes: [],
    assets: [],
});
export const BusinessResourceAssetLibraryProvider = BusinessResourceAssetLibraryContext.Provider;
