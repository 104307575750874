import { BuilderConfig } from '../../types/Builder';
import BusinessResourceBannerCarousel, { BusinessResourceBannerCarouselProps } from './business-resource-banner-carousel';

export const BusinessResourceBannerCarouselBuilderConfig = {
    name: 'Resource Banner Carousel',
    inputs: [
        {
            name: 'banners',
            type: 'list',
            subFields: [
                {
                    name: 'bannerName',
                    type: 'string',
                },
                {
                    name: 'extension',
                    type: 'string',
                },
                {
                    name: 'preview',
                    type: 'file',
                },
                {
                    name: 'downloadUrl',
                    type: 'url',
                },
            ],
        },
        {
            name: 'ctaText',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceBannerCarouselProps> = {
    component: BusinessResourceBannerCarousel,
    config: BusinessResourceBannerCarouselBuilderConfig,
};

export default builderConfig;
