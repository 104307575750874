import React, { FC, ReactNode, useEffect, useRef, MouseEvent } from 'react';
import styles from './modal.module.scss';
import ReactPortal from '../react-portal/react-portal';
import Cross from '../icons/Icons/Cross';

export interface Props {
    title?: ReactNode;
    open?: boolean;
    onOutsideClick?: () => void;
    onEscape?: () => void;
    onCloseClick?: (e: MouseEvent<HTMLDivElement>) => void;
    actions?: ReactNode;
}

const Modal: FC<Props> = ({ children, title = '', open = false, onEscape, onOutsideClick, onCloseClick, actions }) => {
    useEffect(() => {
        const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? onEscape && onEscape() : null);
        document.body.addEventListener('keydown', closeOnEscapeKey);
        return () => {
            document.body.removeEventListener('keydown', closeOnEscapeKey);
        };
    }, [onEscape]);

    const modalRef = useRef<HTMLDivElement | null>(null);

    if (!open) return null;

    return (
        <ReactPortal
            onClick={(e) => {
                if (!modalRef.current?.contains(e.target as Node)) {
                    onOutsideClick && onOutsideClick();
                }
            }}
            wrapperId="react-portal-modal-container"
        >
            <div className={styles.modal}>
                <div ref={modalRef} className={styles.content}>
                    <div className={styles.header}>
                        <div className={styles.title}>{title}</div>
                        <div
                            onClick={(e) => {
                                onCloseClick && onCloseClick(e);
                            }}
                            className={styles.closeButton}
                        >
                            <Cross />
                        </div>
                    </div>
                    <div>{children}</div>
                    <div className={styles.footer}>{actions}</div>
                </div>
            </div>
        </ReactPortal>
    );
};

export default Modal;
