import { BuilderConfig } from '../../types/Builder';
import { BusinessHeadliningLatestArticles, BusinessHeadliningLatestArticlesProps } from './business-headlining-latest-articles';

export const HeadliningLatestArticlesBuilderConfig = {
    name: 'Headlining Latest Articles',
    inputs: [
        {
            name: 'offset',
            type: 'text',
            default: '1',
        },
        {
            name: 'showSignupForAfterpay',
            type: 'boolean',
            default: true
        }
    ],
};

const builderConfig: BuilderConfig<BusinessHeadliningLatestArticlesProps> = {
    component: BusinessHeadliningLatestArticles,
    config: HeadliningLatestArticlesBuilderConfig,
};

export default builderConfig;
