import { BuilderConfig } from '../../types/Builder';
import BusinessResourceLogoUsage, { BusinessResourceLogoUsageProps } from './business-resource-logo-usage';

export const BusinessResourceLogoUsageBuilderConfig = {
    name: 'Resource Logo Usage',
    inputs: [
        {
            name: 'items',
            type: 'list',
            subFields: [
                {
                    name: 'title',
                    type: 'text',
                },
                {
                    name: 'image',
                    type: 'file',
                    required: true,
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceLogoUsageProps> = {
    component: BusinessResourceLogoUsage,
    config: BusinessResourceLogoUsageBuilderConfig,
};

export default builderConfig;
