import { FC, useState } from 'react';
import { downloadAsset } from '../../helpers/ResourceHelper';
import { AssetType } from '../../types/Resource';
import BussinessResourceDownloadButton from '../business-resource-download-button/business-resource-download-button';
import BusinessResourceDownloadCard from '../business-resource-download-card/business-resource-download-card';
import Icon, { IconType } from '../icons';
import styles from './business-resource-download-grid.module.scss';

export interface BusinessResourceDownloadGridProps {
    name?: string;
    items: AssetType[];
}

const BusinessResourceDownloadGrid: FC<BusinessResourceDownloadGridProps> = (props) => {
    const { name, items } = props;
    const [loading, setLoading] = useState(false);

    if (!items) return null;

    const onDownloadAll = async () => {
        setLoading(true);
        try {
            await downloadAsset(items, name || 'afterpay-assets');
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                {items.map((item) => (
                    <BusinessResourceDownloadCard key={`${item.name}_${item.type}_${Math.random()}`} item={item} />
                ))}
            </div>
            <BussinessResourceDownloadButton containerClass={styles.downloadButton} disabled={loading} onClick={onDownloadAll}>
                Download All Versions <Icon type={IconType.Download} />
            </BussinessResourceDownloadButton>
        </div>
    );
};

export default BusinessResourceDownloadGrid;
