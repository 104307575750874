import { BuilderConfig } from '../../types/Builder';
import { BusinessArticleGrid, BusinessArticleGridProps } from './business-article-grid';
import { ALL_ARTICLE_GRID_TYPES } from './business-article-grid.constants';

export const BusinessArticleGridBuilderConfig = {
    name: 'Article Grid',
    inputs: [
        {
            name: 'offset',
            type: 'number',
            default: 0,
        },
        {
            name: 'articleSource',
            type: 'text',
            enum: ALL_ARTICLE_GRID_TYPES
        }
    ],
};

const builderConfig: BuilderConfig<BusinessArticleGridProps> = {
    component: BusinessArticleGrid,
    config: BusinessArticleGridBuilderConfig,
};

export default builderConfig;
