import { BuilderConfig } from '../../types/Builder';
import { MEDIA_RATIO } from '../../types/Media';
import BusinessYoutubeEmbed, { Props } from './business-youtube-embed';

export const BusinessYoutubeEmbedWrapBuilderConfig = {
    name: 'Youtube Embed',
    inputs: [
        {
            name: 'videoUrl',
            type: 'string',
            required: true,
        },
        {
            name: 'ratio',
            type: 'string',
            enum: Object.keys(MEDIA_RATIO),
            defaultValue: Object.keys(MEDIA_RATIO)[0],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessYoutubeEmbed,
    config: BusinessYoutubeEmbedWrapBuilderConfig,
};

export default builderConfig;
