import classNames from 'classnames';
import { FC } from 'react';
import Icon, { IconType } from '../icons';
import styles from './business-resource-price-breakdown.module.scss';

export interface BusinessResourcePriceBreakdownProps {
    priceText: string;
    position: 'left' | 'right';
}

const BusinessResourcePriceBreakdown: FC<BusinessResourcePriceBreakdownProps> = (props) => {
    const { priceText, position } = props;
    return (
        <div className={classNames(styles.container, { [styles.reversed]: position === 'right' })}>
            <div className={styles.priceText}>{priceText}</div>
            <div className={styles.badge}>
                <Icon type={IconType.AfterpayBadge} />
            </div>
        </div>
    );
};

export default BusinessResourcePriceBreakdown;
