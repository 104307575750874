import classNames from 'classnames';
import { CSSProperties, forwardRef, MouseEvent } from 'react';
import { AssetType, AssetVariantType } from '../../../types/Resource';

import styles from '../business-resource-download-button.module.scss';

export type VariantClickType = 'ALL' | AssetVariantType;

interface BusinessResourceDownloadTooltipProps {
    arrowRef?: any;
    item?: AssetType;
    attributes?: { [key: string]: { [key: string]: string } | undefined };
    popperStyles: { [key: string]: CSSProperties };
    disabled?: boolean;
    allOption?: boolean;
    onVariantClick?: (asset: VariantClickType) => void;
}

const BusinessResourceDownloadTooltip: React.ForwardRefRenderFunction<HTMLDivElement, BusinessResourceDownloadTooltipProps> = (props, ref) => {
    const { arrowRef, item, onVariantClick, popperStyles, attributes, disabled, allOption = true } = props;

    if (!item) return null;

    const handleVariantClick = (event: MouseEvent, variant: VariantClickType) => {
        if (disabled) return;
        event.preventDefault();
        event.stopPropagation();
        onVariantClick?.(variant);
    };

    if (!item.variants || item.variants.length === 0) return null;

    return (
        <div ref={ref} className={styles.tooltip} style={popperStyles.popper} {...(attributes?.popper || {})}>
            {allOption ? (
                <button disabled={disabled} type="button" className={styles.button} onClick={(e) => handleVariantClick(e, 'ALL')}>
                    ALL
                </button>
            ) : null}
            {item.variants.map((variant) => {
                if (variant.variantName) {
                    return (
                        <button disabled={disabled} type="button" className={styles.button} key={`${Math.random()}`} onClick={(e) => handleVariantClick(e, variant)}>
                            {variant.variantName}
                        </button>
                    );
                }
                return null;
            })}
            <div className={classNames(styles.tooltipArrow, { [styles.visible]: !!arrowRef })} ref={arrowRef} style={popperStyles.arrow} />
        </div>
    );
};

export default forwardRef(BusinessResourceDownloadTooltip);
