import AOS from 'aos';
import 'aos/dist/aos.css';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { useWindowSize } from '../../hook/useWindowSize';
import Icon, { IconType } from '../icons';
import VideoCardItem from './video-card-item/video-card-item';
import { VIDEO_CARD_ITEM_TYPE, VIDEO_CARD_ITEM_TYPES } from './video-card-item/video-card-item.constants';
import styles from './video-card-list-item.module.scss';

export interface Props {
    items: VideoCardItemProps[];
}
export interface VideoCardItemProps {
    typeOfItem: VIDEO_CARD_ITEM_TYPE;
    discountContent?: string;
    discountPercentImage?: string;
    discountText?: string;
    imageBrandUrl?: string;
    imageUrl?: string;
    authorName?: string;
    quote?: string;
    ctaContent: string;
    ctaUrl: string;
}
const VideoCardListItem = (props: Props) => {
    const { items } = props;
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const windowSize = useWindowSize();
    const windowWidth: number = windowSize.width || 0;

    useEffect(() => {
        setTimeout(() => {
            AOS.init({
                duration: 1200,
            });
        }, 500);
    }, []);

    const animationOption = (type: 'slider' | 'slideItem') => {
        if (type === 'slider' && windowWidth < 1200) {
            return {
                'data-aos': 'fade-up',
                'data-aos-anchor-placement': 'bottom-bottom',
            };
        }
        if (type === 'slideItem' && windowWidth > 1200) {
            return {
                'data-aos': 'fade-up',
                'data-aos-anchor-placement': 'bottom-bottom',
            };
        }
        return null;
    };

    const countImageInItems = (items: VideoCardItemProps[]) => {
        const imagesItem = items.filter((item) => item.typeOfItem === VIDEO_CARD_ITEM_TYPES.IMAGE);
        const secondImage = imagesItem[1] ?? null;
        const indexOfSecondImage = items.indexOf(secondImage);
        return { count: imagesItem.length, secondImageIndex: indexOfSecondImage };
    };
    const handleSliderItemClassName = (item: VideoCardItemProps, index: number) => {
        let slideClassName = classNames(styles.discountCarouselSlide);
        let ratio = [256, 256];
        let isSingleBlock = false;
        const numberOfItems = items.length;
        const imageAmount = countImageInItems(items).count;
        const secondImage = countImageInItems(items).secondImageIndex;
        if (numberOfItems % 5 !== 0 && item.typeOfItem === VIDEO_CARD_ITEM_TYPES.QUOTE) {
            slideClassName = classNames(styles.discountCarouselSlide);
            isSingleBlock = true;
        }
        if (numberOfItems % 5 === 0 && item.typeOfItem === VIDEO_CARD_ITEM_TYPES.QUOTE) {
            slideClassName = classNames(styles.discountCarouselSlide, styles.columnSpanQuote);
        }
        if (numberOfItems % 5 === 0 && item.typeOfItem === VIDEO_CARD_ITEM_TYPES.QUOTE && imageAmount >= 2) {
            slideClassName = classNames(styles.discountCarouselSlide);
            isSingleBlock = true;
        }
        if (numberOfItems % 4 === 0 && item.typeOfItem === VIDEO_CARD_ITEM_TYPES.IMAGE) {
            slideClassName = classNames(styles.discountCarouselSlide);
        }
        if (numberOfItems % 4 !== 0 && item.typeOfItem === VIDEO_CARD_ITEM_TYPES.IMAGE) {
            slideClassName = classNames(styles.discountCarouselSlide, styles.columnSpan);
            ratio = windowWidth > 1200 ? [536, 256] : [256, 256];
        }
        if (numberOfItems % 4 !== 0 && item.typeOfItem === VIDEO_CARD_ITEM_TYPES.IMAGE && imageAmount >= 2) {
            slideClassName = classNames(styles.discountCarouselSlide);
            ratio = [256, 256];
        }
        if (item.typeOfItem === VIDEO_CARD_ITEM_TYPES.IMAGE && index === secondImage) {
            slideClassName = classNames(styles.discountCarouselSlide, styles.columnSpan);
            ratio = windowWidth > 1200 ? [536, 256] : [256, 256];
        }
        if (item.typeOfItem === VIDEO_CARD_ITEM_TYPES.QUOTE && imageAmount >= 2) {
            slideClassName = classNames(styles.discountCarouselSlide, styles.columnSpanQuote);
            isSingleBlock = false;
        }

        return { slideClassName: slideClassName, ratio: ratio, isSingleBlock: isSingleBlock };
    };

    const renderSliderItem = (item: VideoCardItemProps, index: number) => {
        const className = handleSliderItemClassName(item, index).slideClassName;
        const ratio = handleSliderItemClassName(item, index).ratio;
        const isSingleBlock = handleSliderItemClassName(item, index).isSingleBlock;
        return (
            <SwiperSlide key={index} className={className} {...animationOption('slideItem')}>
                <VideoCardItem item={item} ratio={ratio} isSingleBlock={isSingleBlock} />
            </SwiperSlide>
        );
    };

    return (
        <div className={styles.discountCarousel} {...animationOption('slider')}>
            <div ref={nextRef} className={styles.discountCarouselButtonNext}>
                <Icon type={IconType.ArrowRightMedium} />
            </div>
            <div ref={prevRef} className={styles.discountCarouselButtonPrev}>
                <Icon type={IconType.ArrowRightMedium} />
            </div>
            <Swiper
                className={styles.discountCarouselSlider}
                grabCursor
                navigation={{
                    prevEl: prevRef.current ? prevRef.current : undefined,
                    nextEl: nextRef.current ? nextRef.current : undefined,
                }}
                onInit={(swiper) => {
                    if (!swiper) {
                        return;
                    }
                    const navigation = swiper?.params.navigation as any;
                    navigation.prevEl = prevRef.current;
                    navigation.nextEl = nextRef.current;
                    swiper.navigation.update();
                }}
                slidesPerView={'auto'}
                spaceBetween={24}
                breakpoints={{
                    320: { centeredSlides: true, spaceBetween: 15 },
                    768: {
                        spaceBetween: 24,
                        centeredSlides: false,
                    },
                }}
                centeredSlides={false}
                centeredSlidesBounds={true}
                watchOverflow={true}
                updateOnWindowResize={true}
                lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
            >
                {items.map((item: VideoCardItemProps, index: number) => renderSliderItem(item, index))}
            </Swiper>
        </div>
    );
};

export default VideoCardListItem;
