import { BuilderConfig } from '../../types/Builder';
import { MEDIA_RATIO } from '../../types/Media';
import BusinessVimeoEmbedWrap, { Props } from './business-vimeo-embed';

export const BusinessVimeoEmbedWrapBuilderConfig = {
    name: 'Vimeo Embed',
    inputs: [
        {
            name: 'videoUrl',
            type: 'string',
            required: true,
        },
        {
            name: 'ratio',
            type: 'string',
            enum: Object.keys(MEDIA_RATIO),
            defaultValue: Object.keys(MEDIA_RATIO)[0],
        },
        {
            name: 'controls',
            type: 'boolean',
        },
        {
            name: 'backgroundTransform',
            type: 'boolean',
            required: true,
        },
        {
            name: 'containerMaxWidth',
            type: 'string',
        },
        {
            name: 'isHero',
            type: 'boolean',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessVimeoEmbedWrap,
    config: BusinessVimeoEmbedWrapBuilderConfig,
};

export default builderConfig;
