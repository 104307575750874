import classNames from 'classnames';
import React from 'react';
import { LazyBackgroundImage } from '../lazy-background-image/lazy-background-image';
import styles from './image-zoom.module.scss';

export type ImageZoomProps = {
    src: string | '';
    ratio?: number[];
    className?: string;
    children?: any;
};

const ImageZoom = ({ src, ratio = [], className = '', children }: ImageZoomProps) => {
    const [ratioWidth, ratioHeight] = ratio;
    let imageBoxStyle = {};
    if (ratioWidth && ratioHeight) {
        const boxPaddingTop = (ratioHeight / ratioWidth) * 100;
        imageBoxStyle = { paddingTop: `${boxPaddingTop}%` };
    }
    const classNameImageZoom = classNames(className, {
        'image-zoom': true,
    });
    const classNameImageMain = classNames(styles['image-main'], { 'image-hover-zoom': true });
    return (
        <div className={styles[`c-background-zoom`] + ' ' + `${classNameImageZoom}`}>
            <div className={styles['image-box']} style={imageBoxStyle}>
                <LazyBackgroundImage imageUrl={src} className={classNameImageMain} imageWidth={ratio[0]}>
                    {children}
                </LazyBackgroundImage>
            </div>
        </div>
    );
};
export default ImageZoom;
