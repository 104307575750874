import React, { useEffect, useState } from 'react';
import { getCategoriesFromIDs } from '../../helpers/BuilderHelper';
import { localifyLink, useLocale } from '../../helpers/LangHelper';
import { Article } from '../../types/Article';
import { Category } from '../../types/Category';
import HeadliningArticlesBlock from '../headlining-articles-block/headlining-articles-block';
import Icon, { IconType } from '../icons';
import { LearningSeriesCategory } from '../learning-card/learning-card';

import styles from './learning-series-block.module.scss';

export interface LearningSeriesBlockProps {
    titleSeries: string;
    descriptionSeries: string;
    viewAllText: string;
    viewAllUrl?: string;
    category1?: string;
    category2?: string;
    category3?: string;
    backgroundColor?: 'Transparent' | 'Orca Pastel';

    seriesData?: LearningSeriesCategory[] | Article[];
}

const LearningSeriesBlock = (props: LearningSeriesBlockProps) => {
    const { locale } = useLocale();

    const { titleSeries, descriptionSeries, viewAllText, backgroundColor = '', category1, category2, category3, viewAllUrl } = props;
    const [series, setSeries] = useState<LearningSeriesCategory[] | Article[]>(props.seriesData ?? []);

    const viewAllUrlLocale = localifyLink(`/${locale}${viewAllUrl}`, locale);

    useEffect(() => {
        let cancelled = false;

        if (!category1 && !category2 && !category3) return;

        // Don't re-fetch the category data if it's already presented
        if (props.seriesData) return;

        const categories: string[] = [category1 || '', category2 || '', category3 || ''].filter((category) => !!category);

        if (categories && categories.length) {
            getCategoriesFromIDs(categories).then((data) => {
                if (!cancelled) {
                    setSeries(
                        data.map((category) => {
                            return { category };
                        })
                    );
                }
            });
        }

        return () => {
            cancelled = true;
        };
    }, [category1, category2, category3, props.seriesData]);

    return (
        <div className={styles.seriesCollectionBlock}>
            {backgroundColor ? <div className={styles[`seriesCollectionBlock${backgroundColor.replace(' ', '')}`]}></div> : null}
            <div className={styles.seriesHeader}>
                <div className={styles.infoSeries}>
                    <p className={styles.title}>{titleSeries}</p>
                    <p className={styles.description}>{descriptionSeries}</p>
                </div>
                {viewAllUrl ? (
                    <a href={viewAllUrlLocale} className={styles.btnViewAll}>
                        <span>{viewAllText}</span>
                        <Icon type={IconType.ArrowRightMedium} />
                    </a>
                ) : null}
            </div>
            <HeadliningArticlesBlock cardData={series} typeOfCard="learning" showSignupCard={false} />
        </div>
    );
};

export default LearningSeriesBlock;
