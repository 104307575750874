import React, { FC } from 'react';
import { localifyLink, useLocale } from '../../../helpers/LangHelper';
import { Category } from '../../../types/Category';

import ImageZoom from '../../image-zoom/image-zoom';
import styles from './business-trending-topic-item.module.scss';

interface Props {
    category: Category;
}

const BusinessTrendingTopicItem: FC<Props> = ({ category }) => {
    const { locale } = useLocale();

    const { image, name } = category;

    return (
        <a href={localifyLink(`/${locale}${category.categoryUrl}`, locale)} className={styles.businessTrendingTopicsItem}>
            <div className={styles.itemBackground}>
                <ImageZoom src={image ?? ''} ratio={[200, 200]} />
            </div>
            <div className={styles.itemDescription}>{name}</div>
        </a>
    );
};

export default BusinessTrendingTopicItem;
