import { BuilderConfig } from '../../types/Builder';
import BusinessResourceLogos, { Props } from './business-resource-logos';
import { resourcesInput } from '../../helpers/BuilderHelper';

export const BusinessResourceLogosBuilderConfig = {
    name: 'Resource Logos',
    inputs: [
        {
            name: 'downloadAllText',
            type: 'string',
            defaultValue: 'Download logos',
        },
        resourcesInput,
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceLogos,
    config: BusinessResourceLogosBuilderConfig,
};

export default builderConfig;
