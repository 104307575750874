import { BuilderConfig } from '../../types/Builder';
import BusinessArticleQuoteWrap, { Props } from './business-article-quote';
import { BACKGROUND_COLORS, WIDTHS } from './business-article-quote.constants';

export const BusinessArticleQuoteWrapBuilderConfig = {
    name: 'Article Quote',
    inputs: [
        {
            name: 'text',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'authorName',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'backgroundColor',
            type: 'string',
            enum: Object.values(BACKGROUND_COLORS),
            defaultValue: BACKGROUND_COLORS.PLUM,
        },
        {
            name: 'width',
            type: 'string',
            enum: Object.values(WIDTHS),
            defaultValue: WIDTHS.MEDIUM,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessArticleQuoteWrap,
    config: BusinessArticleQuoteWrapBuilderConfig,
};

export default builderConfig;
