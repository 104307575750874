import classNames from 'classnames';
import { FC, useState } from 'react';
import { downloadAsset } from '../../../helpers/ResourceHelper';
import { AssetType, AssetVariantType } from '../../../types/Resource';
import BussinessResourceDownloadButton from '../../business-resource-download-button/business-resource-download-button';
import { VariantClickType } from '../../business-resource-download-button/business-resource-download-tooltip/business-resource-download-tooltip';
import Icon, { IconType } from '../../icons';
import styles from './typography-review-card.module.scss';

export interface TypographyReviewCardProps {
    name: string;
    fontWeight: 'light' | 'regular' | 'demibold' | 'bold';
    fontStyle: 'normal' | 'italic';
    downloadInfo: AssetVariantType[];
    showDownloadButton?: boolean;
}

const TypographyReviewCard: FC<TypographyReviewCardProps> = (props) => {
    const { name, fontWeight = 'light', fontStyle = 'normal', downloadInfo, showDownloadButton = true } = props;
    const [loading, setLoading] = useState(false);

    const onClick = async (variant: VariantClickType) => {
        setLoading(true);
        try {
            const name = 'afterpay-fonts';
            let asset: AssetType = {
                name,
                variants: variant === 'ALL' ? downloadInfo : [variant],
            };

            await downloadAsset([asset], name);
        } catch (ex) {
            console.error(ex);
        } finally {
            setLoading(false);
        }
    };

    const fontClasses = classNames(styles[`font-${fontWeight}`], styles[`font-${fontStyle}`]);

    return (
        <div className={styles.container}>
            <div className={styles.headline}>
                {showDownloadButton && (
                    <BussinessResourceDownloadButton disabled={loading} onVariantClick={onClick} type="icon" item={{ variants: downloadInfo || [] }}>
                        <Icon type={IconType.Download} />
                    </BussinessResourceDownloadButton>
                )}
                <span className={fontClasses}>{name}</span>
            </div>
            <div className={classNames(styles.preview, fontClasses)}>
                <div className={styles.left}>AaBb</div>
                <div className={styles.right}>
                    <div>ABCDEFGHIJKLMNOPQRSTUVWXYZ</div>
                    <div>abcdefghijklmnopqrstuvwxyz</div>
                    <div>{`0123456789 {[(&)]}*,.:;¿¡?!/|\\•¶@®©TM`}</div>
                    <div>$¢£¥€§#%‰</div>
                </div>
            </div>
        </div>
    );
};

export default TypographyReviewCard;
