import * as React from 'react';
import { localifyLink, useLocale } from '../../helpers/LangHelper';
import IconArrowRight from '../icons/Icons/IconArrowRight';
import styles from './breadcrumb.module.scss';

export interface BreadcrumbItem {
    link?: string;
    name: string;
}

export type BreadcrumbType = 'dark' | 'light';

interface Props {
    breadcrumbs: BreadcrumbItem[];
    type: BreadcrumbType;
}

const Breadcrumb: React.FC<Props> = (props: Props) => {
    const { locale } = useLocale();

    const { breadcrumbs, type } = props;

    return (
        <div className={styles.breadcrumbContainer + ' ' + styles[`${type}`]}>
            <ul className={styles.breadcrumb}>
                {(breadcrumbs || []).map((breadcrumb: BreadcrumbItem, index: number) => {
                    const linkTags: any = {};

                    const linkUrl = localifyLink(breadcrumb.link ?? '', locale);

                    if (linkUrl) {
                        linkTags.href = `/${locale}${linkUrl}`;
                    }

                    return (
                        <React.Fragment key={index}>
                            <li className={styles.separator}>
                                <IconArrowRight />
                            </li>
                            <li className={styles.breadcrumbName}>
                                <a {...linkTags} target="_self" className={styles.link} dangerouslySetInnerHTML={{ __html: `${breadcrumb.name}` }}></a>
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

export default Breadcrumb;
