/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import VideoCarousel, { VideoCarouselItem } from './video-carousel';
import VideoCarouselQuoteItem, { QuoteType } from './video-carousel-quote/video-carousel-quote-item';
import styles from './video-carousel.module.scss';

export interface Props {
    videoCarouselItems: VideoCarouselItem[];
}
const VideoCarouselWrapper = (props: Props) => {
    const { videoCarouselItems } = props;
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);

    const quotes: QuoteType[] = videoCarouselItems.map((item): QuoteType => {
        const quote: QuoteType = { quote: item.quote, authorQuote: item.authorQuote };
        return quote;
    });
    const onItemChange = (index: number) => {
        swiper?.slideTo(index, 1000);
    };
    return (
        <div className={styles.videoCarouselWrapper}>
            <VideoCarousel videoCarouselItems={videoCarouselItems} onItemChange={onItemChange} />
            <div className={styles.videoCarouselQuoteContainer}>
                <div className={styles.videoCarouselQuoteContainerDivider}></div>
                <Swiper key={'video_carousel_quote'} direction={'vertical'} spaceBetween={40} className={styles.videoCarouselQuote} onSwiper={setSwiper}>
                    {quotes.map((item: QuoteType, index: number) => (
                        <SwiperSlide key={`quote_key_${index}`}>
                            <VideoCarouselQuoteItem quote={item.quote} authorQuote={item.authorQuote} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default VideoCarouselWrapper;
