import React, { FC } from 'react';
import { ResourceType } from '../../types/Resource';
import { BuilderElement } from '@builder.io/react';
import downloadAllButtonBuilderConfig from '../business-resource-download-all-button/business-resource-download-all-button.builder-component';
import assetCardGridBuilderConfig from '../business-resource-asset-grid/business-resource-grid.builder-component';

export interface BusinessResourceWebsiteProps {
    resources?: ResourceType[];
}

const passableComponents = [assetCardGridBuilderConfig, downloadAllButtonBuilderConfig];
const passableComponentNames = passableComponents.map(({ config: { name } }) => name);

const BusinessResourceAssetContainer: FC<BusinessResourceWebsiteProps> = ({ resources, children }) => {
    const recursivePassResourcesProp = (block: BuilderElement): BuilderElement => {
        if (block.children) {
            return {
                ...block,
                children: block.children.map(recursivePassResourcesProp),
            };
        }
        if (block.component?.name && passableComponentNames.includes(block.component.name))
            return {
                ...block,
                component: {
                    ...block.component,
                    options: {
                        ...block.component.options,
                        resources,
                    },
                },
            };
        return block;
    };
    const passResourcesProp = () =>
        React.Children.map(children, (c) => {
            if (!React.isValidElement(c)) return c;
            return React.cloneElement(c, {
                block: recursivePassResourcesProp(c.props.block),
            });
        });
    return <>{children && passResourcesProp()}</>;
};

export default BusinessResourceAssetContainer;
