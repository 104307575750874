import { BuilderConfig } from '../../../types/Builder';
import { FeaturedArticleHero, FeaturedArticleHeroProps } from './business-featured-article-hero';

export const FullWidthArticleHeroBuilderConfig = {
    name: 'Full Width Featured Article Hero',
    inputs: [],
};

const builderConfig: BuilderConfig<FeaturedArticleHeroProps> = {
    component: FeaturedArticleHero,
    config: FullWidthArticleHeroBuilderConfig,
};

export default builderConfig;
