import { createPortal } from 'react-dom';
import { FC, PropsWithChildren, useLayoutEffect, useState } from 'react';

export type Props = PropsWithChildren<{
    wrapperId?: string;
    onClick?: (e: MouseEvent) => void;
}>;

const ReactPortal: FC<Props> = ({ children, wrapperId = 'react-portal-wrapper', onClick }) => {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);
    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }
        element.onclick = function (e) {
            if (onClick) {
                onClick(e);
            }
        };
        setWrapperElement(element);
        return () => {
            if (systemCreated && element && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [wrapperId]);

    if (wrapperElement === null) return null;

    return createPortal(children, wrapperElement);
};

export function createWrapperAndAppendToBody(wrapperId: string): HTMLElement {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
}
export default ReactPortal;
