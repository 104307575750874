import React from 'react';
import Icon, { IconType } from '../icons';
import { COLOR, COLORS, POSITION, SIZE, SIZES } from './business-button.constants';
import styles from './business-button.module.scss';
import classNames from 'classnames';
export interface Props {
    buttonText?: string;
    color?: COLOR;
    size?: SIZE;
    icon: IconType;
    link?: string;
    linkUrl?: string;
    square?: boolean;
    position?: POSITION;
}

const BusinessButton: React.FC<Props> = (props: Props) => {
    const { buttonText, color = COLORS.MINT, size = SIZES.NORMAL, icon, square, position = POSITION.CENTER } = props;
    const getBackgroundColor = (): string => {
        const backgroundColorMap = {
            [COLORS.MINT]: '#b2fce4',
            [COLORS.PLUM]: '#EADDF2',
            [COLORS.WHITE]: '#fff',
            [COLORS.BLACK]: '#000',
        };
        return backgroundColorMap[color];
    };
    const getIconClassNameColor = (): string => {
        const iconClassNameColorMap = {
            [COLORS.MINT]: 'mint',
            [COLORS.PLUM]: 'plum',
            [COLORS.WHITE]: 'white',
            [COLORS.BLACK]: 'black',
        };
        return iconClassNameColorMap[color];
    };
    const getSize = (): string => {
        const sizeMap = {
            [SIZES.NORMAL]: 'normal',
            [SIZES.SMALL]: 'small',
        };
        return sizeMap[size];
    };
    const getPosition = (): string => {
        const positionMap = {
            [POSITION.LEFT]: 'left',
            [POSITION.RIGHT]: 'right',
            [POSITION.CENTER]: 'center',
        };
        return positionMap[position];
    };

    const link = (props as any).builderBlock?.properties?.href ?? props.link;

    return (
        <a
            href={link}
            className={classNames(styles.businessButtonWrapper, styles[getSize()], styles[getIconClassNameColor()], styles[getPosition()], {
                [styles.square]: square,
            })}
            style={{ backgroundColor: getBackgroundColor() }}
        >
            <span className={styles.businessButtonText + ' ' + styles[getSize()]}>
                {buttonText}
                <Icon type={icon} />
            </span>
        </a>
    );
};

export default BusinessButton;
