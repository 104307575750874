import React, { FC, MouseEvent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from './button/button';
import styles from './inlined-multi-select.module.scss';

export interface Props {
    options?: string[];
    value?: string[];
    onClick?: (e: MouseEvent<HTMLElement>, value: string) => void;
}

const InlinedMultiSelect: FC<Props> = ({ value = [], onClick, options = [] }) => {
    return (
        <div className={styles.root}>
            <Swiper spaceBetween={8} slidesPerGroup={1} slidesPerView="auto" className={styles.swiperContainer}>
                {options.map((option) => (
                    <SwiperSlide
                        style={{
                            width: 'auto',
                        }}
                        key={option}
                        onClick={(e) => onClick?.(e, option)}
                    >
                        <Button selected={value?.includes(option)} id={option}>
                            {option}
                        </Button>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default InlinedMultiSelect;
