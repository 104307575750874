import { BuilderConfig } from '../../types/Builder';
import BusinessResourceAssetGrid, { BusinessResourceAssetGridProps } from './business-resource-asset-grid';

const config = {
    name: 'Resource Asset Grid',
    inputs: [
        {
            name: 'showDownloadButton',
            type: 'boolean',
        },
        {
            name: 'columns',
            type: 'number',
        },
        {
            name: 'limitRows',
            type: 'number',
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceAssetGridProps> = {
    component: BusinessResourceAssetGrid,
    config,
};

export default builderConfig;
