import React, { FC } from 'react';
import BusinessResourceSingleAssetVariant, { Props } from './business-resource-single-asset-variant';
import useDownloadVariant from '../../hook/useDownloadVariant';

export interface BusinessResourceSingleAssetVariantContainerProps extends Props {}

const BusinessResourceSingleAssetVariantContainer: FC<BusinessResourceSingleAssetVariantContainerProps> = ({ asset, ...props }) => {
    const { loading, downloadVariant } = useDownloadVariant();
    return (
        <BusinessResourceSingleAssetVariant
            ButtonProps={{
                disabled: loading,
                onVariantClick: (variant) => {
                    downloadVariant(asset, variant, 'asset');
                },
            }}
            asset={asset}
            {...props}
        />
    );
};

export default BusinessResourceSingleAssetVariantContainer;
