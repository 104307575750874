import React, { FC, ReactNode, useState } from 'react';
import { AssetType, ResourceType } from '../../types/Resource';
import BusinessResourceLinkButton from '../business-resource-link-button/business-resource-link-button';
import ArrowRightXSmall from '../icons/Icons/ArrowRightXSmall';
import DownloadAssetCard from '../asset-card/download-asset-card';
import styles from './business-resource-asset-grid.module.scss';
import BusinessResourceDownloadAssetsButtonContainer from '../business-resource-download-button/business-resource-download-assets-button-container';
import Download from '../icons/Icons/Download';
import classNames from 'classnames';
import BusinessResourceDivider from '../business-resource-divider/business-resource-divider';

export interface BusinessResourceAssetGridProps {
    limitRows?: number;
    resources?: ResourceType[];
    columns?: number;
    showDownloadButton?: boolean;
    showDivider?: boolean;
    renderAssetCard?: (asset: AssetType) => ReactNode;
}

const BusinessResourceAssetGrid: FC<BusinessResourceAssetGridProps> = ({ resources, limitRows = 2, columns = 2, showDownloadButton = false, showDivider, renderAssetCard }) => {
    const [show, setShow] = useState(false);
    if (!resources || !resources[0]) return null;
    const hasShowMore = !!resources[0].assets?.slice(limitRows * columns).length;

    const renderDefaultAssetCard = (asset: AssetType, index: number): ReactNode =>
        renderAssetCard?.(asset) || (
            <DownloadAssetCard
                key={index}
                asset={asset}
                AssetCardProps={{
                    RatioImageProps: {
                        maxHeight: 216,
                        minHeight: 120,
                    },
                }}
            />
        );

    return (
        <>
            <div
                className={classNames(styles.root, {
                    [styles.hasShowMore]: hasShowMore,
                    [styles.showDivider]: showDivider,
                })}
            >
                <div className={styles.titleContainer}>
                    <div className={styles.title}>{resources[0]?.title}</div>
                    {resources?.[0]?.assets && resources?.[0]?.assets.length && showDownloadButton ? (
                        <div className={styles.downloadButton}>
                            <BusinessResourceDownloadAssetsButtonContainer
                                mobileFullWidth
                                assets={resources?.[0]?.assets || []}
                                rootContainerName={resources[0]?.title || resources[0]?.type || 'afterpay asset'}
                            >
                                Download <Download />
                            </BusinessResourceDownloadAssetsButtonContainer>
                        </div>
                    ) : null}
                </div>
                {resources[0]?.description && (
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                            __html: resources[0]?.description,
                        }}
                    />
                )}
                <div className={styles.resourceContainer}>
                    <div
                        style={{
                            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
                        }}
                        className={styles.resource}
                    >
                        {resources[0]?.assets
                            ?.slice(0, limitRows * columns)
                            .filter(({ hide }) => !hide)
                            .map(renderDefaultAssetCard)}
                        {hasShowMore &&
                            show &&
                            resources[0]?.assets
                                ?.slice(limitRows * columns)
                                .filter(({ hide }) => !hide)
                                .map(renderDefaultAssetCard)}
                    </div>
                </div>
                {hasShowMore && !show && (
                    <div className={styles.button}>
                        <BusinessResourceLinkButton strong onClick={() => setShow(true)} icon={<ArrowRightXSmall />}>
                            View more
                        </BusinessResourceLinkButton>
                    </div>
                )}
            </div>
            {showDivider && resources.length > 1 && <BusinessResourceDivider />}
            <BusinessResourceAssetGrid
                resources={resources.slice(1)}
                limitRows={limitRows}
                showDownloadButton={showDownloadButton}
                columns={columns}
                showDivider={showDivider}
                renderAssetCard={renderAssetCard}
            />
        </>
    );
};

export default BusinessResourceAssetGrid;
