import { FC, useMemo } from 'react';
import styles from './business-resource-faq.module.scss';

export interface FAQItemType {
    question: string;
    answer: string;
}

interface Props {
    item: FAQItemType;
}

const FAQItem: FC<Props> = (props) => {
    const { item } = props;
    const id = useMemo(() => `${Math.random()}`, []);

    return (
        <div className={styles.itemContainer}>
            <input type="checkbox" id={id} />
            <label className={styles.question} htmlFor={id}>
                {item.question}
            </label>
            <div className={styles.answer} dangerouslySetInnerHTML={{ __html: item.answer }} />
        </div>
    );
};

export default FAQItem;
