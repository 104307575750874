import { GET_ALL_CATEGORY } from '../../helpers/CategoryHelper';
import { BuilderConfig } from '../../types/Builder';
import { TYPES_OF_CARD } from '../headlining-articles-block/headlining-articles-block.constants';
import SeriesCollectionArticles, { SeriesCollectionArticlesProps } from './series-collection-articles-block';

export const SeriesCollectionArticlesBuilderConfig: any = {
    name: 'Series Collection Articles',
    inputs: [
        {
            name: 'titleSeries',
            type: 'string',
        },
        {
            name: 'descriptionSeries',
            type: 'longText',
            defaultValue: '',
        },
        {
            name: 'viewAllUrl',
            type: 'url',
            defaultValue: '',
        },
        {
            name: 'viewAllText',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'category',
            type: 'dynamic-dropdown',
            options: {
                url: GET_ALL_CATEGORY,
                mapper: `({data}) => {
                  return ({
                    category: Object.values(data)[0].reduce((rs, curr) => {
                      return [...rs, { value: curr.id, name: curr.name }]
                    }, [])
                  })
                }`,
                expectMultipleDropdowns: false,
            },
        },
        {
            name: 'typeOfCard',
            type: 'string',
            defaultValue: TYPES_OF_CARD.ARTICLE,
            enum: Object.values(TYPES_OF_CARD),
        },
        {
            name: 'includeLargeCard',
            type: 'boolean',
            defaultValue: true,
        },
        {
            name: 'backgroundColor',
            type: 'string',
            enum: ['Orca Pastel'],
        },
    ],
};

const builderConfig: BuilderConfig<SeriesCollectionArticlesProps> = {
    component: SeriesCollectionArticles,
    config: SeriesCollectionArticlesBuilderConfig,
};

export default builderConfig;
