import { BuilderConfig } from '../../types/Builder';
import ResourceHeroSub, { ResourceHeroSubProps } from './business-resource-hero-sub';

export const BusinessResourceHeroSubBuilderConfig = {
    name: 'Resource Hero - Tight',
    inputs: [
        {
            name: 'image',
            type: 'file',
            required: true,
        },
        {
            name: 'title',
            type: 'string',
            defaultValue: '',
            required: true,
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'buttonLabel',
            type: 'string',
            defaultValue: 'Read more',
        },
        {
            name: 'breadcrumbs',
            type: 'list',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                    defaultValue: '',
                },
                {
                    name: 'link',
                    type: 'url',
                    defaultValue: '',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<ResourceHeroSubProps> = {
    component: ResourceHeroSub,
    config: BusinessResourceHeroSubBuilderConfig,
};

export default builderConfig;
