import React from 'react';

import styles from './business-resource-page-title.module.scss';
import classNames from 'classnames';

export interface BusinessResourcePageTitleProps {
    title: string;
    introduction: string;
    responsive?: boolean;
}

const BusinessResourcePageTitle: React.FC<BusinessResourcePageTitleProps> = ({ title, introduction, responsive }) => {
    return (
        <div className={styles.root}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            {introduction && (
                <div
                    className={classNames(styles.introduction, {
                        [styles.responsive]: responsive,
                    })}
                    dangerouslySetInnerHTML={{ __html: introduction }}
                />
            )}
        </div>
    );
};

export default BusinessResourcePageTitle;
