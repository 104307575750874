import React, { FC } from 'react';
import useDownloadVariant from '../../hook/useDownloadVariant';
import BussinessResourceDownloadButton, { BussinessResourceDownloadButtonProps } from './business-resource-download-button';
import Icon, { IconType } from '../icons';
import useDownloadAssets from '../../hook/useDownloadAssets';

export interface Props extends BussinessResourceDownloadButtonProps {
    defaultFolderName?: string;
}

const BusinessResourceDownloadVariantButtonContainer: FC<Props> = ({ defaultFolderName, disabled, item, onVariantClick, directlyDownload, ...props }) => {
    const { loading, downloadVariant } = useDownloadVariant();
    const { loading: assetLoading, downloadAssets } = useDownloadAssets();
    return (
        <BussinessResourceDownloadButton
            disabled={loading || assetLoading || disabled}
            item={item}
            onClick={async () => {
                if (directlyDownload) if (item) downloadAssets([item], item?.name || defaultFolderName || 'assets');
            }}
            onVariantClick={async (variant) => {
                if (!directlyDownload) {
                    onVariantClick?.(variant);
                    if (item) {
                        await downloadVariant(item, variant, defaultFolderName);
                    }
                }
            }}
            directlyDownload={directlyDownload}
            {...props}
        >
            <Icon type={IconType.Download} />
        </BussinessResourceDownloadButton>
    );
};

export default BusinessResourceDownloadVariantButtonContainer;
