import React from 'react';
import { BACKGROUND_COLOR, BACKGROUND_COLORS } from './unordered-highlight-list.constants';
import styles from './unordered-highlight-list.module.scss';

export interface HighlightItem {
    content: string;
}

export interface Props {
    heading: string;
    list: HighlightItem[];
    backgroundColor?: BACKGROUND_COLOR;
}

const UnorderedHighlightList: React.FC<Props> = (props: Props) => {
    const { heading, list, backgroundColor = BACKGROUND_COLORS.MINT } = props;

    const getBackgroundColor = (): string => {
        const backgroundColorMap = {
            [BACKGROUND_COLORS.MINT]: '#b2fce4',
            [BACKGROUND_COLORS.PLUM]: '#EADDF2',
        };
        return backgroundColorMap[backgroundColor];
    };

    return (
        <div style={{ backgroundColor: getBackgroundColor() }} className={styles.unorderedHighlightListContainer}>
            <p className={styles.unorderedHighlightHeading}>{heading}</p>
            <ul className={styles.unorderedHighlightList}>
                {list.map((item: HighlightItem) => {
                    return <li key={item.content} className={styles.unorderedHighlightItem} dangerouslySetInnerHTML={{ __html: item.content }} />;
                })}
            </ul>
        </div>
    );
};

export default UnorderedHighlightList;
