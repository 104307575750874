import React, { Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import Chip from '../../../chip/chip';
import { BusinessResourceAssetLibraryContext } from '../../context';
import { onSelect } from '../../utils';

const ChipsContainer = () => {
    const context = useContext(BusinessResourceAssetLibraryContext);

    const renderChips = (key: 'assetTypes' | 'themedAssets' | 'keyMessages' | 'colors', setState?: Dispatch<SetStateAction<string[]>>): ReactNode[] => {
        return context[key].map((v) => (
            <Chip
                onCloseClick={() => {
                    const newValues = onSelect(v, context[key], []);
                    setState?.(newValues);
                }}
                key={v}
            >
                {v}
            </Chip>
        ));
    };

    return (
        <>
            {renderChips('colors', context.setColors as Dispatch<SetStateAction<string[]>>)}
            {renderChips('assetTypes', context.setAssetTypes)}
            {renderChips('keyMessages', context.setKeyMessages)}
            {renderChips('themedAssets', context.setThemedAssets)}
        </>
    );
};

export default ChipsContainer;
