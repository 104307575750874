import React, { FC, PropsWithChildren, MouseEvent } from 'react';
import styles from './button.module.scss';
import classNames from 'classnames';

export type Props = PropsWithChildren<{
    selected?: boolean;
    onClick?: (e: MouseEvent, id?: string) => void;
    id?: string;
}>;

const Button: FC<Props> = ({ children, selected = false, onClick, id }) => {
    return (
        <div
            className={classNames(styles.root, {
                [styles.selected]: selected,
            })}
            onClick={(e) => {
                if (onClick) {
                    onClick(e, id);
                }
            }}
        >
            {children}
        </div>
    );
};

export default Button;
