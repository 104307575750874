import { BuilderConfig } from '../../types/Builder';
import BusinessResourceAssetContainer, { BusinessResourceWebsiteProps } from './business-resource-asset-container';
import { withChildren } from '@builder.io/react';
import { resourcesInput } from '../../helpers/BuilderHelper';

const BusinessResourceWebsiteConfig = {
    name: 'Resource Asset Container',
    inputs: [resourcesInput],
};

const builderConfig: BuilderConfig<BusinessResourceWebsiteProps> = {
    component: withChildren(BusinessResourceAssetContainer),
    config: BusinessResourceWebsiteConfig,
};

export default builderConfig;
