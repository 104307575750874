import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getCookie, setCookie } from '../../helpers/cookieUtils';
import Icon, { IconType } from '../icons';
import styles from './newsletter-signup-section.module.scss';
interface Props {}

const iconArrowRight = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 8.5H15.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 3.5L15.5 8.5L10.5 13.5" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const NewsletterSignUpSection: React.FC<Props> = (props: Props) => {
    const [newsLetterSignUp, setNewsletterSignUp] = useState<boolean>(false);
    const [isShowMessage, setIsShowMessage] = useState<boolean>(false);

    const { ref, inView } = useInView({ triggerOnce: true });

    const handleOnSuccess = () => {
        setCookie('NewsletterSignUp', 'true');
        setIsShowMessage(true);
    };

    useEffect(() => {
        const newsletterSignUpCookie = getCookie('NewsletterSignUp');
        if (newsletterSignUpCookie && newsletterSignUpCookie !== '') {
            setNewsletterSignUp(true);
        }
    }, [newsLetterSignUp]);

    useEffect(() => {
        if (!inView) {
            return;
        }

        const MktoForms2 = (window as any).MktoForms2;

        const loadMarketoForm = () => {
            const newScriptElement = document.createElement('script');
            newScriptElement.onload = () => {
                triggerMarketoForm();
            };
            newScriptElement.setAttribute('src', 'https://business.afterpay.com/js/forms2/js/forms2.min.js');

            document.body.appendChild(newScriptElement);
        };

        const triggerMarketoForm = () => {
            if (!(window as any).MktoForms2) {
                return;
            }

            (window as any).MktoForms2?.loadForm('//business.afterpay.com', '539-RJA-633', 1064, function (form: any) {
                form.getFormElem().find('button.mktoButton').html(`<div class="button-submit"><div class="button-submit-title">Subscribe Now</div>${iconArrowRight}</div>`);
                form.getFormElem().find('input.mktoEmailField').attr('placeholder', 'Enter your email here...');
                form.onSuccess(function () {
                    handleOnSuccess();
                    return false;
                });
            });
        };

        if (!MktoForms2) {
            loadMarketoForm();
        } else {
            triggerMarketoForm();
        }
    }, [inView]);

    if (newsLetterSignUp) {
        return null;
    }

    return (
        <>
            <div className={styles.newsletterSignUpSection} ref={ref}>
                <div className={styles.newsletterSignUpSectionInfoBackground}> </div>
                <div className={styles.newsletterSignUpSectionInfo}>
                    <div className={styles.newsletterSignUpSectionTitle}>Get small business advice and insights straight to your inbox.</div>
                    {/*<div className={styles.newsletterSignUpSectionDescription}>Subscribe to receive the newsletter.</div>*/}
                </div>
                {isShowMessage ? (
                    <div className={styles.newsletterSignUpSectionAfterSubmit}>
                        <Icon type={IconType.Subscribed} />
                        <span>
                            Thanks!
                            <br />
                            You&apos;re now subscribed.
                        </span>
                    </div>
                ) : (
                    <form id="mktoForm_1064" className={styles.newsletterSignUpSectionForm}></form>
                )}
            </div>
        </>
    );
};

export default NewsletterSignUpSection;
