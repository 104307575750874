import { BuilderConfig } from '../../types/Builder';
import BusinessResourceSuperText, { Props } from './business-resource-super-text';

export const BusinessResourceTextHighlightBuilderConfig = {
    name: 'Resource Super Text',
    inputs: [
        {
            name: 'text',
            type: 'string',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceSuperText,
    config: BusinessResourceTextHighlightBuilderConfig,
};

export default builderConfig;
