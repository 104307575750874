import React from 'react';

const SquaredClose = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13 3.66663L3.66663 13" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
            <path d="M13 13L3.66663 3.66663" stroke="black" strokeMiterlimit="10" strokeLinecap="square" />
        </svg>
    );
};

export default SquaredClose;
