import React, { FC } from 'react';
import Checkbox, { Props as CheckboxProps } from '../checkbox/checkbox';
import SwiperCore, { Pagination } from 'swiper';
import AssetCard, { Props as AssetCardProps } from './asset-card';
import styles from './checkbox-asset-card.module.scss';

SwiperCore.use([Pagination]);

export interface Props extends Pick<AssetCardProps, 'asset'>, Pick<CheckboxProps, 'onChange' | 'checked'> {
    AssetCardProps?: Omit<AssetCardProps, 'asset'>;
    CheckboxProps?: Omit<CheckboxProps, 'onChange' | 'checked'>;
}

const CheckboxAssetCard: FC<Props> = ({ asset, onChange, checked, CheckboxProps, AssetCardProps = {} }) => {
    return (
        <label className={styles.root} htmlFor={asset.id}>
            <AssetCard asset={asset} {...AssetCardProps}>
                <Checkbox id={asset.id} checked={checked} onChange={onChange} {...CheckboxProps} />
            </AssetCard>
        </label>
    );
};

export default CheckboxAssetCard;
