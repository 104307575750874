import React, { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper';
import { useWindowSize } from '../../hook/useWindowSize';
import styles from './business-resource-image-carousel.module.scss';
import { useInView } from 'react-intersection-observer';

export interface Props {
    images?: [
        {
            image?: string;
        }
    ];
    itemWidth?: string;
    spaceBetween?: number;
    responsive?: {
        breakpoint?: number;
        itemWidth?: string;
        spaceBetween?: number;
    }[];
}

const getDefaultString = <T,>(defaultValue: T, value?: string | null): T | string => {
    if (!value) return defaultValue;
    return value;
};

const getDefaultNumber = <T,>(defaultValue: T, value?: '' | null | number): T | number => {
    if (typeof value === 'number') {
        return value;
    }
    return defaultValue;
};

const BusinessResourceImageCarousel: FC<Props> = ({ images = [], spaceBetween: rawSpaceBetween, itemWidth: rawItemWidth, responsive = [] }) => {
    let itemWidth = getDefaultString<string>('auto', rawItemWidth);
    const spaceBetween = getDefaultNumber<undefined>(undefined, rawSpaceBetween);
    const { width = 0 } = useWindowSize();

    const { ref, inView } = useInView({ triggerOnce: true });
    const [swiper, setSwiper] = useState<any>(null);

    useEffect(() => {
        if (swiper && inView) {
            swiper.init();
        }
    }, [swiper, inView]);

    const getWidth = () => {
        let w = itemWidth;
        let currentBreakpoint = 0;
        responsive?.forEach(({ breakpoint: rawBreakPoint, itemWidth }) => {
            const breakpoint = getDefaultNumber<number>(0, rawBreakPoint);
            if (width >= breakpoint && breakpoint >= currentBreakpoint && itemWidth) {
                currentBreakpoint = breakpoint;
                w = itemWidth;
            }
        });
        return w;
    };

    const getBreakpoints = () => {
        if (!responsive) return undefined;
        return responsive.reduce<{
            [width: number]: SwiperOptions;
        }>((result, { breakpoint, spaceBetween }) => {
            if (!breakpoint) return result;
            result[breakpoint] = result[breakpoint] || {};
            if (spaceBetween !== undefined) {
                result[breakpoint].spaceBetween = spaceBetween;
            }
            return result;
        }, {});
    };

    return (
        <div className={styles.root} ref={ref}>
            <Swiper
                breakpoints={getBreakpoints()}
                spaceBetween={spaceBetween}
                slidesPerView="auto"
                init={false}
                onSwiper={(sw) => {
                    setSwiper(sw);
                }}
            >
                {images?.map(({ image }, index) => (
                    <SwiperSlide
                        key={index}
                        style={{
                            width: getWidth(),
                        }}
                    >
                        <img className={styles.image} src={image} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default BusinessResourceImageCarousel;
