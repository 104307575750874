import React, { FC } from 'react';
import BusinessResourceDownloadButton, { BussinessResourceDownloadButtonProps } from './business-resource-download-button';
import { ResourceType } from '../../types/Resource';
import useDownloadResource from '../../hook/useDownloadResource';

export interface Props extends BussinessResourceDownloadButtonProps {
    resources: ResourceType[];
    rootContainerName: string;
}

const BusinessResourceDownloadResourcesButtonContainer: FC<Props> = ({ onClick, disabled, resources, rootContainerName, ...props }) => {
    const { loading, downloadResources } = useDownloadResource();
    return (
        <BusinessResourceDownloadButton
            onClick={async (e) => {
                await downloadResources(resources, rootContainerName);
                onClick?.(e);
            }}
            disabled={loading || disabled}
            {...props}
        />
    );
};

export default BusinessResourceDownloadResourcesButtonContainer;
