import React, { useEffect, useRef, useState } from 'react';
import { MEDIA_RATIO, Ratio } from '../../types/Media';
import Player from '@vimeo/player';
import styles from './business-vimeo-embed.module.scss';
import classNames from 'classnames';
import { useBackgroundTransform } from '../../hook/useBackgroundTransform';

export interface Props {
    videoUrl: string;
    video?: string;
    ratio?: Ratio;
    controls?: boolean;
    backgroundTransform?: boolean;
    containerMaxWidth?: string;
    isHero?: boolean;
}

const BusinessVimeoEmbed: React.FC<Props> = (props: Props) => {
    const { videoUrl, ratio = '1:1', controls = false, backgroundTransform, containerMaxWidth, isHero } = props;
    const playerRef = useRef<Player | null>(null);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [isPlayVideo, setIsPlayVideo] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [vimeoControls, setVimeoControls] = useState(false);

    const { position } = useBackgroundTransform(containerRef, isHero);

    useEffect(() => {
        if (!videoUrl) return;
        if (!controls) return;

        const url = new URL(videoUrl);
        const controlParam = url.searchParams.get('controls') ?? true;
        setVimeoControls(controlParam === '1' || controlParam === 'true');
    }, [videoUrl, controls]);

    useEffect(() => {
        if (!videoUrl) return;
        if (!iframeRef.current) return;
        playerRef.current = null;

        playerRef.current = new Player(iframeRef.current);

        playerRef.current.on('play', () => {
            setIsPlayVideo(true);
            playerRef.current?.setVolume(0.5);
        });
        playerRef.current.on('pause', () => setIsPlayVideo(false));
    }, [videoUrl, iframeRef]);

    useEffect(() => {
        if (position !== 'ON_SCREEN') {
            playerRef.current?.pause();
        }
    }, [position]);

    const togglePlayVideo = () => {
        if (isPlayVideo) {
            playerRef.current?.pause();
        } else {
            playerRef.current?.play();
        }
    };

    const toggleMuteVideo = () => {
        playerRef.current;
    };

    const renderPlayPauseBtn = () => {
        if (isPlayVideo) {
            return <div className={styles.buttonPlay}></div>;
        } else {
            return <div className={styles.buttonPause}></div>;
        }
    };

    const classNameVideoControl = classNames(styles[`video-controls`], { [styles['pause']]: !isPlayVideo });
    const classNameOverlay = classNames(styles.controlOverLay);

    const renderVidPlayer = () => {
        const [width, height] = MEDIA_RATIO[ratio];
        const vimeoVideoPaddingTop = (height / width) * 100;
        return (
            <div ref={backgroundTransform ? containerRef : null} className={styles.vimeoVideo} style={{ paddingTop: `${vimeoVideoPaddingTop}%` }}>
                <iframe
                    ref={iframeRef}
                    src={props.video ?? videoUrl}
                    width="872"
                    height="872"
                    frameBorder="0"
                    allow="autoplay; muted; fullscreen; picture-in-picture"
                    allowFullScreen
                />
                {controls && (
                    <>
                        <div className={classNames(classNameVideoControl, { [styles['full-iframe']]: !vimeoControls })} onClick={togglePlayVideo}>
                            <div
                                className={classNames(styles['video-controls__btnPlayPause'], {
                                    [styles.play]: isPlayVideo,
                                    [styles.pause]: !isPlayVideo,
                                })}
                            >
                                {renderPlayPauseBtn()}
                            </div>
                            <div className={styles['video-controls__btnMuted']} onClick={toggleMuteVideo}></div>
                        </div>
                        <div className={classNameOverlay}></div>
                    </>
                )}
            </div>
        );
    };
    return (
        <div className={styles.businessVimeoEmbedContainer} style={{ width: containerMaxWidth }}>
            {renderVidPlayer()}
        </div>
    );
};

export default BusinessVimeoEmbed;
