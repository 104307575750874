import React, { FC } from 'react';
import { replaceOrAddLocale, useLocale } from '../../../helpers/LangHelper';
import Icon, { IconType } from '../../icons';

import styles from './business-resource-related-item.module.scss';

export type ImageGridSliderItemProps = {
    image: string;
    title: string;
    url: string;
};

const ImageGridSliderItem: FC<ImageGridSliderItemProps> = (props) => {
    const { locale } = useLocale();

    const { image, title } = props;

    let url = replaceOrAddLocale(props.url, locale);

    return (
        <div className={styles.businessResourceRelatedItemContainer}>
            <div className={styles.businessResourceRelatedItemBackground}>
                <div className={styles.box}>
                    <div className={styles.main} style={{ backgroundImage: `url(${image})` }}></div>
                </div>
            </div>
            <div className={styles.businessResourceRelatedItemContent}>
                <div className={styles.contentTitle}>{title}</div>
                <a href={url} className={styles.contentCTA}>
                    <span>Read more</span>
                    <Icon type={IconType.ArrowRightSmall} />
                </a>
            </div>
        </div>
    );
};

export default ImageGridSliderItem;
