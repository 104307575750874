import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useBackgroundTransform } from '../../hook/useBackgroundTransform';
import { useWindowSize } from '../../hook/useWindowSize';
import styles from './business-video-player.module.scss';

export type VideoPlayerProps = {
    src: string;
    backgroundTransform?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    loop?: boolean;
    controls?: boolean;
    muted?: boolean;
    className?: string;
};

const VideoPLayer = ({ src, className, onClick = () => {}, loop = false, controls = false, muted = true, backgroundTransform = false }: VideoPlayerProps) => {
    const [loading, setLoading] = useState(false);
    const [isPlayVideo, setPlayVideo] = useState<boolean>(true);
    const refVideo = useRef<HTMLVideoElement>(null);
    const elemRef = useRef<HTMLDivElement>(null);

    useBackgroundTransform(elemRef);

    const [isShowVideoControls, setShowVideoControls] = useState<boolean>(false);

    const windowSize = useWindowSize();
    const windowWidth = windowSize.width || 0;
    const isMobile = windowWidth <= 768;

    useEffect(() => {
        window.addEventListener('focus', onFocusTab);
        window.addEventListener('blur', onLeaveTab);

        return () => {
            window.removeEventListener('focus', onFocusTab);
            window.removeEventListener('blur', onLeaveTab);
        };
    }, []);
    const handleLoadStart = () => {
        setLoading(true);
    };

    const handleCanPlay = () => {
        setLoading(false);
    };

    const togglePlayVideo = () => {
        if (refVideo?.current?.paused) {
            setPlayVideo(true);
            refVideo?.current?.play();
        } else {
            setPlayVideo(false);
            refVideo?.current?.pause();
        }
    };
    const onFocusTab = () => {
        setPlayVideo(false);
        refVideo.current?.pause();
    };
    const onLeaveTab = () => {
        setPlayVideo(false);
        refVideo.current?.pause();
    };
    const renderPlayPauseBtn = () => {
        if (isPlayVideo) {
            return <div className={styles.buttonPlay}></div>;
        } else {
            return <div className={styles.buttonPause}></div>;
        }
    };

    const toggleMuteVideo = () => {
        if (refVideo?.current?.muted) {
            refVideo.current.muted = false;
        } else {
            refVideo.current?.muted ?? true;
        }
    };

    const classNameVideoPlayer = classNames(styles[`c-video-container`], loading && styles['c-video-loading'], className);
    const classNameVideoControl = classNames(styles[`video-controls`], !isPlayVideo || isShowVideoControls ? styles.show : styles.hide);
    const classNameOverlay = classNames(styles.controlOverLay, !isPlayVideo || isShowVideoControls ? styles.show : styles.hide);
    const handleClickVideoM = () => {
        if (isMobile) {
            setShowVideoControls(true);
            setTimeout(() => {
                setShowVideoControls(false);
            }, 2000);
        }
    };
    return (
        <div
            ref={backgroundTransform ? elemRef : null}
            className={classNameVideoPlayer}
            onClick={handleClickVideoM}
            onMouseEnter={() => setShowVideoControls(true)}
            onMouseLeave={() => setShowVideoControls(false)}
        >
            <video
                ref={refVideo}
                onLoadStart={handleLoadStart}
                onCanPlay={handleCanPlay}
                onClick={onClick}
                className={styles['c-video']}
                loop={loop}
                autoPlay={false}
                muted={muted}
                playsInline
            >
                <source src={src} type="video/mp4" />
            </video>
            {controls && (
                <>
                    <div className={classNameVideoControl}>
                        <div
                            onClick={togglePlayVideo}
                            className={classNames(styles['video-controls__btnPlayPause'], {
                                [styles.play]: isPlayVideo,
                                [styles.pause]: !isPlayVideo,
                            })}
                        >
                            {renderPlayPauseBtn()}
                        </div>
                        <div className={styles['video-controls__btnMuted']} onClick={toggleMuteVideo}></div>
                    </div>
                    <div className={classNameOverlay}></div>
                </>
            )}

            <div className={styles['c-video-loader']}></div>
        </div>
    );
};
export default VideoPLayer;
