import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import SwiperCore, { Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Category } from '../../types/Category';

import Icon, { IconType } from '../icons';
import BusinessTrendingTopicItem from './business-treding-topic-item/business-trending-topic-item';
import styles from './business-trending-topics.module.scss';

SwiperCore.use([Lazy, Navigation]);

export interface BusinessTrendingTopicsProps {
    title: string;
    description: string;
    categories: { categoryId: string }[];

    categoryData: Category[];
}

const BusinessTrendingTopics: React.FC<BusinessTrendingTopicsProps> = (props) => {
    const [categories, setCategories] = useState<Category[]>(props.categoryData ?? []);

    const { title, description } = props;

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const { ref, inView } = useInView({ triggerOnce: true });

    const [swiper, setSwiper] = useState<any>(null);

    useEffect(() => {
        if (swiper && inView) {
            swiper.init();
        }
    }, [swiper, inView]);

    return (
        <div className={styles.businessTrendingTopicsWrapper} ref={ref}>
            <div className={styles.businessTrendingTopicsTitle}>{title}</div>
            <div className={styles.businessTrendingTopicsDescription}>{description}</div>
            <div className={styles.businessTrendingTopicsCarousel}>
                <div ref={nextRef} className={styles.carouselButtonNext}>
                    <Icon type={IconType.ArrowRightMedium} />
                </div>
                <div ref={prevRef} className={styles.carouselButtonPrev}>
                    <Icon type={IconType.ArrowRightMedium} />
                </div>
                <Swiper
                    key={title}
                    grabCursor
                    navigation={{
                        prevEl: prevRef.current ? prevRef.current : undefined,
                        nextEl: nextRef.current ? nextRef.current : undefined,
                    }}
                    onInit={(swiper) => {
                        if (!swiper) {
                            return;
                        }
                        const navigation = swiper?.params.navigation as any;
                        navigation.prevEl = prevRef.current;
                        navigation.nextEl = nextRef.current;
                        swiper.navigation.update();
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    breakpoints={{
                        768: {
                            spaceBetween: 24,
                        },
                        1440: {
                            spaceBetween: 24,
                        },
                    }}
                    centeredSlides={false}
                    centeredSlidesBounds
                    watchOverflow={true}
                    lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
                    updateOnWindowResize
                    onSwiper={(sw) => {
                        setSwiper(sw);
                    }}
                >
                    {categories.map((category, index) => {
                        return (
                            <SwiperSlide className={styles.relatedArticleSlide} key={`${index}_${category.categorySlug}`}>
                                <BusinessTrendingTopicItem category={category} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default BusinessTrendingTopics;
