/* eslint-disable @next/next/no-img-element */
import React, { useRef } from 'react';
import SwiperCore, { Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { scrollToById } from '../../helpers';
import { useWindowSize } from '../../hook/useWindowSize';
import Icon, { IconType } from '../icons';
import ImageZoom from '../image-zoom/image-zoom';
import styles from './video-carousel.module.scss';
SwiperCore.use([Lazy, Navigation]);
export interface VideoCarouselItem {
    name: string;
    thumbnailUrl: string;
    imageBrandUrl: string;
    quote: string;
    authorQuote: string;
    sectionId: string;
}

interface Props {
    videoCarouselItems: VideoCarouselItem[];
    onItemChange?: (index: number) => void;
}

const VideoCarousel = (props: Props) => {
    const { videoCarouselItems, onItemChange } = props;
    const windowSize = useWindowSize();
    const windowWidth: number = windowSize.width || 0;
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    if (!videoCarouselItems || videoCarouselItems.length <= 0) {
        return null;
    }
    const spaceWhenScroll = windowWidth > 786 ? -150 : -30;
    const renderVideoCarouselItem = (item: VideoCarouselItem) => {
        return (
            <SwiperSlide className={styles.videoItem} key={item.name}>
                <div className={styles.videoItemContainer} onClick={() => scrollToById(item.sectionId, { detalY: spaceWhenScroll })}>
                    <ImageZoom src={item.thumbnailUrl} ratio={[424, 424]} className={styles.videoItemThumbnailUrl} />
                </div>
            </SwiperSlide>
        );
    };

    return (
        <div className={styles.videoCarousel}>
            <div ref={nextRef} className={styles.videoCarouselButtonNext}>
                <Icon type={IconType.ArrowRightMedium} />
            </div>
            <div ref={prevRef} className={styles.videoCarouselButtonPrev}>
                <Icon type={IconType.ArrowRightMedium} />
            </div>
            <Swiper
                key={'video_carousel'}
                grabCursor
                navigation={{
                    prevEl: prevRef.current ? prevRef.current : undefined,
                    nextEl: nextRef.current ? nextRef.current : undefined,
                }}
                onInit={(swiper) => {
                    if (!swiper) {
                        return;
                    }
                    const navigation = swiper?.params.navigation as any;
                    navigation.prevEl = prevRef.current;
                    navigation.nextEl = nextRef.current;
                    swiper.navigation.update();
                }}
                slidesPerView={'auto'}
                spaceBetween={24}
                breakpoints={{
                    320: { centeredSlides: true, spaceBetween: 15 },
                    768: {
                        spaceBetween: 24,
                        centeredSlides: true,
                    },
                }}
                onSlideChange={(swiper) => {
                    if (onItemChange) {
                        onItemChange(swiper.realIndex);
                    }
                }}
                loop
                centeredSlides={true}
                watchOverflow={true}
                lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
                updateOnWindowResize
            >
                {videoCarouselItems.map((item: VideoCarouselItem) => renderVideoCarouselItem(item))}
            </Swiper>
        </div>
    );
};

export default VideoCarousel;
