import { BuilderConfig } from '../../types/Builder';
import NumericalHighlightList, { Props } from './numerical-highlight-list';
import { BACKGROUND_COLORS } from './numerical-highlight-list.constants';

export const NumericalHighlightListBuilderConfig = {
    name: 'Numerical Highlight List',
    inputs: [
        {
            name: 'heading',
            type: 'string',
            defaultValue: 'Heading',
        },
        {
            name: 'list',
            type: 'list',
            defaultValue: [],
            subFields: [
                {
                    name: 'title',
                    type: 'text',
                    defaultValue: '',
                },
                {
                    name: 'description',
                    type: 'richText',
                    defaultValue: '',
                },
            ],
        },
        {
            name: 'backgroundColor',
            type: 'string',
            enum: Object.values(BACKGROUND_COLORS),
            defaultValue: BACKGROUND_COLORS.PLUM,
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: NumericalHighlightList,
    config: NumericalHighlightListBuilderConfig,
};

export default builderConfig;
