import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { usePopper } from 'react-popper';
import Icon, { IconType } from '../icons';
import styles from './business-resource-messaging.module.scss';

export interface BusinessResourceMessagingProps {
    content: string;
}

const BusinessResourceMessaging: FC<BusinessResourceMessagingProps> = (props) => {
    const { content } = props;
    const [isCopied, setIsCopied] = useState(false);
    const [plainText, setPlainText] = useState('');

    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const [arrowElement, setArrowElement] = useState<any>(null);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                    padding: 5,
                },
            },
        ],
        placement: 'top',
        strategy: 'fixed',
    });

    useEffect(() => {
        const tempDivElement = document.createElement('div');
        tempDivElement.innerHTML = content;
        setPlainText(tempDivElement.textContent || tempDivElement.innerText || '');
    }, [content]);

    return (
        <CopyToClipboard
            text={plainText}
            onCopy={() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            }}
        >
            <div className={styles.container}>
                <span dangerouslySetInnerHTML={{ __html: content }}></span>
                <span ref={setPopperElement} className={classNames(styles.tooltip, { [styles.visible]: isCopied })} style={popperStyles.popper} {...(attributes?.popper || {})}>
                    Copied!
                    <div ref={setArrowElement} className={styles.tooltipArrow} style={popperStyles.arrow} />
                </span>

                <span ref={setReferenceElement} className={styles.copyIcon}>
                    <Icon type={IconType.Copy} />
                </span>
            </div>
        </CopyToClipboard>
    );
};

export default BusinessResourceMessaging;
