import { BuilderConfig } from '../../types/Builder';
import BusinessResourceDownloadAllButton, { Props } from './business-resource-download-all-button';
const BusinessResourceWebsiteConfig = {
    name: 'Resource Download All Button',
    inputs: [
        {
            name: 'text',
            type: 'string',
        },
        {
            name: 'rootContainerName',
            type: 'string'
        },
        {
            name: 'mobileFullWidth',
            type: 'boolean'
        }
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceDownloadAllButton,
    config: BusinessResourceWebsiteConfig,
};

export default builderConfig;
