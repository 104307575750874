import { BuilderConfig } from '../../types/Builder';
import BusinessResourceDownloadCard, { BusinessResourceDownloadCardProps } from './business-resource-download-card';

export const BusinessResourceDownloadCardBuilderConfig = {
    name: 'Resource Download Card',
    inputs: [
        {
            name: 'type',
            type: 'string',
            defaultValue: 'default',
            enum: ['large', 'default'],
        },
        {
            name: 'item',
            type: 'object',
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                },
                {
                    name: 'variants',
                    type: 'list',
                    subFields: [
                        { name: 'variantName', type: 'string' },
                        {
                            name: 'color',
                            type: 'color',
                        },
                        {
                            name: 'backgroundColor',
                            type: 'color',
                            helperText: 'Background in the UI. Default is white',
                            defaultValue: '#ffffff',
                        },
                        {
                            name: 'extension',
                            helperText: 'Example: svg, png, ai, eps,...',
                            type: 'string',
                        },
                        {
                            name: 'previewUrl',
                            type: 'url',
                        },
                        {
                            name: 'downloadUrl',
                            type: 'url',
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourceDownloadCardProps> = {
    component: BusinessResourceDownloadCard,
    config: BusinessResourceDownloadCardBuilderConfig,
};

export default builderConfig;
