import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import Icon, { IconType } from '../icons';
import VideoCardSelected from '../video-card-selected/video-card-selected';
import styles from './business-resource-video-list.module.scss';

export interface Props {
    title?: string;
    description?: string;
    ctaText?: string;
    videoList: VideoItem[];
    watchAllUrl: string;
}
export interface VideoItem {
    title: string;
    description?: string;
    leadImage: string;
    imageBrandUrl: string;
    videoUrl: string;
}

const BusinessResourceVideoList = (props: Props) => {
    const { videoList, watchAllUrl, description, title, ctaText } = props;
    const [videoSelected, setVideoSelected] = useState<VideoItem>(videoList?.[0]);

    useEffect(() => {
        if (videoList && videoList?.length > 0) {
            setVideoSelected(videoList[0]);
        }
    }, [videoList]);

    if (!videoList || videoList?.length === 0) {
        return null;
    }

    const renderVideoItem = (video: VideoItem) => {
        return (
            <div key={`video_item_${video.leadImage}`} className={styles.videoItem} onMouseEnter={() => setVideoSelected(video)} onClick={() => setVideoSelected(video)}>
                <Image
                    className={styles.videoItemImageBrand}
                    src={video.imageBrandUrl}
                    alt=""
                    priority={false}
                    loading={'lazy'}
                    width={160}
                    height={32}
                    fill={false}
                    style={{ objectFit: 'scale-down', objectPosition: 'left' }}
                    loader={({ src, width, quality }) => {
                        return `${src}?width=${width}&quality=${quality ?? 75}`;
                    }}
                />
                <div className={styles.videoItemContainer}>
                    <div className={styles.videoItemTitle}>{video.title}</div>
                    <Icon type={IconType.ArrowRightBig} />
                </div>
                <a href={video.videoUrl} className={styles.videoItemLink} />
            </div>
        );
    };
    return (
        <div className={styles.videoList}>
            <div className={styles.videoListHeader}>
                <div>
                    <p className={styles.videoListHeaderText}>{title}</p>
                    {description ? <p className={styles.videoListDescriptionText}>{description}</p> : null}
                </div>
                <div className={styles.videoListHeaderTextWatchAll}>
                    <span className={styles.businessButtonText}>{ctaText ?? 'Learn More'}</span>
                    <Icon type={IconType.ArrowRightMedium} />
                    <a href={watchAllUrl} className={styles.linkWatchAllVideo}></a>
                </div>
            </div>
            <div className={styles.videoBlock}>
                <VideoCardSelected video={videoSelected} />
            </div>
            <div className={styles.videoListContainer}>{videoList?.map((video: VideoItem) => renderVideoItem(video))}</div>
        </div>
    );
};

export default BusinessResourceVideoList;
