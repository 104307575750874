import React, { FC, MouseEvent } from 'react';
import styles from './business-resource-asset-single-variant.module.scss';
import BusinessResourceDownloadButton, { BussinessResourceDownloadButtonProps } from '../business-resource-download-button/business-resource-download-button';
import Download from '../icons/Icons/Download';
import { AssetType } from '../../types/Resource';
import classNames from 'classnames';

export interface Props {
    asset: AssetType;
    clickable?: boolean;
    onClick?: (asset: AssetType, e: MouseEvent<HTMLDivElement>) => void;
    downloadPlacement?: 'center'; // default top
    ButtonProps?: BussinessResourceDownloadButtonProps;
}

const BusinessResourceSingleAssetVariant: FC<Props> = ({ asset, clickable, onClick, downloadPlacement, ButtonProps = {} }) => {
    const firstVariant = asset?.variants?.[0];
    if (!firstVariant) return null;
    const getPlacementClass = (): string | undefined => {
        if (downloadPlacement === 'center') {
            return styles.alignCentger;
        }
    };
    return (
        <div className={classNames(styles.root, getPlacementClass())}>
            <div className={styles.item}>
                <BusinessResourceDownloadButton item={asset} type="icon" {...ButtonProps}>
                    <Download />
                </BusinessResourceDownloadButton>
            </div>
            <div
                className={classNames(styles.item, styles.imageCol, {
                    [styles.clickable]: clickable,
                })}
                onClick={(e) => onClick?.(asset, e)}
            >
                <img className={styles.image} src={firstVariant.previewUrl} alt="" />
            </div>
        </div>
    );
};

export default BusinessResourceSingleAssetVariant;
