import { BuilderConfig } from '../../types/Builder';
import BusinessResourcePageTitle, { BusinessResourcePageTitleProps } from './business-resource-page-title';

export const BusinessResourcePageTitleBuilderConfig = {
    name: 'Resource Page Title',
    inputs: [
        {
            name: 'responsive',
            type: 'boolean',
        },
        {
            name: 'title',
            type: 'richText',
        },
        {
            name: 'introduction',
            type: 'richText',
        },
    ],
};

const builderConfig: BuilderConfig<BusinessResourcePageTitleProps> = {
    component: BusinessResourcePageTitle,
    config: BusinessResourcePageTitleBuilderConfig,
};

export default builderConfig;
