import React, { FC } from 'react';
import styles from './business-resource-background-text-wrap.module.scss';
import CopyButton from '../copy-button/copy-button';

export interface Props {
    text?: string;
    copyableText?: string;
}

const BusinessResourceBackgroundTextWrap: FC<Props> = ({ text, copyableText }) => {
    if (!text) {
        return null;
    }
    return (
        <div className={styles.root}>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
            {copyableText && (
                <div className={styles.copyButton}>
                    <CopyButton content={copyableText} />
                </div>
            )}
        </div>
    );
};

export default BusinessResourceBackgroundTextWrap;
