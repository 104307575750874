import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import styles from './chip.module.scss';
import SquaredClose from '../icons/Icons/SquaredClose';

export type Props = PropsWithChildren<{
    onCloseClick?: MouseEventHandler<HTMLSpanElement>;
}>;

const Chip: FC<Props> = ({ children, onCloseClick }) => {
    return (
        <div className={styles.root}>
            {children}
            <span onClick={onCloseClick} className={styles.icon}>
                <SquaredClose />
            </span>
        </div>
    );
};

export default Chip;
