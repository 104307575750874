import React, { FC } from 'react';
import BusinessResourceDownloadButton, { BussinessResourceDownloadButtonProps } from './business-resource-download-button';
import useDownloadAssets from '../../hook/useDownloadAssets';
import { AssetType } from '../../types/Resource';

export interface Props extends BussinessResourceDownloadButtonProps {
    assets: AssetType[];
    rootContainerName: string;
}

const BusinessResourceDownloadAssetsButtonContainer: FC<Props> = ({ onClick, disabled, assets, rootContainerName, ...props }) => {
    const { loading, downloadAssets } = useDownloadAssets();
    return (
        <BusinessResourceDownloadButton
            onClick={async (e) => {
                await downloadAssets(assets, rootContainerName);
                onClick?.(e);
            }}
            disabled={loading || disabled}
            {...props}
        />
    );
};

export default BusinessResourceDownloadAssetsButtonContainer;
