import { BuilderConfig } from '../../types/Builder';
import BusinessResourceBanners, { Props } from './business-resource-banners';

export const BusinessResourceBannersBuilderConfig = {
    name: 'Resource Banners',
    inputs: [
        {
            name: 'resources',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                },
                {
                    name: 'assets',
                    type: 'list',
                    copyOnAdd: false,
                    subFields: [
                        {
                            name: 'name',
                            type: 'string',
                        },
                        {
                            name: 'keyMessages',
                            type: 'Tags',
                        },
                        {
                            name: 'themedAssets',
                            type: 'Tags',
                        },
                        {
                            name: 'variants',
                            type: 'list',
                            copyOnAdd: false,
                            subFields: [
                                { name: 'variantName', type: 'string' },
                                {
                                    name: 'color',
                                    type: 'color',
                                },
                                {
                                    name: 'backgroundColor',
                                    type: 'color',
                                },
                                {
                                    name: 'previewUrl',
                                    type: 'url',
                                },
                                {
                                    name: 'downloadUrl',
                                    type: 'url',
                                },
                                {
                                    name: 'extension',
                                    type: 'string',
                                },
                                {
                                    name: 'pagePreviewImage',
                                    type: 'file',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceBanners,
    config: BusinessResourceBannersBuilderConfig,
};

export default builderConfig;
