import React, { FC } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import AssetCard, { Props as AssetCardProps } from './asset-card';
import BusinessResourceDownloadVariantButtonContainer from '../business-resource-download-button/business-resource-download-variant-button-container';

SwiperCore.use([Pagination]);

export interface Props {
    asset: AssetCardProps['asset'];
    AssetCardProps?: Omit<AssetCardProps, 'asset'>;
}

const DownloadAssetCard: FC<Props> = ({ asset, AssetCardProps = {} }) => {
    return (
        <AssetCard asset={asset} {...AssetCardProps}>
            <BusinessResourceDownloadVariantButtonContainer type="icon" directlyDownload item={asset} />
        </AssetCard>
    );
};

export default DownloadAssetCard;
