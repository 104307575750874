import { IncomingMessage } from 'http';
import 'scroll-behavior-polyfill';
interface Options {
    detalY?: number | 0;
}

export const scrollToById = async (id: string, options: Options = {}, delay: number = 10) => {
    if (!('scrollBehavior' in document.documentElement.style)) {
        await import('scroll-behavior-polyfill');
    }
    const { detalY = 0 } = options;
    setTimeout(() => {
        const element = document.getElementById(id);
        if (!element) {
            return;
        }
        const y = element.getBoundingClientRect().top + window.pageYOffset + detalY;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }, delay);
};
