import classNames from 'classnames';
import { FC, useState } from 'react';
import { usePopper } from 'react-popper';
import { AssetType } from '../../types/Resource';
import styles from './business-resource-download-button.module.scss';
import BusinessResourceDownloadTooltip, { VariantClickType } from './business-resource-download-tooltip/business-resource-download-tooltip';

export type HTMLButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

export interface BussinessResourceDownloadButtonProps extends HTMLButtonProps {
    item?: AssetType;
    type?: 'icon';
    disabled?: boolean;
    containerClass?: string;
    allOption?: boolean;
    onVariantClick?: (variant: VariantClickType) => void;
    /**
     * Download on click without showing child (variant) buttons
     */
    directlyDownload?: boolean;
    mobileFullWidth?: boolean;
}

const BussinessResourceDownloadButton: FC<BussinessResourceDownloadButtonProps> = (props) => {
    const { children, item, type, onVariantClick, className, containerClass, disabled, allOption, directlyDownload = false, mobileFullWidth, ...buttonProps } = props;

    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const [arrowElement, setArrowElement] = useState(null);

    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                    padding: 5,
                },
            },
        ],
        placement: 'top',
        strategy: 'fixed',
    });
    return (
        <div
            className={classNames(styles.container, containerClass, {
                [styles.mobileFullWidth]: mobileFullWidth,
            })}
        >
            {!directlyDownload && (
                <BusinessResourceDownloadTooltip
                    ref={setPopperElement}
                    arrowRef={setArrowElement}
                    attributes={attributes}
                    popperStyles={popperStyles}
                    item={item}
                    onVariantClick={onVariantClick}
                    disabled={disabled}
                    allOption={allOption}
                />
            )}
            <button
                disabled={disabled}
                ref={setReferenceElement}
                type="button"
                className={classNames(
                    styles.download,
                    {
                        [styles.mobileFullWidth]: mobileFullWidth,
                        [styles.icon]: type === 'icon',
                    },
                    className
                )}
                {...buttonProps}
            >
                {children}
            </button>
        </div>
    );
};

export default BussinessResourceDownloadButton;
