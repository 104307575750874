import React from 'react';
import styles from './video-carousel-quote.module.scss';
export interface QuoteType {
    quote: string;
    authorQuote: string;
}

const VideoCarouselQuoteItem = ({ quote, authorQuote }: QuoteType) => {
    return (
        <div className={styles.videoCarouselQuoteItem}>
            <q className={styles.videoCarouselQuoteItemContent}>{quote}</q>
            {authorQuote && <p className={styles.videoCarouselQuoteItemAuthorName}>- {authorQuote}</p>}
        </div>
    );
};

export default VideoCarouselQuoteItem;
