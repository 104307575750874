import React, { ChangeEventHandler, FC, PropsWithChildren } from 'react';
import styles from './checkbox.module.scss';
import classNames from 'classnames';

export type Props = PropsWithChildren<{
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    id?: string;
}>;

const Checkbox: FC<Props> = ({ children, onChange, checked, id }) => {
    return (
        <label htmlFor={id} className={styles.root}>
            <span
                className={classNames(styles.checkbox, {
                    [styles.checked]: checked,
                })}
            >
                <input id={id} checked={checked} onChange={onChange} type="checkbox" className={styles.input} />
                <span className={styles.inner}></span>
            </span>
            {children && <span>{children}</span>}
        </label>
    );
};

export default Checkbox;
