import { BuilderConfig } from '../../types/Builder';
import BusinessEmphasizedText, { Props } from './business-emphasized-text';

export const BusinessEmphasizedTextBuilderConfig = {
    name: 'Emphasized Text',
    inputs: [
        {
            name: 'text',
            type: 'richText',
            defaultValue: '',
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessEmphasizedText,
    config: BusinessEmphasizedTextBuilderConfig,
};

export default builderConfig;
