import BusinessResourceGroupLabel, { BusinessResourceGroupLabelProps } from './business-resource-group-label';
import React, { FC } from 'react';
import useDownloadAssets from '../../hook/useDownloadAssets';
import { AssetType } from '../../types/Resource';

export interface BusinessResourceGroupLabelContainerProps extends BusinessResourceGroupLabelProps {
    assets: AssetType[];
    rootFolderName: string;
}

const BusinessResourceGroupLabelContainer: FC<BusinessResourceGroupLabelContainerProps> = ({ assets, rootFolderName, DownloadButtonProps = {}, ...props }) => {
    const { loading, downloadAssets } = useDownloadAssets();
    return (
        <BusinessResourceGroupLabel
            DownloadButtonProps={{
                disabled: loading || DownloadButtonProps?.disabled,
                onClick: (e) => {
                    DownloadButtonProps?.onClick?.(e);
                    downloadAssets(assets, rootFolderName);
                },
                ...DownloadButtonProps,
            }}
            {...props}
        />
    );
};

export default BusinessResourceGroupLabelContainer;
