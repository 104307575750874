import { BuilderConfig } from '../../types/Builder';
import BusinessResourceLightbox, { Props } from './business-resource-lightbox';

export const BusinessResourceLightboxBuilderConfig = {
    name: 'Resource Lightbox',
    inputs: [
        {
            name: 'assets',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'name',
                    type: 'string',
                },
                {
                    name: 'variants',
                    type: 'list',
                    copyOnAdd: false,
                    subFields: [
                        { name: 'variantName', type: 'string' },
                        {
                            name: 'previewUrl',
                            type: 'url',
                        },
                        {
                            name: 'downloadUrl',
                            type: 'url',
                        },
                        {
                            name: 'pagePreviewImage',
                            type: 'file',
                        },
                        {
                            name: 'extension',
                            type: 'string',
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceLightbox,
    config: BusinessResourceLightboxBuilderConfig,
};

export default builderConfig;
