/* eslint-disable @next/next/no-img-element */
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { FC, useEffect } from 'react';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_BACKGROUND_COLORS, mapNameToColour } from '../../helpers/ColourMap';
import { replaceOrAddLocale, useLocale } from '../../helpers/LangHelper';
import { Size, useWindowSize } from '../../hook/useWindowSize';
import Icon, { IconType } from '../icons';
import ImageZoom from '../image-zoom/image-zoom';
import VideoCardListItem, { VideoCardItemProps } from '../video-card-list-item/video-card-list-item';
import { VIDEO_POSITION, VIDEO_POSITIONS } from './video-card.constants';
import styles from './video-card.module.scss';

export type VideoCardProps = {
    borderColor?: DEFAULT_BACKGROUND_COLOR;
    backgroundColor: DEFAULT_BACKGROUND_COLOR;
    leadImage: string;
    title: string;
    description: string;
    href?: string;
    ctaText?: string;
    ctaIcon?: IconType;
    mediaPosition: VIDEO_POSITION;
    imageBrand: string;
    videoCardItems: VideoCardItemProps[];
    sectionId: string;
};

const VideoCard: FC<VideoCardProps> = (props) => {
    const { locale } = useLocale();

    const { borderColor = DEFAULT_BACKGROUND_COLORS.MINT, leadImage, title, description, mediaPosition = 'left', ctaText, ctaIcon, imageBrand, videoCardItems, sectionId } = props;
    const url = replaceOrAddLocale(props.href ?? '', locale);
    const windowSize: Size = useWindowSize();
    const windowWidth: number = windowSize.width || 0;

    console.log('locale', locale);
    console.log('url', url);

    useEffect(() => {
        setTimeout(() => {
            AOS.init({
                duration: 1200,
            });
        }, 500);
    }, []);

    const animationOption = (type: 'media' | 'info', delay: string = '300') => {
        const handleDataAos = (type: 'media' | 'info', mediaPosition: VIDEO_POSITION) => {
            if (type === 'media' && mediaPosition === 'left') {
                return 'right';
            }
            if (type === 'media' && mediaPosition === 'right') {
                return 'left';
            }
            return mediaPosition;
        };
        return {
            'data-aos': 'fade-' + handleDataAos(type, mediaPosition),
            'data-aos-delay': delay,
        };
    };
    const renderMediaContent = () => {
        const imageRatio = windowWidth < 786 ? [313, 311] : [424, 552];
        return (
            <div className={styles.mediaImageContainer}>
                <div className={styles.mediaImageContainerButtonPlay}>
                    <Icon type={IconType.Triangle} />
                </div>
                <ImageZoom src={leadImage} ratio={imageRatio} />
            </div>
        );
    };
    const mediaBlock = () => (
        <div className={`${styles.videoCardMediaContainer} ${styles[mediaPosition]}`}>
            <div className={styles.videoCardMedia}>
                <div className={`${styles.mediaImageWrapper} ${styles[mediaPosition]}`} {...animationOption('media', '300')}>
                    <div className={styles.mediaImageBox}>
                        {renderMediaContent()}
                        {console.log('mediaBlock', url)}
                        <a href={url} target="_self" className={styles.mediaImageLink}></a>
                    </div>
                </div>
                <div
                    className={`${styles.mediaBackground} ${styles[mediaPosition]}`}
                    style={{ borderColor: mapNameToColour(borderColor) }}
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-anchor-placement="top-center"
                    data-aos-duration="500"
                ></div>
            </div>
        </div>
    );

    const infoBlock = () => (
        <div className={`${styles.videoCardInfo} ${styles[mediaPosition]}`}>
            <img src={imageBrand} alt="" className={styles.infoImageBrand} {...animationOption('info', '250')} />
            <div className={styles.infoTitle} dangerouslySetInnerHTML={{ __html: title }} {...animationOption('info', '300')} />
            <div className={styles.infoDescription} dangerouslySetInnerHTML={{ __html: description }} {...animationOption('info', '350')} />
            {console.log('infoBlock', url)}
            <a href={url} target="_self" className={styles.infoCTA} {...animationOption('info', '350')}>
                <span>{ctaText ?? 'Watch Now'}</span>
                <Icon type={ctaIcon ?? IconType.ArrowRightMedium} />
            </a>
        </div>
    );

    return (
        <div className={styles.videoCard} id={sectionId}>
            <div className={styles.videoCardContainer}>
                {mediaPosition === VIDEO_POSITIONS.LEFT ? mediaBlock() : null}
                {infoBlock()}
                {mediaPosition === VIDEO_POSITIONS.RIGHT ? mediaBlock() : null}
            </div>
            <VideoCardListItem items={videoCardItems} />
        </div>
    );
};

export default VideoCard;
