import React from 'react';

import styles from './business-resource-text-wrap.module.scss';
import classNames from 'classnames';

export interface Props {
    caption?: string;
    content?: string;
    row?: boolean;
}

const BusinessResourceTextWrap: React.FC<Props> = ({ caption, content, row }) => {
    if (!caption && !content) return null;

    return (
        <div
            className={classNames(styles.root, {
                [styles.row]: row,
            })}
        >
            {caption && <div className={styles.caption} dangerouslySetInnerHTML={{ __html: caption }} />}
            {content && <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
    );
};

export default BusinessResourceTextWrap;
