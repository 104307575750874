import React, { useContext } from 'react';
import InlinedMultiSelect from '../../../inlined-multi-select/inlined-multi-select';
import { BusinessResourceAssetLibraryContext } from '../../context';
import { onSelect } from '../../utils';

const InlinedMultiSelectContainer = () => {
    const { resources, assetTypes, setAssetTypes } = useContext(BusinessResourceAssetLibraryContext);
    const options = ['All'].concat(resources?.map(({ type }) => type).filter(Boolean) as string[]);
    return (
        <div>
            <InlinedMultiSelect
                value={assetTypes}
                onClick={(e, value) => {
                    const newValues = onSelect(value, assetTypes, options);
                    setAssetTypes?.(newValues);
                }}
                options={options}
            />
        </div>
    );
};

export default InlinedMultiSelectContainer;
