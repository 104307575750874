import { BuilderConfig } from '../../types/Builder';
import BusinessResourceImageCarousel, { Props } from './business-resource-image-carousel';

const sliderConfig = [
    {
        name: 'itemWidth',
        type: 'string',
        helperText: 'Should be number or "auto"',
    },
    {
        name: 'spaceBetween',
        type: 'number',
    },
];

export const BusinessResourceImageCarouselConfig = {
    name: 'Resource Image Carousel',
    inputs: [
        {
            name: 'images',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'image',
                    type: 'file',
                },
            ],
        },
        ...sliderConfig,
        {
            name: 'responsive',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'breakpoint',
                    type: 'number',
                },
                ...sliderConfig,
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceImageCarousel,
    config: BusinessResourceImageCarouselConfig,
};

export default builderConfig;
