import React from 'react';

const BusinessResourceFilter = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 12L7.99996 12M2.66663 12L5.33329 12" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3333 8L7.99996 8M2.66663 8L5.33329 8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3333 4L10.6666 4M2.66663 4L7.99996 4" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6666 5.33317V2.6665" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.33337 9.33317V6.6665" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 13.3332V10.6665" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default BusinessResourceFilter;
