import { DEFAULT_BACKGROUND_COLORS } from '../../helpers/ColourMap';
import { BuilderConfig } from '../../types/Builder';
import { IconType } from '../icons';
import { VIDEO_CARD_ITEM_TYPES } from '../video-card-list-item/video-card-item/video-card-item.constants';
import VideoCard, { VideoCardProps } from './video-card';
import { VIDEO_POSITIONS } from './video-card.constants';

export const VideoCardBuilderConfig = {
    name: 'VideoCard',
    inputs: [
        {
            name: 'leadImage',
            type: 'file',
            defaultValue: '',
        },
        { name: 'imageBrand', type: 'file' },
        {
            name: 'title',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
        },
        {
            name: 'href',
            type: 'url',
        },
        {
            name: 'ctaText',
            type: 'string',
            defaultValue: '',
        },
        {
            name: 'ctaIcon',
            type: 'string',
            enum: [IconType.Download, IconType.ArrowRightMedium],
        },
        {
            name: 'mediaPosition',
            type: 'string',
            enum: Object.values(VIDEO_POSITIONS),
            defaultValue: VIDEO_POSITIONS.LEFT,
        },
        {
            name: 'videoCardItems',
            type: 'list',
            defaultValue: [],
            subFields: [
                {
                    name: 'typeOfItem',
                    type: 'string',
                    enum: Object.values(VIDEO_CARD_ITEM_TYPES),
                    defaultValue: VIDEO_CARD_ITEM_TYPES.DISCOUNT,
                },
                {
                    name: 'imageBrandUrl',
                    type: 'file',
                    helperText: 'Use for item type image brand',
                },
                {
                    name: 'discountPercentImage',
                    type: 'file',
                    defaultValue: '',
                    helperText: 'Use for item type discount',
                },
                {
                    name: 'discountContent',
                    type: 'string',
                    defaultValue: "+74%",
                    helperText: 'Use for item type discount',
                },

                {
                    name: 'discountText',
                    type: 'text',
                    defaultValue: '',
                    helperText: 'Use for item type discount',
                },
                {
                    name: 'imageUrl',
                    type: 'file',
                    defaultValue: '',
                    helperText: 'Use for item type image',
                },
                {
                    name: 'quote',
                    type: 'text',
                    defaultValue: '',
                    helperText: 'Use for item type quote',
                },
                {
                    name: 'authorName',
                    type: 'text',
                    defaultValue: '',
                    helperText: 'Use for item type quote',
                },
                {
                    name: 'ctaContent',
                    type: 'text',
                    defaultValue: '',
                    helperText: 'Use for item type CTABlock',
                },
                {
                    name: 'ctaUrl',
                    type: 'text',
                    defaultValue: '',
                    helperText: 'Use for item type CTABlock',
                },
            ],
        },
        {
            name: 'borderColor',
            type: 'string',
            enum: Object.values(DEFAULT_BACKGROUND_COLORS),
            defaultValue: DEFAULT_BACKGROUND_COLORS.MINT,
        },
        {
            name: 'sectionId',
            type: 'string',
            defaultValue: '',
            helperText: 'This field must be the same with sectionId above the carousel Item',
        },
    ],
};

const builderConfig: BuilderConfig<VideoCardProps> = {
    component: VideoCard,
    config: VideoCardBuilderConfig as any,
};

export default builderConfig;
