export const onSelect = (newValue: string, selectedValues: string[], options: string[]): string[] => {
    if (newValue === 'All') {
        if (selectedValues.includes('All')) {
            return [];
        } else {
            return options;
        }
    } else {
        const index = selectedValues.findIndex((o) => o === newValue);
        let newValues: string[];
        if (index >= 0) {
            // Remove
            newValues = selectedValues.slice(0, index).concat(selectedValues.slice(index + 1));
            const allIndex = newValues.findIndex((v) => v === 'All');
            if (allIndex >= 0) {
                newValues = newValues.slice(0, allIndex).concat(newValues.slice(allIndex + 1));
            }
        } else {
            // Add
            newValues = selectedValues.concat([newValue]);
            if (newValues.length === options.length - 1) {
                newValues = newValues.concat(['All']);
            }
        }
        return newValues;
    }
};

export const onMultiSelect = <T extends {}>(newValue: T, selectedValues: T[], idName: keyof T): T[] => {
    const index = selectedValues.findIndex((o) => o[idName] === newValue[idName]);
    let newValues: T[];
    if (index >= 0) {
        // Remove
        newValues = selectedValues.slice(0, index).concat(selectedValues.slice(index + 1));
    } else {
        // Add
        newValues = selectedValues.concat([newValue]);
    }
    return newValues;
};
