import React, { FC, useEffect, useRef, useState } from 'react';
import SwiperCore, { Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import Icon, { IconType } from '../icons';
import ImageGridSliderItem, { ImageGridSliderItemProps } from './business-resource-related-item/business-image-grid-slider-item';

import styles from './business-image-grid-slider.module.scss';
import { useInView } from 'react-intersection-observer';

SwiperCore.use([Lazy, Navigation]);

export type ImageGridSliderProps = {
    title: string;
    description: string;
    list: ImageGridSliderItemProps[];
};

const ImageGridSlider: FC<ImageGridSliderProps> = (props) => {
    const { title, description, list } = props;

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const { ref, inView } = useInView({ triggerOnce: true });

    const [swiper, setSwiper] = useState<any>(null);

    useEffect(() => {
        if (swiper && inView) {
            swiper.init();
        }
    }, [swiper, inView]);

    return (
        <div className={styles.businessResourceRelatedContainer} ref={ref}>
            <div className={styles.businessResourceRelatedTitle}>{title}</div>
            <div className={styles.businessResourceRelatedDescription} dangerouslySetInnerHTML={{ __html: description }} />
            <div className={styles.businessResourceRelatedCarousel}>
                {!!list?.length && (
                    <>
                        <div ref={nextRef} className={styles.carouselButtonNext}>
                            <Icon type={IconType.ArrowRightMedium} />
                        </div>
                        <div ref={prevRef} className={styles.carouselButtonPrev}>
                            <Icon type={IconType.ArrowRightMedium} />
                        </div>
                    </>
                )}
                <Swiper
                    navigation={{
                        prevEl: prevRef.current ? prevRef.current : undefined,
                        nextEl: nextRef.current ? nextRef.current : undefined,
                    }}
                    onInit={(swiper) => {
                        if (!swiper) {
                            return;
                        }
                        const navigation = swiper?.params.navigation as any;
                        navigation.prevEl = prevRef.current;
                        navigation.nextEl = nextRef.current;
                        swiper.navigation.update();
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={4}
                    breakpoints={{
                        768: {
                            spaceBetween: 24,
                        },
                    }}
                    centeredSlidesBounds
                    watchOverflow={true}
                    lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
                    updateOnWindowResize
                    draggable
                    grabCursor
                    onSwiper={(sw) => {
                        setSwiper(sw);
                    }}
                    init={false}
                >
                    {list?.map((item, index) => (
                        <SwiperSlide key={`${index}`}>
                            <ImageGridSliderItem {...item} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default ImageGridSlider;
