import React from "react";

import styles from "./business-text-caption.module.scss";

export interface Props {
    text: string;
}

const BusinessTextCaption: React.FC<Props> = (props: Props) => {
    const { text } = props;

    return (
        <div className={styles.businessTextCaptionContainer}>
            <div className={styles.businessTextCaption} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
};

export default BusinessTextCaption;
