import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react';
import Checkbox from '../../../checkbox/checkbox';
import styles from './filter-panel-container.module.scss';
import { BusinessResourceAssetLibraryContext } from '../../context';
import { onSelect } from '../../utils';
import { COLORS } from '../../../../types/Resource';

const FilterPanelContainer = () => {
    const { resources, colors, setColors, keyMessages, setKeyMessages, themedAssets, setThemedAssets, assetTypes, setAssetTypes } = useContext(BusinessResourceAssetLibraryContext);
    const getColors = (): COLORS[] => {
        const colors = new Set<COLORS>();
        resources?.forEach((resource) => {
            resource.assets?.forEach((asset) => {
                asset.variants?.forEach((variant) => {
                    variant.colors?.forEach(({ color }) => color && colors.add(color));
                });
            });
        });
        return Array.from(colors);
    };

    const getAttributes = (attrName: 'keyMessages' | 'themedAssets'): string[] => {
        const attrs = new Set<string>();
        resources?.forEach((resource) => {
            resource.assets?.forEach((asset) => {
                asset[attrName]?.forEach(attrs.add, attrs);
            });
        });
        return Array.from(attrs);
    };

    const alphabetSort = (array: string[]): string[] => array.sort((a, b) => a.localeCompare(b));

    const getAssetTypes = (): string[] => resources?.map(({ type }) => type).filter(Boolean) as string[];

    const onChangeHof =
        <T,>(value: T, values: T[], setVales?: Dispatch<SetStateAction<T[]>>) =>
        (e: ChangeEvent<HTMLInputElement>): void => {
            const index = values.findIndex((c) => c === value);
            if (index >= 0) {
                setVales?.(values.slice(0, index).concat(values.slice(index + 1)));
            } else {
                setVales?.(values.concat([value]));
            }
        };

    const isChecked = (value: string, values: string[]): boolean => {
        return values.includes(value);
    };

    const onReset =
        <T,>(setState?: Dispatch<SetStateAction<T[]>>) =>
        (): void => {
            setState?.([]);
        };

    return (
        <div className={styles.root}>
            <div>
                <div className={styles.title}>
                    Colour
                    <div className={styles.reset} onClick={onReset<COLORS>(setColors)}>
                        Reset
                    </div>
                </div>
                <div className={styles.content}>
                    {getColors().map((color) => (
                        <Checkbox checked={isChecked(color, colors)} onChange={onChangeHof<COLORS>(color, colors, setColors)} key={color}>
                            {color}
                        </Checkbox>
                    ))}
                </div>
            </div>
            <div>
                <div className={styles.title}>
                    Asset Type
                    <div className={styles.reset} onClick={onReset<string>(setAssetTypes)}>
                        Reset
                    </div>
                </div>
                <div className={styles.content}>
                    {getAssetTypes().map((v) => (
                        <Checkbox
                            key={v}
                            checked={isChecked(v, assetTypes)}
                            onChange={() => {
                                const newValues = onSelect(v, assetTypes, getAssetTypes());
                                setAssetTypes?.(newValues);
                            }}
                        >
                            {v}
                        </Checkbox>
                    ))}
                </div>
            </div>
            <div className={styles.keyMessages}>
                <div className={styles.title}>
                    Key Messages
                    <div className={styles.reset} onClick={onReset<string>(setKeyMessages)}>
                        Reset
                    </div>
                </div>
                <div className={styles.content}>
                    {alphabetSort(getAttributes('keyMessages')).map((v) => (
                        <Checkbox key={v} checked={isChecked(v, keyMessages)} onChange={onChangeHof<string>(v, keyMessages, setKeyMessages)}>
                            {v}
                        </Checkbox>
                    ))}
                </div>
            </div>
            {/*<div>*/}
            {/*    <div className={styles.title}>*/}
            {/*        Themed Assets*/}
            {/*        <div className={styles.reset} onClick={onReset<string>(setThemedAssets)}>*/}
            {/*            Reset*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={styles.content}>*/}
            {/*        {getAttributes('themedAssets').map((v) => (*/}
            {/*            <Checkbox key={v} checked={isChecked(v, themedAssets)} onChange={onChangeHof(v, themedAssets, setThemedAssets)}>*/}
            {/*                {v}*/}
            {/*            </Checkbox>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default FilterPanelContainer;
