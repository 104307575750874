import { BuilderConfig } from '../../types/Builder';
import ImageGridSlider, { ImageGridSliderProps } from './business-image-grid-slider';

export const ImageGridSliderBuilderConfig = {
    name: 'Grid Image Slider With Link',
    inputs: [
        {
            name: 'title',
            type: 'string',
            required: true,
        },
        {
            name: 'description',
            type: 'richText',
            defaultValue: '',
            required: true,
        },
        {
            name: 'list',
            type: 'list',
            defaultValue: [],
            subFields: [
                {
                    name: 'image',
                    type: 'file',
                    required: true,
                },
                {
                    name: 'title',
                    type: 'text',
                    defaultValue: '',
                },
                {
                    name: 'url',
                    type: 'url',
                    defaultValue: '',
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<ImageGridSliderProps> = {
    component: ImageGridSlider,
    config: ImageGridSliderBuilderConfig,
};

export default builderConfig;
