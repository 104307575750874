import { BuilderConfig } from '../../types/Builder';
import BusinessResourceInSitu, { Props } from './business-resource-in-situ';

const BusinessResourceInSituConfig = {
    name: 'Resource In Situ',
    inputs: [
        {
            name: 'sections',
            type: 'list',
            copyOnAdd: false,
            subFields: [
                {
                    name: 'title',
                    type: 'string',
                },
                {
                    name: 'previewPages',
                    type: 'list',
                    copyOnAdd: false,
                    subFields: [
                        {
                            name: 'name',
                            type: 'string',
                        },
                        {
                            name: 'previewImage',
                            type: 'file',
                        },
                    ],
                },
                {
                    name: 'downloadButton',
                    type: 'object',
                    subFields: [
                        {
                            name: 'text',
                            type: 'string',
                        },
                        {
                            name: 'assets',
                            type: 'list',
                            copyOnAdd: false,
                            subFields: [
                                {
                                    name: 'name',
                                    type: 'string',
                                },
                                // {
                                //     name: 'keyMessages',
                                //     type: 'Tags',
                                // },
                                // {
                                //     name: 'themedAssets',
                                //     type: 'Tags',
                                // },
                                {
                                    name: 'variants',
                                    type: 'list',
                                    copyOnAdd: false,
                                    subFields: [
                                        { name: 'variantName', type: 'string' },
                                        // {
                                        //     name: 'color',
                                        //     type: 'color',
                                        // },
                                        // {
                                        //     name: 'backgroundColor',
                                        //     type: 'color',
                                        // },
                                        // {
                                        //     name: 'previewUrl',
                                        //     type: 'url',
                                        // },
                                        {
                                            name: 'downloadUrl',
                                            type: 'url',
                                        },
                                        {
                                            name: 'extension',
                                            type: 'string',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'moreButton',
                    type: 'object',
                    subFields: [
                        {
                            name: 'text',
                            type: 'string',
                        },
                        {
                            name: 'url',
                            type: 'url',
                        },
                    ],
                },
            ],
        },
    ],
};

const builderConfig: BuilderConfig<Props> = {
    component: BusinessResourceInSitu,
    config: BusinessResourceInSituConfig,
};

export default builderConfig;
